import React from 'react'
import { Route, Switch } from 'react-router-dom'

// Property-search
import SearchFormPage from '../content/pages/property-search/Search-form-page'

// Communities
import CommunitiesPage from '../content/pages/communities/Communities'
import Communitiy from '../common/communities/Community'

// Sell
import SellingAHomePage from '../content/pages/sell/Selling-a-home'
import PricingYourHomePage from '../content/pages/sell/Pricing-your-home'
import MarketingYourHomePage from '../content/pages/sell/Marketing-your-home'
import ShowingYourHomePage from '../content/pages/sell/Showing-your-home'
import AddingValuePage from '../content/pages/sell/Adding-value'
import FreeMarketAnalysisPage from '../content/pages/sell/Free-market-analysis'

// Buy
import FirstTimeBuyersPage from '../content/pages/buy/First-time-buyers'
import MortgageCalculatorPage from '../content/pages/buy/Mortgage-calculator'
import MortgagePreApprovalPage from '../content/pages/buy/Mortgage-pre-approval'
import MakingAnOfferPage from '../content/pages/buy/Making-an-offer'
import WhatAreClosingCostsPage from '../content/pages/buy/What-are-closing-costs'
import EscrowNowWhatPage from '../content/pages/buy/Escrow-now-what'
import FinancialTermsGlossaryPage from '../content/pages/buy/Financial-terms-glossary'
import PersonalizedHomeSearchPage from '../content/pages/buy/Personalized-home-search'
import TodaysNewListingsPage from '../content/pages/buy/Todays-new-listings'
import ReadyToBuyPage from '../content/pages/buy/Ready-to-buy'
import UtilityServicesPage from '../content/pages/buy/Utility-services'

// About
import AboutPage from '../content/pages/our-team/About.js'
import FeaturedListingsPage from '../content/pages/our-team/Featured-listings.js'
import WhatItTakesPage from '../content/pages/our-team/What-it-takes.js'
import RealEstateAgentPage from '../content/pages/our-team/Real-estate-agent.js'
import GetLicensedPage from '../content/pages/our-team/Get-licensed.js'
import SplitCalculatorPage from '../content/pages/our-team/Split-calculator.js'

// Contact
import ContactPage from '../content/pages/contact/Contact.js'
import AppointmentPage from '../content/pages/contact/Appointment.js'

// Blog
import BlogPage from '../content/blog/Blog'
import ArticleWrapper from '../common/blog/Article-wrapper'

// Service pages
import AccessibilityPage from '../content/pages/service/Accessibility'
import TermsOfServicePage from '../content/pages/service/Terms-of-service'
import PrivacyPolicyPage from '../content/pages/service/Privacy-policy'
import DmcaNoticePage from '../content/pages/service/Dmca-notice'
import SiteMapPage from '../content/pages/service/Site-map'

// Admin
import AdminIndex from '../content/pages/admin'

// 404
import NotFoundPage from '../content/pages/404.js'

function Main() {
  return (
    <section className={'main-content'}>
      <div className={'top-strip'}>
        <div className={'container'}></div>
      </div>
      <div className={'container'}>
        <div className={'row'}>
          <Switch>
            <Route exact path="/property-search/">
              <SearchFormPage />
            </Route>
            <Route exact path="/property-search/search-form/">
              <SearchFormPage />
            </Route>
            

            <Route exact path="/communities/">
              <CommunitiesPage />
            </Route>
            <Route exact path="/communities/:alias">
              <Communitiy />
            </Route>

            <Route exact path="/buy/">
              <FirstTimeBuyersPage />
            </Route>
            <Route exact path="/buy/first-time-buyers/">
              <FirstTimeBuyersPage />
            </Route>
            <Route exact path="/buy/mortgage-calculator/">
              <MortgageCalculatorPage />
            </Route>
            <Route exact path="/buy/mortgage-pre-approval/">
              <MortgagePreApprovalPage />
            </Route>
            <Route exact path="/buy/making-an-offer/">
              <MakingAnOfferPage />
            </Route>
            <Route exact path="/buy/what-are-closing-costs/">
              <WhatAreClosingCostsPage />
            </Route>
            <Route exact path="/buy/escrow-now-what/">
              <EscrowNowWhatPage />
            </Route>
            <Route exact path="/buy/financial-terms-glossary/">
              <FinancialTermsGlossaryPage />
            </Route>
            <Route exact path="/buy/personalized-home-search/">
              <PersonalizedHomeSearchPage />
            </Route>
            <Route exact path="/buy/todays-new-listings/">
              <TodaysNewListingsPage />
            </Route>
            <Route exact path="/buy/ready-to-buy/">
              <ReadyToBuyPage />
            </Route>
            <Route exact path="/buy/utility-services/">
              <UtilityServicesPage />
            </Route>

            <Route exact path="/sell/">
              <SellingAHomePage />
            </Route>
            <Route exact path="/sell/pricing-your-home/">
              <PricingYourHomePage />
            </Route>
            <Route exact path="/sell/marketing-your-home/">
              <MarketingYourHomePage />
            </Route>
            <Route exact path="/sell/showing-your-home/">
              <ShowingYourHomePage />
            </Route>
            <Route exact path="/sell/adding-value/">
              <AddingValuePage />
            </Route>
            <Route exact path="/sell/free-market-analysis/">
              <FreeMarketAnalysisPage />
            </Route>
            <Route exact path="/sell/sell-your-property/">
              <SellingAHomePage />
            </Route>
            <Route exact path="/about/">
              <AboutPage />
            </Route>
            <Route exact path="/about/featured-listings/">
              <FeaturedListingsPage />
            </Route>
            <Route exact path="/about/what-it-takes/">
              <WhatItTakesPage />
            </Route>
            <Route exact path="/about/real-estate-agent/">
              <RealEstateAgentPage />
            </Route>
            <Route exact path="/about/get-licensed/">
              <GetLicensedPage />
            </Route>
            <Route exact path="/about/split-calculator/">
              <SplitCalculatorPage />
            </Route>
            <Route exact path="/contact/">
              <ContactPage />
            </Route>
            <Route exact path="/contact/appointment/">
              <AppointmentPage />
            </Route>

            <Route exact path="/blog/">
              <BlogPage />
            </Route>
            <Route exact path="/blog/:alias">
              <ArticleWrapper />
            </Route>

            <Route exact path="/accessibility/">
              <AccessibilityPage />
            </Route>
            <Route exact path="/terms-of-service/">
              <TermsOfServicePage />
            </Route>
            <Route exact path="/privacy-policy/">
              <PrivacyPolicyPage />
            </Route>
            <Route exact path="/dmca-notice/">
              <DmcaNoticePage />
            </Route>
            <Route exact path="/site-map/">
              <SiteMapPage />
            </Route>
            <Route path="/admin">
              <AdminIndex />
            </Route>
            <Route>
              <NotFoundPage />
            </Route>
          </Switch>
        </div>
      </div>
    </section>
  )
}

export default Main
