import React from 'react'
import Breadcrumb from '../../../common/Breadcrumb'
import Sidebar from '../../../common/sidebar/Sidebar'

const FreeMarketAnalysisPage = () => {
  return (
    <>
      <div className={'si-site-container col-md-8 si-site-container--full'}>
        <Breadcrumb />
        <div className={'si-content-area'}>
          <h1>What's Your St. George Area Property Worth?</h1>
          <p>
            Would you like to know your home's estimated value? Our FREE service
            provides you with your property's current market value and suggested
            selling price! Just fill out and submit the form below, and we'll
            analyze the comparables, local trends and other local market data to
            provide you with an up-to-date and accurate estimate of the worth of
            your home or condo.
          </p>
          <div className={'si-content-area'}>
            <h1>What's Your St. George Area Property Worth?</h1>
            <p>
              Would you like to know your home's estimated value? Our FREE
              service provides you with your property's current market value and
              suggested selling price! Just fill out and submit the form below,
              and we'll analyze the comparables, local trends and other local
              market data to provide you with an up-to-date and accurate
              estimate of the worth of your home or condo.
            </p>
          </div>
        </div>
      </div>
      <Sidebar />
    </>
  )
}

export default FreeMarketAnalysisPage
