import React from 'react'

import AuthRegisterInputView from './Auth-input-view-blocks/Auth-register-input-view'
import AuthSigninInputView from './Auth-input-view-blocks/Auth-signin-input-view'
import AuthForgotPasswordInputView from './Auth-input-view-blocks/Auth-forgot-password-input-view'
import AuthSocialRegisterInputView from './Auth-input-view-blocks/Auth-social-register-input-view'

import { authInputViewNames } from '../dictionaries/inputViews'

function AuthInputView({
  closeFunction,
  authInputViewName,
  setAuthInputViewName,
}) {
  return (
    <div id="authInputView">
      {authInputViewName === authInputViewNames.AuthRegisterInputView ? (
        <AuthRegisterInputView
          closeFunction={closeFunction}
          changeView={setAuthInputViewName}
        />
      ) : (
        ''
      )}
      {authInputViewName === authInputViewNames.AuthSigninInputView ? (
        <AuthSigninInputView
          closeFunction={closeFunction}
          changeView={setAuthInputViewName}
        />
      ) : (
        ''
      )}
      {authInputViewName === authInputViewNames.AuthSocialRegisterInputView ? (
        <AuthSocialRegisterInputView closeFunction={closeFunction} />
      ) : (
        ''
      )}
      {authInputViewName === authInputViewNames.AuthForgotPasswordInputView ? (
        <AuthForgotPasswordInputView
          closeFunction={closeFunction}
          changeView={setAuthInputViewName}
        />
      ) : (
        ''
      )}
    </div>
  )
}

export default AuthInputView
