import React from 'react'
import PropTypes from 'prop-types'

function Modal({ closeFunction, children }) {
  const overlayClickHandler = (evt) => {
    if (evt.target === evt.currentTarget) {
      closeFunction()
    }
  }
  return (
    <>
      <div
        className="si-modal modal fade si-auth-modal in"
        id="ssModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="ssModalLabel"
        aria-hidden="true"
        style={{ display: 'block', paddingRight: '15px' }}
        onClick={overlayClickHandler}
      >
        <div className="modal-dialog">{children}</div>
      </div>
      <div className="modal-backdrop fade in"></div>
    </>
  )
}
Modal.propTypes = {
  closeFunction: PropTypes.func.isRequired,
}
export default Modal
