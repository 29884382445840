import React, { useEffect, useState } from 'react'
import communities from '../../../database/comunities'
import { Link } from 'react-router-dom'

const FeaturedNeighborhoods = () => {
  const [featuredNeighborhoods, setFeaturedNeighborhoods] = useState([])
  useEffect(() => {
    const currFeaturedNeighborhoods = communities.filter(
      (community) =>
        community.type === 'neighborhood' && community.is_featured === true
    )
    setFeaturedNeighborhoods(currFeaturedNeighborhoods)
  }, [])
  return (
    <div className="si-container si-content-label js-content-label">
      <div className="si-row">
        <div className="si-content-label__title">Featured Neighborhoods</div>
        <ul className="si-content-label__body">
          {featuredNeighborhoods.map((featuredNeighborhood, index) => (
            <li key={index} className="si-content-label__link">
              <Link
                to={`/communities/${featuredNeighborhood.alias}/`}
                title={featuredNeighborhood.name}
              >
                {featuredNeighborhood.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default FeaturedNeighborhoods
