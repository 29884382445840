import React from 'react'

import { authInputViewNames } from '../../dictionaries/inputViews'

const styleDisplayNone = { display: 'none' }

function AuthForgotPasswordInputView({ closeFunction, changeView }) {
  const openSigninInputViewButtonHandler = (evt) => {
    evt.preventDefault()
    changeView(authInputViewNames.AuthSigninInputView)
  }
  return (
    <div id="authForgotPasswordInputView">
      <div className="modal-header">
        <button
          type="button"
          className="close js-auth-close"
          style={{ display: 'blick' }}
          onClick={closeFunction}
        >
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </button>
        <h4 className="modal-title">Reset Your Password</h4>
      </div>
      <div id="authForgotPasswordContainer" className="si-auth-modal__form-sm">
        <form
          id="authForgotPasswordForm"
          name="authForgotPasswordForm"
          className="si-form"
          noValidate="noValidate"
        >
          <div className="modal-body">
            <div
              id="authForgotPasswordMessage"
              style={styleDisplayNone}
              className="si-form__error"
            ></div>
            <div className="si-row">
              <div className="form-group si-form__full">
                <label
                  className="control-label"
                  htmlFor="authForgotPasswordEmail"
                >
                  Email
                </label>
                <input
                  onChange={(evt) => console.log(evt.target.value)}
                  type="email"
                  name="email"
                  id="authForgotPasswordEmail"
                  value=""
                  className="form-control"
                  placeholder="name@website.com"
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              id="authForgotPasswordSubmit"
              className="si-btn si-btn--primary si-auth-modal__form-sm-btn"
            >
              Send Password
            </button>
            <p className="si-view-link">
              Return to{' '}
              <a
                href="/"
                className="js-signin-link"
                onClick={openSigninInputViewButtonHandler}
              >
                Login Form
              </a>
            </p>
            {/*  <div className="si-captcha-disclaimer">
              This site is protected by reCAPTCHA and the Google{' '}
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>{' '}
              and{' '}
              <a
                href="https://policies.google.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>{' '}
              apply.
            </div> */}
          </div>
        </form>
      </div>
      <div id="authForgotPasswordSuccessContainer" style={styleDisplayNone}>
        <div className="modal-body">
          <p>
            We've sent an email to{' '}
            <strong id="authForgotPasswordUserEmail">you</strong> with
            instructions for resetting your password. Please check your email
            and click the reset password link.
          </p>
          <p>
            Didn't receive the email? Please check your Spam folder, or{' '}
            <a href="/" className="js-register-link">
              Register
            </a>{' '}
            with a Different Email Address.
          </p>
        </div>
        <div className="modal-footer">
          <p className="si-view-link">
            Return to{' '}
            <a href="/" className="js-signin-link">
              Login Form
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default AuthForgotPasswordInputView
