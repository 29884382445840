import React from 'react'
import PropTypes from 'prop-types'

import PhoneInput from '../inputs/Phone-input'
import EmailInput from '../inputs/Email-input'

function Form(props) {
  const nameChangeHandler = (evt) => {
    props.setName(evt.target.value)
  }
  const optionChangeHandler = (evt) => {
    props.setContactPreference(evt.target.value)
  }
  const commentsChangeHandler = (evt) => {
    props.setComments(evt.target.value)
  }
  return (
    <>
      <div className="si-row">
        <div className="form-group si-form__column">
          <label className="control-label" htmlFor="requestName">
            Name:
          </label>
          <input
            type="text"
            required
            name="requestName"
            tabIndex="1"
            value={props.name}
            className="form-control"
            aria-required="true"
            aria-invalid="false"
            onChange={nameChangeHandler}
          />
        </div>
        <div className="form-group si-form__column">
          <label className="control-label" htmlFor="requestEmail">
            Email Address:
          </label>
          <EmailInput email={props.email} setEmail={props.setEmail} />
        </div>
        <div className="form-group si-form__column">
          <label className="control-label" htmlFor="requestCellPhone">
            Phone:
          </label>
          <PhoneInput phone={props.phone} setPhone={props.setPhone} />
        </div>
        <div className="form-group si-form__column">
          <label
            className="control-label"
            htmlFor="requestContactPreference"
            style={{ marginBottom: '12px' }}
          >
            Contact Preference:
          </label>
          <br />
          <label className="radio-inline">
            <input
              type="radio"
              required
              name="requestContactPreference"
              tabIndex="4"
              value="phone"
              onChange={optionChangeHandler}
            />
            Phone
          </label>
          <label className="radio-inline">
            <input
              type="radio"
              required
              name="requestContactPreference"
              tabIndex="5"
              value="email"
              onChange={optionChangeHandler}
            />
            Email
          </label>
          <label className="radio-inline">
            <input
              type="radio"
              required
              name="requestContactPreference"
              tabIndex="6"
              value="either"
              onChange={optionChangeHandler}
            />
            Either
          </label>
        </div>

        <div className="form-group si-form__full">
          <label className="control-label" htmlFor="requestComments">
            Questions / Comments:
          </label>
          <textarea
            name="requestComments"
            tabIndex="8"
            id="ssRequestComments"
            className="form-control"
            rows="4"
            value={props.comments}
            onChange={commentsChangeHandler}
          ></textarea>
        </div>
      </div>
    </>
  )
}
Form.propTypes = {
  name: PropTypes.string.isRequired,
  setName: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
  phone: PropTypes.string.isRequired,
  setPhone: PropTypes.func.isRequired,
  contactPreference: PropTypes.string,
  setContactPreference: PropTypes.func.isRequired,
  comments: PropTypes.string.isRequired,
  setComments: PropTypes.func.isRequired,
}
export default Form
