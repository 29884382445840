import { UPDATE_PROPERTY_SEARCH_OBJECT } from '../actions/action-types'
import {
  getSearchDataFromLocalStorage,
  saveSeadrchDataToLocalStorage,
} from '../../helpers/local-storage'

const savedLocalStorage = getSearchDataFromLocalStorage()
const initialState = {
  propertySearch: savedLocalStorage
    ? JSON.parse(savedLocalStorage)
    : { isResidential: true },
}

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_PROPERTY_SEARCH_OBJECT:
      saveSeadrchDataToLocalStorage(action.payload)
      console.log({
        ...state,
        propertySearch: action.payload,
      })
      return {
        ...state,
        propertySearch: action.payload,
      }
    default:
      return state
  }
}
