import React, { useEffect } from 'react'

import InputCustomSelect from '../inputs/Input-custom-select'

function MinMaxBlock(props) {
  useEffect(() => {
    if (props.min !== '' && props.max !== '' && +props.min > +props.max) {
      exchangeValues()
    }
  })

  const exchangeValues = () => {
    const min = props.min
    const max = props.max
    props.setMin(max)
    props.setMax(min)
  }
  return (
    <>
      <div className="si-sf-primary__item">
        <h2>{props.name}</h2>
        <InputCustomSelect
          value={props.min}
          onChange={props.setMin}
          selectData={props.minSelectData}
        />
      </div>
      <div className="si-sf-primary__item">
        <h2>&nbsp;</h2>
        <InputCustomSelect
          value={props.max}
          onChange={props.setMax}
          selectData={props.maxSelectData}
        />
      </div>
    </>
  )
}

export default MinMaxBlock
