import React, { useState, useEffect, useCallback } from 'react'
import Api from '../../../../api/index'

import ResultsListItem from './Results-list-item'
// import ResultsListItemLoading from './Results-list-item-loading'

import ResultsGalleryItem from './Results-gallery-item'
// import ResultsGalleryItemLoading from './Results-gallery-item-loading'

import Pagination from './Pagination'

import './results-list.css'
const styleDisplayNone = { display: 'none' }

function debounce(func) {
  var timeout = null
  return function (status, searchData) {
    clearTimeout(timeout)
    timeout = setTimeout(func, 400, status, searchData)
  }
}
const ListTypes = {
  MAP: 'MAP',
  LIST: 'LIST',
  GALLERY: 'GALLERY',
}
const ResultsList = (props) => {
  const [properties, setProperties] = useState([])
  const [onSiteTimestamps, setOnSiteTimestamps] = useState({})
  const [isGettingProperties, setIsGettingProperties] = useState(false)
  const [page, setPage] = useState(1)
  const [pageLimit] = useState(12)
  const [quantity, setQuantity] = useState(0)
  const [gotPhotoIds, setGotPhotoIds] = useState({})

  const getProperties = async (status, requestObject) => {
    if (requestObject.searchData === undefined) {
      return null
    }
    setIsGettingProperties(true)

    let newProperties = null

    try {
      newProperties = await Api.propertySearch.find(requestObject)
    } catch (error) {
      console.log(error)
      newProperties = { data: { properties: [], quantity: 0, timestamps: [] } }
    }
    console.log(newProperties.data)
    if (!status.isCanceled) {
      setProperties(newProperties.data.properties)
      setQuantity(newProperties.data.quantity)
      setOnSiteTimestamps(newProperties.data.timestamps)
    }
    setIsGettingProperties(false)
  }

  const updateProperties = useCallback(debounce(getProperties), [])

  useEffect(() => {
    const status = { isCanceled: false }
    const requestObject = {
      searchData: props.propertySearch,
      page,
      pageLimit,
    }
    updateProperties(status, requestObject)
    return () => {
      status.isCanceled = true
    }
  }, [props.propertySearch, updateProperties, page, pageLimit, gotPhotoIds])

  useEffect(() => {
    const status = { isCanceled: false }
    const requestObject = {
      searchData: props.propertySearch,
      page,
      pageLimit,
    }
    const timeoutId = setTimeout(() => {
      if (!status.isCanceled) {
        updateProperties({ isCanceled: false }, requestObject)
      }
    }, 60 * 1000)
    return () => {
      status.isCanceled = true
      clearTimeout(timeoutId)
    }
  })

  const getPageQuantity = () => {
    return Math.ceil(quantity / pageLimit)
  }

  const getPropertyIdsWithoutPhotos = (properties) => {
    return properties
      .filter(
        (property) =>
          property.Photos.length === 0 && gotPhotoIds[property.Id] !== true
      )
      .map((property) => property.Id)
  }

  const updatePropertiesPhotosInDB = async (status, propertiesIds) => {
    if (propertiesIds.length > 0) {
      try {
        const result = await Api.propertySearch.updatePhotos(propertiesIds)
        if (result.data === true) {
          if (!status.isCanceled) {
            const newGotPhotoIds = propertiesIds.reduce(
              (newGotPhotoIds, id) => {
                return Object.assign(newGotPhotoIds, { [id]: true })
              },
              {}
            )
            setGotPhotoIds(Object.assign({}, gotPhotoIds, newGotPhotoIds))
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
    return true
  }

  useEffect(() => {
    const status = { isCanceled: false }
    updatePropertiesPhotosInDB(status, getPropertyIdsWithoutPhotos(properties))
    return () => {
      status.isCanceled = true
    }
    // eslint-disable-next-line
  }, [properties])

  const pageChangeHandler = (evt) => {
    const modificator = evt.target.dataset.action === 'next' ? 1 : -1
    setPage((curentPage) => {
      const newPageNumber = curentPage + modificator
      if (newPageNumber < 1 || newPageNumber > getPageQuantity()) {
        return curentPage
      }
      return newPageNumber
    })
  }

  return (
    <div className="si-sapp-result">
      <div className="si-container si-filter js-listing-count-pager">
        <div className="si-row">
          <div className="si-filter__status">
            <span className="js-listing-count">
              {quantity} {quantity > 1 ? 'Properties' : 'Property'}
            </span>
          </div>
          <div
            className="si-filter__pager js-pager"
            style={{ display: 'block' }}
          >
            <button
              onClick={pageChangeHandler}
              className="js-prev is-disabled"
              rel="prev"
            >
              <i data-action="prev" className="fa fa-chevron-left"></i>
            </button>
            <span className="js-page-nums">
              Page {page} of {getPageQuantity()}
            </span>
            <button
              onClick={pageChangeHandler}
              data-action="next"
              className="js-next is-disabled"
              rel="next"
            >
              <i data-action="next" className="fa fa-chevron-right"></i>
            </button>
          </div>
          <div className="si-sapp-title">
            <h1 className="js-page-title">
              Property Search
              {isGettingProperties ? (
                <>
                  {/* <i className="fa fa-circle-o-notch fa-fw fa-spin"></i> */}
                </>
              ) : (
                ''
              )}
            </h1>
          </div>
        </div>
      </div>

      <div className="tab-content si-listings">
        {/* GALLERY */}
        <div
          className={`tab-pane fade si-container ${
            props.listType === ListTypes.GALLERY ? 'active in' : ''
          }`}
          id="sappGallery"
        >
          <div className="si-listings-gallery">
            <div className="row">
              {properties.map((property) => (
                <ResultsGalleryItem
                  key={property.Id}
                  property={property}
                  timestamp={
                    onSiteTimestamps[property.Id]
                      ? onSiteTimestamps[property.Id]
                      : null
                  }
                />
              ))}
            </div>
          </div>
        </div>
        {/* LIST */}
        <div
          className={`tab-pane fade si-container ${
            props.listType === ListTypes.LIST ? 'active in' : ''
          }`}
          id="sappList"
        >
          <div className="si-listings-list">
            {properties.map((property) => (
              <ResultsListItem
                key={property.Id}
                property={property}
                timestamp={
                  onSiteTimestamps[property.Id]
                    ? onSiteTimestamps[property.Id]
                    : null
                }
              />
            ))}
          </div>
        </div>
        {/* MAP */}
        <div
          className={`tab-pane fade si-container ${
            props.listType === ListTypes.MAP ? 'active in' : ''
          }`}
          id="sappMap"
          style={{ margin: 0 }}
        >
          MAP VIEW IS UNDER CONSTRUCTION NOW
          <div className="si-map-container">
            <div id="mapCanvas"></div>
            <div
              className="si-map-message js-map-tooltip"
              style={styleDisplayNone}
            ></div>
            <div className="si-map-polygon">
              <a href="/" className=" js-polygon" style={styleDisplayNone}>
                <span className="js-polygon-label">Draw</span>
              </a>
            </div>
            <div
              id="mapPropertyItemContainer"
              className="si-map-listing si-container"
              style={styleDisplayNone}
            ></div>
            <div
              id="mapProgress"
              className="si-map-progress"
              style={styleDisplayNone}
            >
              Loading listing information...
              <span className="count">0%</span>
              <span className="line"></span>
            </div>
          </div>
          <div id="mapList" className="si-map-listings">
            <div className="clearfix">
              {/* <div className="si-map-listings__count js-map-listings-count">
                Loading...
              </div> */}
              <ul
                className="si-map-listings__tabs js-map-listings-tabs"
                style={styleDisplayNone}
              >
                <li className="active">
                  <a href="#sappMapGallery">Photos</a>
                </li>
                <li>
                  <a href="#sappMapList">List</a>
                </li>
              </ul>
            </div>
            <div
              className="tab-content js-map-listings-tab-content"
              style={styleDisplayNone}
            >
              <div className="tab-pane si-map-list" id="sappMapList">
                <table>
                  <thead>
                    <tr>
                      <th data-value="1">Address</th>
                      <th data-value="3">Price</th>
                      <th data-value="4">Beds</th>
                      <th data-value="5">Baths</th>
                      <th className="is-sqft" data-value="6">
                        Sq. Ft.
                      </th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                </table>
              </div>
              <div
                className="tab-pane active si-map-gallery si-listings-gallery"
                id="sappMapGallery"
              ></div>
            </div>
            <div
              className="si-app-message js-map-message"
              style={styleDisplayNone}
            ></div>
          </div>
        </div>
      </div>
      {properties.length > 0 ? (
        <Pagination
          setCurrentPage={setPage}
          currentPage={page}
          pagesQuantity={getPageQuantity()}
        />
      ) : null}
    </div>
  )
}

export default ResultsList
