import React from 'react'
import Breadcrumb from '../../../common/Breadcrumb'
import Sidebar from '../../../common/sidebar/Sidebar'

const MakingAnOfferPage = () => {
  return (
    <>
      <div className={'si-site-container col-md-8 si-site-container--full'}>
        <Breadcrumb />
        <div className={'si-content-area'}>
          <h1>Making an Offer</h1>
          <p>
            Before the offer to purchase is created, it is very important that
            you have been at least pre-qualified or better yet pre-approved by a
            lender.
          </p>
          <p>
            This is one of the best negotiating tools a buyer can have. It shows
            the seller that you are financially able to purchase the home. After
            you have found the right home, it is time to prepare the offer.
          </p>
          <p>
            When you are buying a home, there are many problems that the seller
            is obligated to disclose. For example, in most states, it is illegal
            to withhold information about major physical defects on the
            property, but, these disclosures don't always paint the entire
            picture of the home. Here are six questions you may want to ask that
            can offer additional insight about the prospective home before you
            make a final decision.
          </p>
          <p>
            <strong>1) Why is the seller selling the house? </strong>This
            question may help you evaluate the "real value" of the property. Is
            there something about the house the seller does not like? If so, you
            may be able to adjust the purchase offer accordingly.
          </p>
          <p>
            <strong>2) How much did the seller pay for the home?</strong> This
            question can, in some instances, help the buyer negotiate a better
            deal-maybe even get the seller to carry part of the loan. However,
            it is important to remember that the purchase price is influenced by
            several factors, like the current market value and any improvements
            the seller may have made to the home. The original purchase price
            might not have anything to do with the current value of the house.
          </p>
          <p>
            <strong>
              3) What does the seller like most and least about the property?
            </strong>{' '}
            By asking the seller what he or she likes most and least about the
            property, you might get some interesting information. In a few
            cases, what a seller likes the most about a home might actually be
            something the buyer is looking to avoid. For example, if the seller
            describes his house as being in a "happening community," the buyer
            might consider this a negative factor because the area may be too
            noisy or busy for his or her taste.
          </p>
          <p>
            <strong>
              4) Has the seller had any problems with the home in the past?{' '}
            </strong>
            It is also a good idea to ask the seller if he or she has had any
            problems with the home while living there. Has the seller had
            problems with a leakage from the upstairs bedroom in the past? If
            so, even if the leak has been corrected, the floor and walls around
            the bathroom might have been damaged. You should also check that
            these items were repaired properly.
          </p>
          <p>
            <strong>5) Are there any nuisances or problem neighbors?</strong>{' '}
            Use this answer to find out about any noisy neighbors, barking dogs,
            heavy airplane traffic or even planned changes to the community,
            such as a planned street widening. This may give you insight on why
            the seller is really moving.
          </p>
          <p>
            <strong>6) How are the public schools in the area?</strong> Because
            the value of a community is usually greatly influenced by the public
            schools in the area, finding out the buyer's perception can give you
            some insight about the quality of the area's schools.
          </p>
          <p>
            Knowing all you can about a prospective home, not only helps you
            decide if it's the home of your dreams, but what offer to make as
            well. Your real estate professional can help you get your key
            questions answered and give you advice on how to evaluate your
            findings.
          </p>
        </div>
      </div>
      <Sidebar />
    </>
  )
}

export default MakingAnOfferPage
