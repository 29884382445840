import React from 'react'
import { formatMoney } from '../../../../../helpers/text-helpers'

import TimeCounter from '../../results/Time-counter'

function PrimaryInformation({ property }) {
  const timestamp = 1
  return (
    <section className="si-ld-primary">
      <div className="si-ld-primary__info clearfix">
        <div className="si-ld-primary__status">
          <strong>Status:</strong>

          <span className="is-active">{property.StandardFields.MlsStatus}</span>
        </div>

        <div>
          <strong>Property Type:</strong>
          <span>
            {property.StandardFields.PropertyTypeLabel} -{' '}
            {property.StandardFields.PropertySubType}
          </span>
        </div>

        <div>
          <strong>MLS #:</strong>
          <span>
            {property.StandardFields.ListingPrefix}{' '}
            {property.StandardFields.ListingPrefix &&
            property.StandardFields.ListingNumber
              ? '-'
              : ''}
            {property.StandardFields.ListingNumber}
          </span>
        </div>

        <div>
          <strong>Sq. Feet:</strong>
          <span>{property.StandardFields.BuildingAreaTotal}</span>
        </div>

        <div>
          <strong>On Site:</strong>
          <span>
            {timestamp ? (
              <TimeCounter
                timestamp={timestamp}
                OnMarketDate={property.StandardFields.OnMarketDate}
              />
            ) : (
              ''
            )}
          </span>
        </div>

        <div>
          <strong>Lot Size:</strong>
          <span>{property.StandardFields.LotSizeAcres} Acres</span>
        </div>

        <div>
          <strong>County:</strong>
          <span>{property.StandardFields.CountyOrParish}</span>
        </div>

        <div>
          {+property.StandardFields.AssociationFee ? (
            <>
              <strong>
                {property.StandardFields.AssociationFeeFrequency} HOA:
              </strong>

              <span style={{ textColor: 'red' }}>
                ${formatMoney(property.StandardFields.AssociationFee, 0)}
              </span>
            </>
          ) : (
            ''
          )}
        </div>
      </div>

      <div className="si-ld-description js-listing-description"></div>
    </section>
  )
}

export default PrimaryInformation
