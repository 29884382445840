import React from 'react'
import Breadcrumb from '../../../common/Breadcrumb'
import Sidebar from '../../../common/sidebar/Sidebar'

const UtilityServicesPage = () => {
  return (
    <>
      <div className={'si-site-container col-md-8 si-site-container--full'}>
        <Breadcrumb />
        <div className={'si-content-area'}>
          <h1 className="c15" style={{ textAlign: 'center' }}>
            Get Connected
          </h1>
          <p className="c2 c9">
            Welcome to Southern Utah. Here’s what you need to know to get your
            utility services connected.&nbsp;&nbsp;
          </p>
          <h2 className="c2 c9" style={{ textAlign: 'center' }}>
            Washington
          </h2>
          <h3 className="c2 c9" style={{ textAlign: 'center' }}>
            Electricity
          </h3>
          <p>
            <strong>North of Virgin River</strong> =&nbsp;
            <a
              className="c10"
              href="https://www.google.com/url?q=https://washingtoncity.org/services/utilitybilling&amp;sa=D&amp;ust=1559950422068000"
            >
              Washington City
            </a>
            . Sign up for services online&nbsp;
            <a
              className="c10"
              href="https://www.google.com/url?q=https://washingtoncity.org/services/utilitybilling/signup&amp;sa=D&amp;ust=1559950422069000"
            >
              here
            </a>
            . Allow at least two days for set up. &nbsp;This is also the portal
            to set up online payment.
          </p>
          <ul>
            <li>Set up fee: $5</li>
            <li>
              Deposit: $150 for residential accounts; $300 if setting up
              electricity and water. &nbsp;Ask about fee waiver options.
            </li>
          </ul>
          <p>
            <strong>South of Virgin River</strong> =&nbsp;
            <a
              className="c10"
              href="https://www.google.com/url?q=https://www.dixiepower.com/&amp;sa=D&amp;ust=1559950422070000"
            >
              Dixie Power
            </a>
            &nbsp;(blue area on the map below). &nbsp;Check service area&nbsp;
            <a
              className="c10"
              href="https://www.google.com/url?q=https://www.dixiepower.com/services/service-areas/&amp;sa=D&amp;ust=1559950422071000"
            >
              here
            </a>
            . &nbsp;Sign up for services online&nbsp;
            <a
              className="c10"
              href="https://www.google.com/url?q=https://www.dixiepower.com/services/new-member/&amp;sa=D&amp;ust=1559950422071000"
            >
              here
            </a>
            .
          </p>
          <ul>
            <li>Set up fee: $30</li>
            <li>&nbsp;Deposit: Up to $200 depending on credit history</li>
            <li>
              <img
                src="/images/userfiles/Dixie_Power_-_Washington.jpeg"
                width="1585"
                height="920"
                alt=""
              />
            </li>
          </ul>
          <h3 style={{ textAlign: 'center' }}>Gas</h3>
          <p>
            <strong>Dominion Energy</strong> (fka Questar Gas). &nbsp;While the
            majority of Washington County is in Dominion Energy's service area,
            check the service map&nbsp;
            <a
              className="c10"
              href="https://www.google.com/url?q=https://www.dominionenergy.com/company/moving-energy/western-gas-operations&amp;sa=D&amp;ust=1559950422073000"
            >
              here
            </a>
            &nbsp;to make sure. &nbsp;Sign up for services online&nbsp;
            <a
              className="c10"
              href="https://www.google.com/url?q=https://www.questargas.com/GasServiceAppWeb/resgasserviceapp&amp;sa=D&amp;ust=1559950422073000"
            >
              here
            </a>
            .
          </p>
          <ul>
            <li>Set up fees:</li>
          </ul>
          <ol>
            <li className="c2 c8">
              Dominion Energy charges a $30 plus tax connection fee to initiate
              natural gas service, check the appliances and activate a new
              account at a new or existing home.
            </li>
            <li className="c2 c8">
              Dominion Energy charges $15 plus tax to initiate service and
              remove the meter lock so a qualified individual may turn on the
              meter and light the appliances.
            </li>
            <li className="c2 c8">
              Dominion Energy charges an $8 plus tax connection fee if service
              is already on at the premises and service needs to be transferred
              into a new name.
            </li>
          </ol>
          <ul>
            <li>
              Deposit. In most cases, Dominion Energy does not require a
              security deposit to initiate residential service.&nbsp;&nbsp;
            </li>
          </ul>
          <h3 style={{ textAlign: 'center' }}>
            <span style={{ color: '#000000', fontSize: '30px' }}>
              Water, Sewer, and Garbage
            </span>
          </h3>
          <p>
            <strong>
              <a
                className="c10"
                href="https://www.google.com/url?q=https://washingtoncity.org/services/utilitybilling&amp;sa=D&amp;ust=1559950422075000"
              >
                Washington City
              </a>
              .
            </strong>{' '}
            Sign up for services online&nbsp;
            <a
              className="c10"
              href="https://www.google.com/url?q=https://washingtoncity.org/services/utilitybilling/signup&amp;sa=D&amp;ust=1559950422076000"
            >
              here
            </a>
            . Allow at least two days for set up. &nbsp;This also the portal to
            set up online payment.
          </p>
          <ul>
            <li>Set up fee: $5</li>
            <li>
              Deposit: $150 for residential accounts; $300 if setting up
              electricity and water. &nbsp;Ask about fee waiver options.
            </li>
          </ul>
          <h3 style={{ textAlign: 'center' }}>Internet and Cable</h3>
          <p>
            There are lots of options depending on the speed, price bundling
            with other services (e.g. phone and cable) and your location.
            &nbsp;Make sure the provider will test the speed of your connection
            or give you an idea of connectivity in your neighborhood.
            &nbsp;Also, Washington County has limited channel reception without
            cable service. &nbsp;Don’t expect to hook up your TV and get the
            major television networks. &nbsp;Check out these websites to help
            you compare internet and cable providers.
          </p>
          <ol>
            <li className="c0">
              <a
                className="c10"
                href="https://www.google.com/url?q=https://www.inmyarea.com/internet/84780/providers&amp;sa=D&amp;ust=1559950422077000"
              >
                https://www.inmyarea.com/internet/84780/providers
              </a>
            </li>
            <li className="c0">
              <a
                className="c10"
                href="https://www.google.com/url?q=https://broadbandnow.com/Utah/Washington?zip%3D84780&amp;sa=D&amp;ust=1559950422077000"
              >
                https://broadbandnow.com/Utah/Washington?zip=84780
              </a>
            </li>
          </ol>
        </div>
      </div>
      <Sidebar />
    </>
  )
}
export default UtilityServicesPage
