import React from 'react'

const NotFoundPage = () => {
  return (
    <div className={'si-content-area'}>
      <h1>GiddyDigs.com</h1>
      <h2>OOPS! The page you are looking for cannot be found.</h2>
      <p>
        Sorry, but the page you are looking for does not exist. This can happen
        for a couple of different reasons:
      </p>
      <ol>
        <li>
          If you are looking for a specific property, it may have already sold.
          Please visit our&nbsp;
          <a href="/property-search/search-form/" title="Search the MLS">
            Property Search
          </a>{' '}
          page to find other homes that may meet your needs.
        </li>
        <li>
          You may have mis-typed the URL of the page you are looking for. Please
          retype the page address in your browser and try again.
        </li>
        <li>
          If you believe that you've reached this page in error, it is possible
          that we have a broken link or error in the website. Please{' '}
          <a href="/contact/" title="Contact Us">
            contact us{' '}
          </a>
          and we will be happy to help you offline!
        </li>
      </ol>
      <p>
        In the meantime, here are links to our more popular sections of our
        website.
      </p>
      <div className={'house_text'}>
        <a href="/property-search/search-form/" title="Search the MLS">
          <img
            src="https://assets.site-static.com/userFiles/984/image/thmb_advanced_search.jpg"
            width="140"
            height="100"
            alt=""
          />
        </a>
        <h3>Advanced Search</h3>
        <p>
          Search the MLS - the consumer database of homes for sale in{' '}
          <span>
            St. George,<span>UT</span>
          </span>
          . Search homes by price, location, or neighborhood amenities.{' '}
          <a href="/property-search/search-form/" title="Search the MLS">
            Go to PROPERTY SEARCH
          </a>
        </p>
      </div>
      <div className={'house_text'}>
        <a
          href="/property-search/results/?searchtype=3"
          title="Search the MLS by Map"
        >
          <img
            src="https://assets.site-static.com/userFiles/984/image/thmb_map_search.png"
            alt="Search by Map"
            title="Search by map"
            width="140"
            height="100"
          />
        </a>
        <h3>Map Search</h3>
        <p>
          Prefer to search by map? Use our interactive map search to search
          homes from the sky, including Google Street View and Satellite View.{' '}
          <a
            href="/property-search/results/?searchtype=3"
            title="Search the MLS by Map"
          >
            Go to MAP SEARCH
          </a>
        </p>
      </div>
      <div className={'house_text'}>
        <a href="/communities/" title="Community Guide">
          <img
            src="https://assets.site-static.com/userFiles/984/image/thmb_community_info.jpg"
            alt="Community Info"
            title="Community Info"
            width="140"
            height="100"
          />
        </a>
        <h3>Community Guide</h3>
        <p>
          Are you buying a property in St. George, UT? Access our Community
          Guide to view the list of areas we specialize in, and instantly search
          St. George area real estate lisings by community with a click of a
          button. Go to{' '}
          <a href="/communities/" title="Community Guide">
            COMMUNITY GUIDE
          </a>
        </p>
      </div>
      <div className={'house_text'}>
        <a href="/contact/" title="Contact Us">
          <img
            src="https://assets.site-static.com/userFiles/984/image/thmb_contact.jpg"
            alt="Contact Us"
            title="Contact Us"
            width="140"
            height="100"
          />
        </a>
        <h3>Contact Us</h3>
        <p>
          Have a question, need more detail on a property listing, or simply not
          able to find what you're looking for? We're here to help and we'll
          help you find what you need.{' '}
          <a href="/contact/" title="Contact Us">
            Go to CONTACT US
          </a>
        </p>
      </div>
    </div>
  )
}

export default NotFoundPage
