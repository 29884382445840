import React from 'react'

function AuthRegisterSuccessView({ closeFunction }) {
  return (
    <div id="authRegisterSuccessView">
      <div className="modal-header">
        <button
          type="button"
          className="close js-auth-close"
          style={{ display: 'block' }}
          onClick={closeFunction}
        >
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </button>
        <h4 className="modal-title">Success! Your Registration Is Complete</h4>
      </div>
      <div className="modal-body">
        <p>
          Thank you for registering. You now enjoy full access to all site
          search tools:
        </p>
        <p>
          <i className="fa fa-check-square-o fa-2x pull-left"></i>
          Click the <strong>Property Tracker</strong> link at the top of any
          search page to access your search tools.
        </p>
        <p></p>
        <i className="fa fa-check-square-o fa-2x pull-left"></i>
        Click the <strong>Save This Listing</strong> link on any Property Detail
        page to save it to your favorites.
        <p></p>
        <p></p>
        <i className="fa fa-check-square-o fa-2x pull-left"></i>
        Click the <strong>Save This Search</strong> link at the top of any
        Property Detail or Search Results page to save a search.
        <p></p>
        <p></p>
        <i className="fa fa-check-square-o fa-2x pull-left"></i>
        When saving a search, you will have the option to automatically receive{' '}
        <strong>Email Alerts</strong> when new matching listings hit the market.
        <p></p>
        <p>
          And if you have any questions, problems, comments or suggestions,
          click the Contact button at the top of any page to get in touch!
        </p>
      </div>
      <div className="modal-footer">
        <button type="button" className="si-btn si-btn--default js-modal-close">
          Close
        </button>
      </div>
    </div>
  )
}

export default AuthRegisterSuccessView
