import React from 'react'
import { Link } from 'react-router-dom'
import './Home-quick-search.css'

const HomeDarkBanner = () => {
  return (
    <div className="dark-banner">
      <div className="container">
        <strong>What's Your Home Worth?</strong> Find your home or condo's value
        in today's market, for free.
        <Link
          to="/sell/free-market-analysis/"
          target="_self"
          className="btn-effect"
        >
          <span>Find Out Now</span>
        </Link>
      </div>
    </div>
  )
}

export default HomeDarkBanner
