import React from 'react'
import Breadcrumb from '../../../common/Breadcrumb'
import Sidebar from '../../../common/sidebar/Sidebar'

const AccessibilityPage = () => {
  return (
    <>
      <div className={'si-site-container col-md-8 si-site-container--full'}>
        <Breadcrumb />
        <div className={'si-content-area'}>
          <h1>Accessibility</h1>
          <p>
            GiddyDigs.com is committed to providing a website that is accessible
            to the widest possible audience, regardless of technology or
            ability. We continually strive to meet the standards of level A of
            the World Wide Web Consortium (W3C) Web Content Accessibility
            Guidelines 2.1, which provide guidelines and suggestions for making
            web content more accessible for all users. Conformance with these
            guidelines will help make the web more user friendly for everyone.
          </p>
          <p>
            We welcome any feedback or comments that will help us improve our
            ongoing efforts to increase accessibility to our site. If you are
            having difficulties with any part of the site or if you have any
            other accessibility related concerns or notice any problems, please
            let us know by sending an email to{' '}
            <a href="mailto:Joe@GiddyDigs.com">Joe@GiddyDigs.com</a> with a
            description of the general or specific issue you've encountered and
            the website location of where you encountered the issue. We will be
            happy to work with you to provide additional assistance and make all
            reasonable efforts to ensure accessibility to the site and/or
            provide the content you need to access in a format or method you
            require.
          </p>
        </div>
      </div>
      <Sidebar />
    </>
  )
}

export default AccessibilityPage
