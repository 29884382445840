import React from 'react'
import PropTypes from 'prop-types'

import InputCustomSelect from '../inputs/Input-custom-select'
import {
  propertyFeaturesSelectData,
  landDescriptionSelectData,
  garageParkingSelectData,
  poolSelectData,
  storiesSelectData,
  zoningSelectData,
  inclusionFeaturesSelectData,
  assocDuesIncludeSelectData,
  srHighSchoolsSelectData,
  listingClassSelectData,
} from './aditional-features/aditional-features-select-data'

const SelectedItems = (props) => {
  const labels = props.selectData !== undefined ? props.selectData : []

  const getLabel = (value) => {
    if (labels.length > 0) {
      const foundLabel = labels.find((elem) => elem.value === value)
      if (foundLabel !== undefined) {
        return foundLabel.label
      }
    }
    return value
  }
  return (
    <>
      {props.items.map((item, index) => (
        <div key={index}>
          <span>
            {getLabel(item)}
            <a
              href="#del"
              onClick={props.onDelete}
              data-index={index}
              data-type={props.locationType}
              className="js-remove-location "
            >
              {' '}
            </a>
          </span>
        </div>
      ))}
    </>
  )
}

SelectedItems.propTypes = {
  selectData: PropTypes.arrayOf(PropTypes.object),
  items: PropTypes.array,
  onDelete: PropTypes.func,
  locationType: PropTypes.string,
}

//////////////////////////////////

function AdditionalFeatures(props) {
  const updateSelected = (key, newSelectedItems) => {
    props.setSelectedAdditionalFeatures({
      ...props.selectedAdditionalFeatures,
      [key]: newSelectedItems,
    })
  }
  const addToSelected = (key, value) => {
    if (value !== '') {
      let currentSelectedItems = []
      if (props.selectedAdditionalFeatures[key] !== undefined) {
        currentSelectedItems = props.selectedAdditionalFeatures[key]
      }
      const newSelectedItems = [
        ...new Set(currentSelectedItems.concat([value])),
      ]
      updateSelected(key, newSelectedItems)
    }
  }

  const deleteFromSelected = (key, elementIndexToDelete) => {
    const newSelectedItems = props.selectedAdditionalFeatures[key].filter(
      (el, index) => {
        return index !== +elementIndexToDelete
      }
    )
    updateSelected(key, newSelectedItems)
  }

  const selectedItemsDeleteHandler = (evt) => {
    deleteFromSelected(evt.target.dataset.type, evt.target.dataset.index)
  }

  // Features

  const addToPropertyFeature = (value) => {
    addToSelected('propertyFeature', value)
  }
  const addToLandDescription = (value) => {
    addToSelected('landDescription', value)
  }
  const addGarageParking = (value) => {
    addToSelected('garageParking', value)
  }
  const addPool = (value) => {
    addToSelected('pool', value)
  }
  const addStories = (value) => {
    addToSelected('stories', value)
  }
  const addZoning = (value) => {
    addToSelected('zoning', value)
  }
  const addInclusionFeatures = (value) => {
    addToSelected('inclusionFeatures', value)
  }
  const addAssocDuesInclude = (value) => {
    addToSelected('assocDuesInclude', value)
  }
  const addSrHighSchools = (value) => {
    addToSelected('srHighSchools', value)
  }
  const addListingClass = (value) => {
    addToSelected('listingClass', value)
  }

  return (
    <section className="si-sf-section">
      <h2>Additional Features</h2>

      <div className="si-sf-feature si-row js-feature-criteria">
        <div className="si-sf-feature__item">
          <InputCustomSelect
            value={''}
            onChange={addToPropertyFeature}
            selectData={propertyFeaturesSelectData}
          />

          <div className="si-selected-items is-block js-selected-features">
            {props.selectedAdditionalFeatures['propertyFeature'] ? (
              <SelectedItems
                selectData={propertyFeaturesSelectData}
                items={props.selectedAdditionalFeatures['propertyFeature']}
                locationType={'propertyFeature'}
                onDelete={selectedItemsDeleteHandler}
              />
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="si-sf-feature__item">
          <InputCustomSelect
            value={''}
            onChange={addToLandDescription}
            selectData={landDescriptionSelectData}
          />
          <div className="si-selected-items is-block js-selected-features">
            {props.selectedAdditionalFeatures['landDescription'] ? (
              <SelectedItems
                selectData={landDescriptionSelectData}
                items={props.selectedAdditionalFeatures['landDescription']}
                locationType={'landDescription'}
                onDelete={selectedItemsDeleteHandler}
              />
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="si-sf-feature__item">
          <InputCustomSelect
            value={''}
            onChange={addGarageParking}
            selectData={garageParkingSelectData}
          />
          <div className="si-selected-items is-block js-selected-features">
            {props.selectedAdditionalFeatures['garageParking'] ? (
              <SelectedItems
                selectData={garageParkingSelectData}
                items={props.selectedAdditionalFeatures['garageParking']}
                locationType={'garageParking'}
                onDelete={selectedItemsDeleteHandler}
              />
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="si-sf-feature__item">
          <InputCustomSelect
            value={''}
            onChange={addPool}
            selectData={poolSelectData}
          />
          <div className="si-selected-items is-block js-selected-features">
            {props.selectedAdditionalFeatures['pool'] ? (
              <SelectedItems
                selectData={poolSelectData}
                items={props.selectedAdditionalFeatures['pool']}
                locationType={'pool'}
                onDelete={selectedItemsDeleteHandler}
              />
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="si-sf-feature__item">
          <InputCustomSelect
            value={''}
            onChange={addStories}
            selectData={storiesSelectData}
          />
          <div className="si-selected-items is-block js-selected-features">
            {props.selectedAdditionalFeatures['stories'] ? (
              <SelectedItems
                selectData={storiesSelectData}
                items={props.selectedAdditionalFeatures['stories']}
                locationType={'stories'}
                onDelete={selectedItemsDeleteHandler}
              />
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="si-sf-feature__item">
          <InputCustomSelect
            value={''}
            onChange={addZoning}
            selectData={zoningSelectData}
          />
          <div className="si-selected-items is-block js-selected-features">
            {props.selectedAdditionalFeatures['zoning'] ? (
              <SelectedItems
                selectData={zoningSelectData}
                items={props.selectedAdditionalFeatures['zoning']}
                locationType={'zoning'}
                onDelete={selectedItemsDeleteHandler}
              />
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="si-sf-feature__item">
          <InputCustomSelect
            value={''}
            onChange={addInclusionFeatures}
            selectData={inclusionFeaturesSelectData}
          />
          <div className="si-selected-items is-block js-selected-features">
            {props.selectedAdditionalFeatures['inclusionFeatures'] ? (
              <SelectedItems
                selectData={inclusionFeaturesSelectData}
                items={props.selectedAdditionalFeatures['inclusionFeatures']}
                locationType={'inclusionFeatures'}
                onDelete={selectedItemsDeleteHandler}
              />
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="si-sf-feature__item">
          <InputCustomSelect
            value={''}
            onChange={addAssocDuesInclude}
            selectData={assocDuesIncludeSelectData}
          />

          <div className="si-selected-items is-block js-selected-features">
            {props.selectedAdditionalFeatures['assocDuesInclude'] ? (
              <SelectedItems
                selectData={assocDuesIncludeSelectData}
                items={props.selectedAdditionalFeatures['assocDuesInclude']}
                locationType={'assocDuesInclude'}
                onDelete={selectedItemsDeleteHandler}
              />
            ) : (
              ''
            )}
          </div>
        </div>

        <div className="si-sf-feature__item">
          <InputCustomSelect
            value={''}
            onChange={addSrHighSchools}
            selectData={srHighSchoolsSelectData}
          />

          <div className="si-selected-items is-block js-selected-features">
            {props.selectedAdditionalFeatures['srHighSchools'] ? (
              <SelectedItems
                selectData={srHighSchoolsSelectData}
                items={props.selectedAdditionalFeatures['srHighSchools']}
                locationType={'srHighSchools'}
                onDelete={selectedItemsDeleteHandler}
              />
            ) : (
              ''
            )}
          </div>
        </div>

        <div className="si-sf-feature__item">
          <InputCustomSelect
            value={''}
            onChange={addListingClass}
            selectData={listingClassSelectData}
          />

          <div className="si-selected-items is-block js-selected-features">
            {props.selectedAdditionalFeatures['listingClass'] ? (
              <SelectedItems
                selectData={listingClassSelectData}
                items={props.selectedAdditionalFeatures['listingClass']}
                locationType={'listingClass'}
                onDelete={selectedItemsDeleteHandler}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default AdditionalFeatures
