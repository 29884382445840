import React from 'react'
import Breadcrumb from '../../../common/Breadcrumb'
import PropertySearchFormMain from '../../../common/property-search/Property-search-form-main'


const SearchFormPage = () => {
  return (
    <>
        <Breadcrumb />
        <PropertySearchFormMain />
    </>
  )
}

export default SearchFormPage
