import React from 'react'

function AuthDeletedAccountView({ closeFunction }) {
  return (
    <div id="authDeletedAccountView">
      <div className="modal-header">
        <button
          type="button"
          className="close js-auth-close"
          style={{ display: 'block' }}
          onClick={closeFunction}
        >
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </button>
        <h4 className="modal-title">Account On Hold!</h4>
      </div>
      <div className="modal-body">
        <p className="js-error-message">
          Your account has been placed on-hold by a site administrator. Please
          contact us with any questions.
        </p>
      </div>
      <div className="modal-footer">
        <button type="button" className="si-btn si-btn--default js-modal-close">
          Close
        </button>
      </div>
    </div>
  )
}

export default AuthDeletedAccountView
