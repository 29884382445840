import React from 'react'

const SelectedItems = (props) => {
  return (
    <>
      {props.items.map((item, index) => (
        <div key={index}>
          <span>
            {item}
            <a
              href="#del"
              onClick={props.onDelete}
              data-index={index}
              data-type={props.locationType}
              className="js-remove-location "
            >
              {' '}
            </a>
          </span>
        </div>
      ))}
    </>
  )
}

export default SelectedItems
