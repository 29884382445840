import React from 'react'
import Breadcrumb from '../../../common/Breadcrumb'
import Sidebar from '../../../common/sidebar/Sidebar'

const FeaturedListingsPage = () => {
  return (
    <>
      <div className={'si-site-container col-md-8 si-site-container--full'}>
        <Breadcrumb />
        <div className={'si-content-area'}>
          <h1>Featured Listings</h1>
          <p>
            Included below are our featured listings, updated daily from the
            MLS. If you would like more information on any of these homes,
            condos or townhomes, you can request more info or schedule a showing
            from the detail page of each listing. We can provide you with
            disclosures, past sales history, dates and prices of properties
            recently sold nearby, and more.
          </p>
          <p>
            And, for your convenience, be sure to{' '}
            <a href="/register/" title="Email Listing Alerts">
              register for a free account
            </a>{' '}
            to automatically receive email listing alerts whenever new homes
            come on the market that match your specific search criteria.
          </p>
        </div>
      </div>
      <Sidebar />
    </>
  )
}

export default FeaturedListingsPage
