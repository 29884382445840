import React from 'react'
import HomeQuickSearch from '../../common/home/Home-quick-search'
import HomeDarkBanner from '../../common/home/Home-dark-banner'
import HomeCommunities from '../../common/home/Home-communities'
import HomeBlog from '../../common/home/Home-blog'
import HomeAbout from '../../common/home/Home-about'

const HomePage = () => {
  return (
    <>
      <HomeQuickSearch />
      <HomeDarkBanner />
      <HomeCommunities />
      <HomeBlog />
      <HomeAbout />
    </>
  )
}

export default HomePage
