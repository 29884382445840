import React from 'react'
import { authInputViewNames } from '../../dictionaries/inputViews'

const styleDisplayNone = { display: 'none' }

function AuthSigninInputView({ closeFunction, changeView }) {
  const openRegisterInputViewButtonHandler = (evt) => {
    evt.preventDefault()
    changeView(authInputViewNames.AuthRegisterInputView)
  }

  const openForgotPasswordInputViewButtonHandler = (evt) => {
    evt.preventDefault()
    changeView(authInputViewNames.AuthForgotPasswordInputView)
  }

  return (
    <div id="authSigninInputView" className="si-auth-modal__form-sm">
      <div className="modal-header">
        <button
          type="button"
          className="close js-auth-close"
          style={{ display: 'block' }}
          onClick={closeFunction}
        >
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </button>
        <h4 className="modal-title" aria-level="3">
          Returning User Login
        </h4>
      </div>

      <div className="modal-info">
        {/*<div className="si-social-login">
          <button
            type="button"
            className="si-btn si-social-login__facebook js-social-login"
            data-type="facebook"
          >
            <i className="icon"></i>
            <span>
              Continue with Facebook<span></span>
            </span>
          </button>
          <button
            type="button"
            className="si-btn si-social-login__googleplus js-social-login"
            data-type="google"
          >
            <i className="icon"></i>
            <span>
              Sign in with Google<span></span>
            </span>
          </button>
          <div
            style={styleDisplayNone}
            className="si-form__error js-social-login-msg"
          ></div>
          <div className="si-social-login__or">
            <span>or</span>
          </div>
  </div> */}
      </div>

      <form
        id="authSigninForm"
        name="authSigninForm"
        className="si-form"
        noValidate="noValidate"
      >
        <div className="modal-body">
          <div
            id="authSigninMessage"
            style={styleDisplayNone}
            className="si-form__error"
          ></div>
          <div className="si-row">
            <div className="form-group si-form__full">
              <label className="control-label" htmlFor="authSigninEmail">
                Email
              </label>
              <input
                onChange={(evt) => console.log(evt.target.value)}
                type="email"
                name="email"
                id="authSigninEmail"
                value=""
                className="form-control"
                placeholder="name@website.com"
              />
            </div>

            <div className="form-group si-form__full">
              <label className="control-label" htmlFor="authSigninPassword">
                Password
              </label>
              <input
                onChange={(evt) => console.log(evt.target.value)}
                type="password"
                id="authSigninPassword"
                className="form-control"
                placeholder="Password"
                required=""
                title="Please enter your password"
                aria-required="true"
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            id="authSigninSubmit"
            className="si-btn si-btn--primary si-auth-modal__form-sm-btn"
          >
            Login
          </button>

          <p className="si-view-link">
            Trouble Logging In?{' '}
            <a
              href="/"
              className="js-forgot-password-link"
              onClick={openForgotPasswordInputViewButtonHandler}
            >
              Reset Password
            </a>
          </p>

          <p className="si-view-link">
            Not a member yet?{' '}
            <a
              href="/"
              className="js-register-link"
              onClick={openRegisterInputViewButtonHandler}
            >
              Register Now
            </a>
          </p>
          {/*<div className="si-captcha-disclaimer">
            This site is protected by reCAPTCHA and the Google{' '}
            <a
              href="https://policies.google.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>{' '}
            and{' '}
            <a
              href="https://policies.google.com/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>{' '}
            apply.
          </div> */}
        </div>
      </form>
    </div>
  )
}

export default AuthSigninInputView
