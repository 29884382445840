import React from 'react'
import PropTypes from 'prop-types'

function PhoneInput({ phone, setPhone, placeholder }) {
  const phoneCreatePattern = () => {
    if (phone === '') {
      return ''
    }
    const leftBrecket = '('
    const firstThreeDigits = phone.slice(0, 3)
    const rigthBracket = phone.length > 3 ? ') ' : ''
    const secondThreeDigits = phone.slice(3, 6)
    const hyphen = phone.length > 6 ? '-' : ''
    const lastFourDigits = phone.slice(6, 10)

    return (
      leftBrecket +
      firstThreeDigits +
      rigthBracket +
      secondThreeDigits +
      hyphen +
      lastFourDigits
    )
  }
  const phoneChangeHandler = (evt) => {
    setPhone(phoneCleanPattern(evt.target.value))
  }
  const phoneCleanPattern = (patternedPhone) => {
    return patternedPhone.replace(/[^+\d]/g, '')
  }
  return (
    <input
      type="tel"
      required
      name="requestCellPhone"
      value={phoneCreatePattern()}
      tabIndex="3"
      className="form-control si-phone-group"
      placeholder={placeholder !== undefined ? placeholder : '(000) 000-0000'}
      maxLength="14"
      onChange={phoneChangeHandler}
    />
  )
}
PhoneInput.propTypes = {
  phone: PropTypes.string.isRequired,
  setPhone: PropTypes.func.isRequired,
}
export default PhoneInput
