import React from 'react'
import PropTypes from 'prop-types'

import { formatMoney } from '../../../../../helpers/text-helpers'

function TopPanel({ property }) {
  return (
    <div
      className="si-ld-top js-top-nav"
      style={{ top: '0px', height: 'auto' }}
    >
      <div className="container-ld">
        <div className="si-ld-top__address">
          <h1>{property.StandardFields.UnparsedFirstLineAddress}</h1>
          <span className="si-ld-top__address-location">
            {property.StandardFields.UnparsedAddress.replace(
              property.StandardFields.UnparsedFirstLineAddress + ',',
              ''
            )}
          </span>
          <a href="/" className="si-ld-top__view-map js-scrollto-link">
            <svg className="icon">
              <use xlinkHref="/assets/listing-detail-icons.svg#icon-marker" />
            </svg>{' '}
            View Map
          </a>
          <span className="si-ld-top__price">
            ${formatMoney(property.StandardFields.CurrentPrice, 0)}
          </span>

          <a
            href="/"
            rel="nofollow"
            className="si-btn si-btn--secondary si-btn--sm"
          >
            Schedule a Tour
          </a>
        </div>
        <div className="si-ld-top__info">
          <div>
            <span>Beds</span>
            <strong>{property.StandardFields.BedsTotal}</strong>
          </div>
          <div>
            <span>Baths</span>
            <strong>{property.StandardFields.BathsTotal}</strong>
          </div>
          <div>
            <span>Sq.Ft.</span>
            <strong>
              {formatMoney(property.StandardFields.BuildingAreaTotal, 0)}
            </strong>
          </div>
          <div>
            <span>Year</span>
            <strong>{property.StandardFields.YearBuilt}</strong>
          </div>
        </div>
        <div className="si-ld-top__buttons js-sticky-buttons-sm">
          <a
            href="/"
            rel="nofollow"
            className="si-btn si-btn--secondary si-btn--sm is-showing"
          >
            Schedule a Tour
          </a>
          {/* <a href="/" rel="nofollow">
            <span>Save</span>
            <i className="fa fa-heart-o"></i>
          </a> */}
          {/* <a href="/" rel="nofollow">
            <span>Share</span>
            <svg className="icon">
              <use xlinkHref="/assets/listing-detail-icons.svg#icon-letter" />
            </svg>
          </a> */}

          {/* <a
            href="/"
            rel="nofollow"
            className="is-share js-social-share"
            title=""
          >
            <span>Social</span>
            <svg className="icon">
              <use xlinkHref="/assets/listing-detail-icons.svg#icon-share" />
            </svg>
          </a> */}
        </div>
      </div>
    </div>
  )
}
TopPanel.propTypes = {
  property: PropTypes.object.isRequired,
}
export default TopPanel
