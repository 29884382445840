export const formatDuration = (seconds) => {
  if (seconds === 0) {
    return 'now'
  }
  const secondsData = {
    amountSeconds: 1,
    one: 'sec',
    many: 'sec',
  }
  const minutesData = {
    amountSeconds: 60,
    one: 'min',
    many: 'min',
  }
  const hoursData = {
    amountSeconds: 3600,
    one: 'hour',
    many: 'hours',
  }
  const daysData = {
    amountSeconds: 3600 * 24,
    one: 'day',
    many: 'days',
  }
  const yearsData = {
    amountSeconds: 3600 * 24 * 365,
    one: 'year',
    many: 'years',
  }

  const combinationElements = [
    yearsData,
    daysData,
    hoursData,
    minutesData,
    secondsData,
  ]

  function reducer(result, data, index) {
    let delimeter =
      result.resultString === ''
        ? ''
        : result.seconds % data.amountSeconds === 0
        ? ' and '
        : ', '
    return result.seconds >= data.amountSeconds
      ? {
          seconds: result.seconds % data.amountSeconds,
          resultString:
            result.resultString +
            delimeter +
            Math.floor(result.seconds / data.amountSeconds) +
            ' ' +
            (Math.floor(result.seconds / data.amountSeconds) > 1
              ? data.many
              : data.one),
        }
      : result
  }

  const combinationResult = combinationElements.reduce(reducer, {
    seconds: seconds,
    resultString: '',
  })

  return combinationResult.resultString
}

export const formatDurationShort = (seconds) => {
  if (seconds === 0) {
    return 'now'
  }
  const secondsData = {
    amountSeconds: 1,
    one: 'sec',
    many: 'seconds',
  }
  const minutesData = {
    amountSeconds: 60,
    one: 'min',
    many: 'min',
  }
  const hoursData = {
    amountSeconds: 3600,
    one: 'hour',
    many: 'hours',
  }
  const daysData = {
    amountSeconds: 3600 * 24,
    one: 'day',
    many: 'days',
  }
  const yearsData = {
    amountSeconds: 3600 * 24 * 365,
    one: 'year',
    many: 'years',
  }

  const combinationElements = [
    yearsData,
    daysData,
    hoursData,
    minutesData,
    secondsData,
  ]

  function reducer(result, data, index) {
    let delimeter =
      result.resultString === ''
        ? ''
        : result.seconds % data.amountSeconds === 0
        ? ' '
        : ' '

    let resultString = result.resultString
    if (!result.finish) {
      resultString =
        result.resultString +
        delimeter +
        Math.floor(result.seconds / data.amountSeconds) +
        ' ' +
        (Math.floor(result.seconds / data.amountSeconds) > 1
          ? data.many
          : data.one)
    }

    return result.seconds >= data.amountSeconds
      ? {
          seconds: result.seconds % data.amountSeconds,
          resultString: resultString,
          finish:
            ((data.one === 'hour' ||
              data.one === 'day' ||
              // data.one === 'min' ||
              data.one === 'year') &&
              result.seconds % data.amountSeconds > 0) ||
            result.finish === true
              ? true
              : false,
        }
      : result
  }

  const combinationResult = combinationElements.reduce(reducer, {
    seconds: seconds,
    resultString: '',
    finish: false,
  })
  return combinationResult.resultString
}