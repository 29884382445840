import React, { useState } from 'react'
import UserLoginRegisterForm from '../forms/login-register/User-login-register-form'

import { authInputViewNames } from '../forms/login-register/dictionaries/inputViews'
function UserRegister() {
  const [isRegisterFormShown, setIsRegisterFormShown] = useState(false)
  const [firstView, setFirstView] = useState(
    authInputViewNames.AuthSigninInputView
  )
  const openRegisterFormButtonHandler = (evt) => {
    evt.preventDefault()
    if (evt.target.id === 'loginButton') {
      setFirstView(authInputViewNames.AuthSigninInputView)
    } else if (evt.target.id === 'registerButton') {
      setFirstView(authInputViewNames.AuthRegisterInputView)
    }
    setIsRegisterFormShown(true)
  }
  return (
    <>
      <a href="/" onClick={openRegisterFormButtonHandler} id="loginButton">
        Login<span className={'tracker-div'}>/</span>
      </a>
      <a href="/" onClick={openRegisterFormButtonHandler} id="registerButton">
        Register
      </a>
      {isRegisterFormShown ? (
        <UserLoginRegisterForm
          closeFunction={() => setIsRegisterFormShown(false)}
          firstView={firstView}
        />
      ) : (
        ''
      )}
    </>
  )
}

export default UserRegister
