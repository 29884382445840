export const viewNames = {
  AuthInputView: 'AuthInputView',
  AuthRegisterSuccessView: 'AuthRegisterSuccessView',
  AuthSigninSuccessView: 'AuthSigninSuccessView',
  AuthDeletedAccountView: 'AuthDeletedAccountView',
  AuthBannedRegistrationView: 'AuthBannedRegistrationView',
}

export const authInputViewNames = {
  AuthRegisterInputView: 'AuthRegisterInputView',
  AuthSigninInputView: 'AuthSigninInputView',
  AuthSocialRegisterInputView: 'AuthSocialRegisterInputView',
  AuthForgotPasswordInputView: 'AuthForgotPasswordInputView',
}
