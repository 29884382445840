import React from 'react'
import './modal-full-screen.css'

function ModalFullScreen(props) {
  return (
    <div id="myNav" className="overlay">
      {props.leftButtonSlot}
      <div className="overlay-content">{props.children}</div>
      {props.rightButtonSlot}
      <button className="closebtn" onClick={props.close}>
        &times;
      </button>
    </div>
  )
}

export default ModalFullScreen
