import React from 'react'
import PropTypes from 'prop-types'

import Breadcrumb from '../../common/Breadcrumb'
import Sidebar from '../../common/sidebar/Sidebar'

//TODO safe dangerouslySetInnerHTML library

const SingleArticle = ({ article }) => {
  function getArticleBodyHtml() {
    return { __html: article.summary }
  }
  function getFormatedDateFull(dateString) {
    const date = new Date(dateString)
    const weekDay = new Intl.DateTimeFormat('en', { weekday: 'long' }).format(
      date
    )
    const longMonth = new Intl.DateTimeFormat('en', { month: 'long' }).format(
      date
    )
    const longYear = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(
      date
    )
    const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date)
    const time = new Intl.DateTimeFormat('en', {
      hour: 'numeric',
      minute: '2-digit',
    }).format(date)
    return `${weekDay}, ${longMonth} ${day}, ${longYear} at ${time}`
  }
  function getFormatedDateSmall(dateString) {
    const date = new Date(dateString)
    const longMonth = new Intl.DateTimeFormat('en', { month: 'long' }).format(
      date
    )
    const longYear = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(
      date
    )
    const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date)
    return `${longMonth} ${day}, ${longYear}`
  }
  //   const articleHref = article.link['@href'].split("/").filter((element=>element!=="")).pop()
  return (
    <>
      <div className={'si-site-container col-md-8 si-site-container--full'}>
        <Breadcrumb />
        <article className="si-blog-entry">
          <div className="si-content-area si-blog-entry__header">
            <h1>{article.title}</h1>
            <div className="si-blog-entry__info">
              <span className="si-blog-entry__info--full">
                Posted by <strong>{article.author.name}</strong> on{' '}
                {getFormatedDateFull(article.published)}
              </span>
              <span className="si-blog-entry__info--small">
                By <strong>{article.author.name}</strong> /{' '}
                {getFormatedDateSmall(article.published)}
              </span>
              {/*<span><i className="fa fa-comment"></i> <a href="#discussion">Comment</a></span>*/}
            </div>
          </div>
          <div
            className="si-content-area clearfix"
            dangerouslySetInnerHTML={getArticleBodyHtml()}
          ></div>
        </article>{' '}
      </div>
      <Sidebar />
    </>
  )
}

SingleArticle.propTypes = {
  article: PropTypes.object,
}

export default SingleArticle
