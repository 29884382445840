import React from 'react'
import { Link } from 'react-router-dom'

const HomeCommunities = () => {
  return (
    <section className="communities link-effect dark">
		<div className="container-fluid">
			<div className="row">
				<div className="col-lg-4 col-md-3 hidden-sm hidden-xs img">
				</div>
				<div className="col-lg-8 col-md-9 items">
					<h2>Explore Communities and Neighborhoods</h2>
          
					<ul>
          <li><Link to="/communities/apple-valley/" title="Apple Valley">Apple Valley</Link></li><li><Link to="/communities/bloomington/" title="Bloomington">Bloomington</Link></li><li><Link to="/communities/bloomington-hills/" title="Bloomington Hills">Bloomington Hills</Link></li><li><Link to="/communities/cedar-city/" title="Cedar City">Cedar City</Link></li><li><Link to="/communities/coral-canyon/" title="Coral Canyon">Coral Canyon</Link></li><li><Link to="/communities/dammeron-valley/" title="Dammeron Valley">Dammeron Valley</Link></li><li><Link to="/communities/dixie-springs/" title="Dixie Springs">Dixie Springs</Link></li><li><Link to="/communities/enterprise/" title="Enterprise">Enterprise</Link></li><li><Link to="/communities/entrada/" title="Entrada">Entrada</Link></li><li><Link to="/communities/hurricane/" title="Hurricane">Hurricane</Link></li><li><Link to="/communities/ivins/" title="Ivins">Ivins</Link></li><li><Link to="/communities/la-verkin/" title="La Verkin">La Verkin</Link></li><li><Link to="/communities/leeds/" title="Leeds">Leeds</Link></li><li><Link to="/communities/little-valley/" title="Little Valley">Little Valley</Link></li><li><Link to="/communities/santa-clara/" title="Santa Clara">Santa Clara</Link></li><li><Link to="/communities/springdale/" title="Springdale">Springdale</Link></li><li><Link to="/communities/st-george/" title="St George">St George</Link></li><li><Link to="/communities/sun-river/" title="Sun River">Sun River</Link></li><li><Link to="/communities/the-ledges/" title="The Ledges">The Ledges</Link></li><li><Link to="/communities/virgin/" title="Virgin">Virgin</Link></li><li><Link to="/communities/washington/" title="Washington">Washington</Link></li><li><Link to="/communities/winchester-hills/" title="Winchester Hills">Winchester Hills</Link></li>
						<li><Link className="view-more" to="/communities/">View More <i className="fa fa-angle-right"></i></Link></li>
					</ul>
          
				</div>
			</div>
		</div>
	</section>
  )
}

export default HomeCommunities
