import React from 'react'
import PropTypes from 'prop-types'

import InputCustomSelect from '../inputs/Input-custom-select'

import {
  priceReductionSelectData,
  priceReductionDateSelectData,
  sortResultsBySelectData,
  daysOnSiteSelectData,
} from './ext-conditions/ext-conditions-select-data'

const ExtConditions = (props) => {
  return (
    <section className="si-sf-section">
      <div className="si-sf-filter si-row js-filter-criteria">
        <div className="si-sf-filter__item">
          <h2>Price Reduction (%)</h2>
          <InputCustomSelect
            value={props.priceReduction}
            onChange={props.setPriceReduction}
            selectData={priceReductionSelectData}
          />
        </div>

        <div className="si-sf-filter__item">
          <h2>Price Reduction Date</h2>

          <InputCustomSelect
            value={props.priceReductionDate}
            onChange={props.setPriceReductionDate}
            selectData={priceReductionDateSelectData}
          />
        </div>

        <div className="si-sf-filter__item">
          <h2>Sort Results By</h2>
          <InputCustomSelect
            value={props.sortResultsBy}
            onChange={props.setSortResultsBy}
            selectData={sortResultsBySelectData}
          />
        </div>

        <div className="si-sf-filter__item">
          <h2>Days on Site</h2>
          <InputCustomSelect
            value={props.daysOnSite}
            onChange={props.setDaysOnSite}
            selectData={daysOnSiteSelectData}
          />
        </div>
      </div>
    </section>
  )
}

ExtConditions.propTypes = {
  priceReduction: PropTypes.string,
  setPriceReduction: PropTypes.func,

  priceReductionDate: PropTypes.string,
  setPriceReductionDate: PropTypes.func,

  sortResultsBy: PropTypes.string,
  setSortResultsBy: PropTypes.func,

  daysOnSite: PropTypes.string,
  setDaysOnSite: PropTypes.func,
}

export default ExtConditions
