import React from 'react'
import Breadcrumb from '../../../common/Breadcrumb'
import Sidebar from '../../../common/sidebar/Sidebar'

const ReadyToBuyPage = () => {
  return (
    <>
      <div className={'si-site-container col-md-8 si-site-container--full'}>
        <Breadcrumb />
        <div className={'si-content-area'}>
          <p>
            <iframe
              src="https://docs.google.com/a/mystg.com/forms/d/1VtvwGHBTPr6pmF5QFTWxesPdVSbqz5352aAUaTs5ObQ/viewform"
              style={{ width: '100%', border: 'none' }}
              width="300"
              height="1600"
              scrolling="no"
              frameBorder="0"
              title="google form"
            ></iframe>
          </p>
          <div
            id="wuf-adv"
            style={{
              fontFamily: 'inherit',
              fontSize: 'small',
              color: ' #a7a7a7',
              textAlign: 'center',
              display: 'block',
            }}
          ></div>
        </div>
      </div>
      <Sidebar />
    </>
  )
}

export default ReadyToBuyPage
