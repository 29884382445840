import React from 'react'
import Breadcrumb from '../../../common/Breadcrumb'
import Sidebar from '../../../common/sidebar/Sidebar'

const WhatAreClosingCostsPage = () => {
  return (
    <>
      <div className={'si-site-container col-md-8 si-site-container--full'}>
        <Breadcrumb />
        <div className={'si-content-area'}>
          <h1>What Are Closing Costs?</h1>
          <p>
            You've found your dream home, the seller has accepted your offer,
            your loan has been approved and you're eager to move into your new
            home. But before you get the key, there's one more step--the
            closing.
          </p>
          <p>
            Also called the settlement, the closing is the process of passing
            ownership of property from seller to buyer. And it can be
            bewildering. As a buyer, you will sign what seems like endless piles
            of documents and will have to present a sizeable check for the down
            payment and various closing costs. It's the fees associated with the
            closing that many times remains a mystery to many buyers who may
            simply hand over thousands of dollars without really knowing what
            they are paying for.
          </p>
          <p>
            As a responsible buyer, you should be familiar with these costs that
            are both mortgage-related and government imposed. Although many of
            the fees may vary by locality, here are some common fees:
          </p>
          <p>
            <strong>Appraisal Fee:</strong> This fee pays for the appraisal of
            the property. You may already have paid this fee at the beginning of
            your loan application process.
          </p>
          <p>
            <strong>Credit Report Fee:</strong> This fee covers the cost of the
            credit report requested by the lender. This too may already have
            been paid when you applied for your loan.
          </p>
          <p>
            <strong>Loan Origination Fee:</strong> This fee covers the lender's
            loan-processing costs. The fee is typically one percent of the total
            mortgage.
          </p>
          <p>
            <strong>Loan Discount:</strong> You will pay this one-time charge if
            you have chosen to pay points to lower your interest rate. Each
            point you purchase equals one percent of the total loan.
          </p>
          <p>
            <strong>Title Insurance Fees:</strong> These fees generally include
            costs for the title search, title examination, title insurance,
            document preparation and other miscellaneous title fees.
          </p>
          <p>
            <strong>PMI Premium:</strong> If you buy a home with a low down
            payment, a lender usually requires that you pay a fee for mortgage
            insurance. This fee protects the lender against loss due to
            foreclosure. Once a new owner has 20 percent equity in their home,
            however, he or she can normally apply to eliminate this insurance.
          </p>
          <p>
            <strong>Prepaid Interest Fee:</strong> This fee covers the interest
            payment from the date you purchases the home to the date of your
            first mortgage payment. Generally, if you buy a home early in the
            month, the prepaid interest fee will be substantially higher than if
            you buy it towards the end of the month.
          </p>
          <p>
            <strong>Escrow Accounts:</strong> In locations where escrow accounts
            are common, a mortgage lender will usually start an account that
            holds funds for future annual property taxes and home insurance. At
            least one year advance plus two months worth of homeowner's
            insurance premium will be collected. In addition, taxes equal
            approximately to two months in excess of the number of months that
            have elapsed in the year are paid at closing. (If six months have
            passed, eight months of taxes will be collected.)
          </p>
          <p>
            <strong>Recording Fees and transfer taxes:</strong> This expense is
            charged by most states for recording the purchase documents and
            transferring ownership of the property.
          </p>
          <p>
            Make sure you consult a real estate professional in your area to
            find out which fees--and how much--you will be expected to pay
            during the closing of you prospective home. Keep in mind that you
            can negotiate these costs with the seller during the offering stage.
            In some instances, the seller might even agree to pay all of the
            settlement costs.
          </p>
        </div>
      </div>
      <Sidebar />{' '}
    </>
  )
}

export default WhatAreClosingCostsPage
