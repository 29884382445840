import React from 'react'

import FormWrapper from './Form-wrapper'

function SheduleATour({ closeFunction }) {
  return (
    <FormWrapper
      closeFunction={closeFunction}
      formTitle={'Schedule a Tour'}
      info={'Complete the form below to submit a tour request for this listing'}
      buttonName={'Request Showing'}
    />
  )
}

export default SheduleATour
