export const formatMoney = (
  summ,
  digsAfterDot,
  decimalDotChar,
  thousandComma
) => {
  //n - summ, c - digs after dot, d - decimal dot, t - thousand comma
  const digsAfterDotResult = isNaN((digsAfterDot = Math.abs(digsAfterDot)))
    ? 2
    : digsAfterDot
  const decimalDotCharResult =
    decimalDotChar === undefined ? '.' : decimalDotChar
  const thousandCommaResult = thousandComma === undefined ? ',' : thousandComma
  let s = summ < 0 ? '-' : ''
  const i = String(
    parseInt((summ = Math.abs(Number(summ) || 0).toFixed(digsAfterDotResult)))
  )
  let j = i.length
  j = j > 3 ? j % 3 : 0

  return (
    s +
    (j ? i.substr(0, j) + thousandCommaResult : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousandCommaResult) +
    (digsAfterDotResult
      ? decimalDotCharResult +
        Math.abs(summ - i)
          .toFixed(digsAfterDotResult)
          .slice(2)
      : '')
  )
}

export const capitlizeFirstChar = (text) => {
  return text
    ? text
        .toLowerCase()
        .split(' ')
        .map(
          (word) => word.charAt(0).toUpperCase() + [...word.slice(1)].join('')
        )
        .join(' ')
    : ''
}

export const shortText = (text, maxLength) => {
  return text? text.split(' ').reduce((acum,elem)=>acum.length + 1 + elem.length <= maxLength ? acum + " " + elem: acum, '') + ' ...' : ''
}

export const getAlias=(string) => {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return string.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word characters
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}
