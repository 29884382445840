import React from 'react'
import PropTypes from 'prop-types'

import MinMaxBlock from './Min-max-block'

import {
  minSquareFeedSelectData,
  maxSquareFeedSelectData,
} from '../inputs/select-data'

function SquareFeet(props) {
  return (
    <MinMaxBlock
      name={'Square Feet'}
      min={props.min}
      setMin={props.setMin}
      minSelectData={minSquareFeedSelectData}
      max={props.max}
      setMax={props.setMax}
      maxSelectData={maxSquareFeedSelectData}
    />
  )
}

SquareFeet.propTypes = {
  min: PropTypes.string,
  setMin: PropTypes.func,
  max: PropTypes.string,
  setMax: PropTypes.func,
}

export default SquareFeet
