import React from 'react'
import Breadcrumb from '../../../common/Breadcrumb'
import Sidebar from '../../../common/sidebar/Sidebar'

const upperCaseStyle = {
  textTransform: 'uppercase',
}

const TermsOfServicePage = () => {
  return (
    <>
      <div className={'si-site-container col-md-8 si-site-container--full'}>
        <Breadcrumb />
        <div className={'si-content-area'}>
          <h1>Terms of Service</h1>
          <p>
            PLEASE READ! <span style={upperCaseStyle}>GiddyDigs.com</span>{' '}
            REQUIRES CONSIDERATION FOR AND AS A CONDITION OF ALLOWING YOU
            ACCESS.
          </p>
          <p>
            READING AND ACCEPTING THE TERMS OF USE AND READING AND ACCEPTING THE
            PROVISIONS OF THE PRIVACY POLICY OF{' '}
            <span style={upperCaseStyle}>GiddyDigs.com</span> ARE REQUIRED
            CONSIDERATIONS FOR <span style={upperCaseStyle}>GiddyDigs.com</span>{' '}
            GRANTING YOU THE RIGHT TO VISIT, READ OR INTERACT WITH IT.
          </p>
          <p>
            ALL PERSONS ARE DENIED ACCESS TO THIS SITE UNLESS THEY READ AND
            ACCEPT THE TERMS OF USE AND THE PRIVACY POLICY.
          </p>
          <p>
            BY VIEWING, VISITING, USING, OR INTERACTING WITH{' '}
            <span style={upperCaseStyle}>GiddyDigs.com</span> OR WITH ANY
            BANNER, POP-UP, OR ADVERTISING THAT APPEARS ON IT, YOU ARE AGREEING
            TO ALL THE PROVISIONS OF THIS TERMS OF USE POLICY AND THE PRIVACY
            POLICY OF <span style={upperCaseStyle}>GiddyDigs.com</span>.
          </p>
          <p>
            ALL PERSONS UNDER THE AGE OF 18 ARE DENIED ACCESS TO{' '}
            <span style={upperCaseStyle}>GiddyDigs.com</span>. IF YOU ARE UNDER
            18 YEARS OF AGE, IT IS UNLAWFUL FOR YOU TO VISIT, READ, OR INTERACT
            WITH <span style={upperCaseStyle}>GiddyDigs.com</span> OR ITS
            CONTENTS IN ANY MANNER.{' '}
            <span style={upperCaseStyle}>GiddyDigs.com</span> SPECIFICALLY
            DENIES ACCESS TO ANY INDIVIDUAL THAT IS COVERED BY THE CHILD ONLINE
            PRIVACY ACT (COPA) OF 1998.
          </p>
          <p>
            <span style={upperCaseStyle}>GiddyDigs.com</span> RESERVES THE RIGHT
            TO DENY ACCESS TO ANY PERSON OR VIEWER FOR ANY REASON. UNDER THE
            TERMS OF THE PRIVACY POLICY, WHICH YOU ACCEPT AS A CONDITION FOR
            VIEWING, <span style={upperCaseStyle}>GiddyDigs.com</span> IS
            ALLOWED TO COLLECT AND STORE DATA AND INFORMATION FOR THE PURPOSE OF
            EXCLUSION AND FOR MANY OTHER USES.
          </p>
          <p>
            THE TERMS OF USE AGREEMENT MAY CHANGE FROM TIME TO TIME. VISITORS
            HAVE AN AFFIRMATIVE DUTY, AS PART OF THE CONSIDERATION FOR
            PERMISSION TO VIEW <span style={upperCaseStyle}>GiddyDigs.com</span>
            , TO KEEP THEMSELVES INFORMED OF CHANGES.
          </p>
          <h2>PARTIES TO THE TERMS OF USE AGREEMENT</h2>
          <p>
            Visitors, viewers, users, subscribers, members, affiliates, or
            customers, collectively referred to herein as "Visitors," are
            parties to this agreement. The website and its owners and/or
            operators are parties to this agreement, herein referred to as
            "Website."
          </p>
          <h2>USE OF INFORMATION FROM THIS WEBSITE</h2>
          <p>
            Unless you have entered into an express written contract with this
            website to the contrary, visitors, viewers, subscribers, members,
            affiliates, or customers have no right to use this information in a
            commercial or public setting; they have no right to broadcast it,
            copy it, save it, print it, sell it, or publish any portions of the
            content of this website. By viewing the contents of this website you
            agree to this condition of viewing and you acknowledge that any
            unauthorized use is unlawful and may subject you to civil or
            criminal penalties. Again, Visitor has no rights whatsoever to use
            the content of, or portions thereof, including its databases,
            invisible pages, linked pages, underlying code, or other
            intellectual property the site may contain, for any reason for any
            use whatsoever. Nothing. Visitor agrees to liquidated damages in the
            amount of U.S. $100,000 in addition to costs and actual damages for
            breach of this provision. Visitor warrants that he or she
            understands that accepting this provision is a condition of viewing
            and that viewing constitutes acceptance.
          </p>
          <h2>
            OWNERSHIP OF WEBSITE OR RIGHT TO USE, SELL, PUBLISH CONTENTS OF THIS
            WEBSITE
          </h2>
          <p>
            The website and its contents are owned or licensed by the website.
            Material contained on the website must be presumed to be proprietary
            and copyrighted. Visitors have no rights whatsoever in the site
            content. Use of website content for any reason is unlawful unless it
            is done with express contract or permission of the website.
          </p>
          <h2>
            HYPERLINKING TO SITE, CO-BRANDING, "FRAMING" AND REFERENCING SITE
            PROHIBITED
          </h2>
          <p>
            Unless expressly authorized by website, no one may hyperlink this
            site, or portions thereof, (including, but not limited to,
            logotypes, trademarks, branding or copyrighted material) to theirs
            for any reason. Further, you are not allowed to reference the url
            (website address) of this website in any commercial or
            non-commercial media without express permission, nor are you allowed
            to'frame' the site. You specifically agree to cooperate with the
            Website to remove or de-activate any such activities and be liable
            for all damages. You hereby agree to liquidated damages of US
            $100,000.00 plus costs and actual damages for violating this
            provision.
          </p>
          <h2>DISCLAIMER FOR CONTENTS OF SITE</h2>
          <p>
            The website disclaims any responsibility for the accuracy of the
            content of this website. Visitors assume all risk of viewing,
            reading, using, or relying upon this information. Unless you have
            otherwise formed an express contract to the contrary with the
            website, you have no right to rely on any information contained
            herein as accurate. The website makes no such warranty.
          </p>
          <h2>
            DISCLAIMER FOR HARM CAUSED TO YOUR COMPUTER OR SOFTWARE FROM
            INTERACTING WITH THIS WEBSITE OR ITS CONTENTS. VISITOR ASSUMES ALL
            RISK OF VIRUSES, WORMS, OR OTHER CORRUPTING FACTORS.
          </h2>
          <p>
            The website assumes no responsibility for damage to computers or
            software of the visitor or any person the visitor subsequently
            communicates with from corrupting code or data that is inadvertently
            passed to the visitor's computer. Again, visitor views and interacts
            with this site, or banners or pop-ups or advertising displayed
            thereon, at his own risk.
          </p>
          <h2>DISCLAIMER FOR HARM CAUSED BY DOWNLOADS</h2>
          <p>
            Visitor downloads information from this site at their own risk. This
            website makes no warranty that downloads are free of corrupting
            computer codes, including, but not limited to, viruses and worms.
          </p>
          <h2>LIMITATION OF LIABILITY</h2>
          <p>
            By viewing, using, or interacting in any manner with this site,
            including banners, advertising, or pop-ups, downloads, and as a
            condition of the website to allow his lawful viewing, Visitor
            forever waives all right to claims of damage of any and all
            description based on any causal factor resulting in any possible
            harm, no matter how heinous or extensive, whether physical or
            emotional, foreseeable or unforeseeable, whether personal or
            business in nature.
          </p>
          <h2>INDEMNIFICATION</h2>
          <p>
            Visitor agrees that in the event he causes damage, which the Website
            is required to pay for, the Visitor, as a condition of viewing,
            promises to reimburse the Website for all.
          </p>
          <h2>SUBMISSIONS</h2>
          <p>
            Visitor agrees as a condition of viewing, that any communication
            between Visitor and Website is deemed a submission. All submissions,
            including portions thereof, graphics contained thereon, or any of
            the content of the submission, shall become the exclusive property
            of the Website and may be used, without further permission, for
            commercial use without additional consideration of any kind. Visitor
            agrees to only communicate that information to the Website, which it
            wishes to forever allow the Website to use in any manner as it sees
            fit. "Submissions" is also a provision of the Privacy Policy.
          </p>
          <h2>NOTICE</h2>
          <p>
            No additional notice of any kind for any reason is due Visitor and
            Visitor expressly warrants an understanding that the right to notice
            is waived as a condition for permission to view or interact with the
            website.
          </p>
          <h2>DISPUTES</h2>
          <p>
            As part of the consideration that the Website requires for viewing,
            using or interacting with this website, Visitor agrees to use
            binding arbitration for any claim, dispute, or controversy ("CLAIM")
            of any kind (whether in contract, tort or otherwise) arising out of
            or relating to this purchase, this product, including solicitation
            issues, privacy issues, and terms of use issues.
          </p>
          <p>
            Arbitration shall be conducted pursuant to the rules of the American
            Arbitration Association which are in effect on the date a dispute is
            submitted to the American Arbitration Association. Information about
            the American Arbitration Association, its rules, and its forms are
            available from the American Arbitration Association, 335 Madison
            Avenue, Floor 10, New York, New York, 10017-4605. Hearing will take
            place in the city or county of the Seller.
          </p>
          <p>
            In no case shall the viewer, visitor, member, subscriber or customer
            have the right to go to court or have a jury trial. Viewer, visitor,
            member, subscriber or customer will not have the right to engage in
            pre-trial discovery except as provided in the rules; you will not
            have the right to participate as a representative or member of any
            class of claimants pertaining to any claim subject to arbitration;
            the arbitrator's decision will be final and binding with limited
            rights of appeal.
          </p>
          <p>
            The prevailing party shall be reimbursed by the other party for any
            and all costs associated with the dispute arbitration, including
            attorney fees, collection fees, investigation fees, travel expenses.
          </p>
          <h2>JURISDICTION AND VENUE</h2>
          <p>
            If any matter concerning this purchase shall be brought before a
            court of law, pre- or post-arbitration, Viewer, visitor, member,
            subscriber or customer agrees to that the sole and proper
            jurisdiction to be the state and city declared in the contact
            information of the web owner unless otherwise here specified. In the
            event that litigation is in a federal court, the proper court shall
            be the closest federal court to the Seller's address.
          </p>
          <h2>APPLICABLE LAW</h2>
          <p>
            Viewer, visitor, member, subscriber or customer agrees that the
            applicable law to be applied shall, in all cases, be that of the
            state of the Seller.
          </p>
          <h2>CONTACT INFORMATION</h2>
          <p>The Seller of this product is:</p>
          <p>Mailing address:</p>
          <p>
            Giddy Digs
            <br />
            1031 S. Bluff Street
            <br />
            St. George UT 84770
            <br /> Contact Email:{' '}
            <a href="mailto:joeneuman@gmail.com">joeneuman@gmail.com</a> All
            Rights Reserved.
          </p>
        </div>
      </div>
      <Sidebar />
    </>
  )
}

export default TermsOfServicePage
