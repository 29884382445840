import React from 'react'
import Breadcrumb from '../../../common/Breadcrumb'
import Sidebar from '../../../common/sidebar/Sidebar'

const AboutPage = () => {
  return (
    <>
      <div className={'si-site-container col-md-8 si-site-container--full'}>
        <Breadcrumb />
        <div className={'si-content-area'}>
          <h1>
            <span style={{ fontSize: '21px' }}>
              <strong style={{ fontSize: '17px' }}>
                What does it mean to Get Giddy?&nbsp;
              </strong>
            </span>
          </h1>
          <p>
            We want you to #getGiddy about real estate. Buying or selling
            property should be a positive experience and not one that adds
            unnecessary stress and financial strain to your life. At Giddy Digs,
            we seek to educate and empower our clients with the promise that
            every interaction will be guided by our core values: Get Real. Get
            Centered. Get Connected. Get Out There.&nbsp; &nbsp;
          </p>
          <p>
            <img
              src="https://assets.site-static.com/userFiles/984/image/loreleineuman/getreal4.jpg"
              width="4000"
              height="2667"
              alt=""
            />
          </p>
          <p>
            <strong>#getReal&nbsp;</strong>
          </p>
          <p>
            “Getting Real” begins with helping you set financial and time
            expectations for the sale/purchase of your home. It is also about
            taking a good hard look at what it takes to get the home of your
            dreams, invest in real estate, or sell your property for top
            dollar.&nbsp;
          </p>
          <p dir="ltr">
            <span>
              We commit to #getReal and provide honest advice and timely
              communication throughout the process. As we #getReal with
              ourselves and seek to be a little better each day and seek to
              achieve our personal and business goals, we know that our
              commitment and passion will shine through.&nbsp;
            </span>
          </p>
          <p dir="ltr">
            <span>
              <img
                src="https://assets.site-static.com/userFiles/984/image/loreleineuman/Get_Centered_7.jpg"
                width="4000"
                height="2667"
                alt=""
              />
            </span>
          </p>
          <p>
            <span style={{ fontSize: '21px' }}>
              <strong>#getCentered</strong>
            </span>
          </p>
          <div className={'_2cuy _3dgx _2vxa'}>
            <span className={'_4yxo'}>
              Your home is your happy place. At Giddy Digs, we understand that
              buying or selling a home is more than a financial decision, it’s
              an emotional process. Whether it’s buying your first home or
              selling your childhood home, we recognize that a house is more
              than a number; it is home.
            </span>
          </div>
          <div className={'_2cuy _3dgx _2vxa'}>
            <span className={'_4yxo'}></span>
          </div>
          <div className="_2cuy _3dgx _2vxa">
            <span className={'_4yxo'}> </span>
          </div>
          <div className={'_2cuy _3dgx _2vxa'}>
            <span className={'_4yxo'}>
              Your home is the center of your life. A good home isn’t about
              “perfection”, it’s filled with things and people we love, the
              center of family gatherings, and joy-filled moments of laughter.
              Home is your safe place, where you can kick off your shoes, shed
              the worries of the day, relax into calm and stillness and just be
              you.
            </span>
          </div>
          <div className={'_2cuy _3dgx _2vxa'}>
            <span className={'_4yxo'}></span>
          </div>
          <div className={'_2cuy _3dgx _2vxa'}>
            <span className={'_4yxo'}> </span>
          </div>
          <div className="_2cuy _3dgx _2vxa">
            <span className={'_4yxo'}>
              At Giddy Digs, we strive to cultivate the best “home-like”
              qualities in our agents and our business: self-compassion,
              resilience, gratitude, joy, intuition, trusting faith, stillness,
              and laughter. We believe that as we focus and strive to develop
              qualities that matter most, we will be better able to help our
              clients{' '}
            </span>
            <a
              className="_58cn"
              href="https://business.facebook.com/hashtag/getcentered?source=note&amp;epa=HASHTAG"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className={'_4yxo'}>#getCentered</span>
            </a>
            <span className={'_4yxo'}>
              {' '}
              on what matters most to you and help you find your center.{' '}
            </span>
          </div>
          <p>
            &nbsp; &nbsp;
            <img
              src="https://assets.site-static.com/userFiles/984/image/loreleineuman/getconnected6.jpg"
              width="4000"
              height="2667"
              alt=""
            />
          </p>
          <p>
            <span style={{ fontSize: '21px' }}>
              <strong>#getConnected</strong>
            </span>
          </p>
          <p>
            Connection and meaningful relationships matter. That’s why at Giddy
            Digs we are committed to establishing meaningful long term
            relationships with our clients, not just processing
            transactions.&nbsp; We also recognize that feeling connected to a
            new community is essential to new buyers and our commitment is to
            help every client #getConnected in meaningful ways.
          </p>
          <p>
            For Giddy Digs' agents and staff, we are committed to #getConnected
            through community service, business engagement, and professional
            development.&nbsp;
          </p>
          <p>
            <span style={{ fontSize: '21px' }}>
              <strong>
                <img
                  src="https://assets.site-static.com/userFiles/984/image/loreleineuman/getoutthere5.jpg"
                  width="4000"
                  height="2667"
                  alt=""
                />
              </strong>
            </span>
          </p>
          <p>
            <span style={{ fontSize: '21px' }}>
              <strong>#getOutThere</strong>
            </span>
          </p>
          <p>
            At Giddy Digs, we work hard and we rest hard. That means that
            whether we are listing your property or helping you buy a new one,
            we will get out there and get it done.&nbsp; Our unique 4-step
            listing presentation process and in-depth pricing analysis ensure
            that your property will be staged, priced, and marketed to attract
            buyers right from the get-go. If you're buying a property, whether a
            home or as an investment, our agents excel at communicating and
            listening to what your wants and needs are and finding and showing
            you only properties that fit <em>your </em>
            needs. In short, we listen and then we hustle to get it done.&nbsp;
            &nbsp;
          </p>
          <p>
            When the workday is done, we #getOutTher by renewing ourselves
            through creativity, play, and rest. We know that it's only by living
            a balanced life can we be our best selves and the best agents for
            you.
          </p>
        </div>
      </div>
      <Sidebar />
    </>
  )
}

export default AboutPage
