import React from 'react'
import Breadcrumb from '../../../common/Breadcrumb'
import Sidebar from '../../../common/sidebar/Sidebar'

const buttonStyle = {
  backgroundColor: '#1c3144',
  border: '2px solid #0a3c59',
  color: 'white',
  padding: '10px',
  textAlign: 'center',
  textDecoration: 'none',
  display: 'inline-block',
  fontSize: '26px',
  margin: '2px 2px',
  cursor: 'pointer',
  borderRadius: '6px',
}

const WhatItTakesPage = () => {
  return (
    <>
      <div className={'si-site-container col-md-8 si-site-container--full'}>
        <Breadcrumb />
        <div className={'si-content-area'}>
          <p style={{ textAlign: 'right', margin: '10%' }}>
            <a
              href="/about/real-estate-agent/"
              className={'button'}
              style={buttonStyle}
            >
              I’m a Real Estate Agent
            </a>{' '}
            <a
              href="/about/get-licensed/"
              className={'button'}
              style={buttonStyle}
            >
              How do I get Licensed?
            </a>
          </p>
        </div>
      </div>
      <Sidebar />
    </>
  )
}

export default WhatItTakesPage
