import React, { useState } from 'react'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import HomePage from './components/content/pages/Home'
import ResultsPage from './components/content/pages/property-search/Results-page'
import DetailsPage from './components/content/pages/property-search/Details-page'

// import './App.css'

import { Provider } from 'react-redux'

import Header from './components/layout/Header'
import Main from './components/layout/Main'
import Footer from './components/layout/Footer'

import store from './store/redux.js'

function findGetParameter(parameterName) {
  var result = null,
    tmp = []
  var items = window.location.search.substr(1).split('&')
  for (var index = 0; index < items.length; index++) {
    tmp = items[index].split('=')
    if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1])
  }
  return result
}

function App() {
  const sec_word = 'giddydigssuperstrongpassword'
  const [isClosedSite] = useState(findGetParameter('password') !== sec_word)
  return (
    <>
      {isClosedSite ? (
        'private use only'
      ) : (
          <Router>
            {isClosedSite}
            <Provider store={store}>
              <Header />
              <Switch>
                <Route exact path="/">
                  <HomePage />
                </Route>
                <Route exact path="/property-search/results/">
                  <ResultsPage />
                </Route>
                <Route
                  exact
                  path="/property-search/details/:propertyId/:addressAlias"
                >
                  <DetailsPage />
                </Route>
                <Route path="/">
                  <Main />
                </Route>
              </Switch>
              <Footer />
            </Provider>
          </Router>
        )}
    </>
  )
}

export default App
