import React from 'react'
import PropTypes from 'prop-types'

import InputCustomSelect from '../inputs/Input-custom-select'

import { roomsSelectData } from '../inputs/select-data'

function Bathrooms(props) {
  return (
    <div className="si-sf-primary__item">
      <h2>Bathrooms</h2>
      <InputCustomSelect
        value={props.value}
        onChange={props.setValue}
        selectData={roomsSelectData}
      />
    </div>
  )
}

Bathrooms.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
}

export default Bathrooms
