import React from 'react'

import { isEmailValid } from '../../../../helpers/email-validator'

function EmailInput({ email, setEmail }) {
  const emailChangeHandler = (evt) => {
    if (isEmailValid(evt.target.value)) {
      evt.target.setCustomValidity('')
    } else {
      evt.target.setCustomValidity('I am expecting an e-mail address!')
    }

    setEmail(evt.target.value)
  }
  return (
    <input
      type="email"
      required
      name="requestEmail"
      tabIndex="2"
      value={email}
      className="form-control"
      placeholder="E-mail: name@website.com"
      onChange={emailChangeHandler}
    />
  )
}

export default EmailInput
