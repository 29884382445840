import React from 'react'
import MortgageCalculator from '../property-search/details/blocks/Mortgage-calculator'

const MortgageCalculatorLite = () => {
  //inputs

  return (
    <div>
      <MortgageCalculator price={100000} />
    </div>
  )
}

export default MortgageCalculatorLite
