import React from 'react'
import Breadcrumb from '../../../common/Breadcrumb'
import Sidebar from '../../../common/sidebar/Sidebar'

const pStyles = {
  margin: '12px 20px',
  padding: '0px',
}

const EscrowNowWhatPage = () => {
  return (
    <>
      <div className={'si-site-container col-md-8 si-site-container--full'}>
        <Breadcrumb />
        <div className={'si-content-area'}>
          <h1>Escrow: Now What?</h1>
          <p>
            Congratulations, you are on your way to owning your very own home!
            Follow these suggestions (and your Realtors's advice) so that escrow
            and settlement with go as smooth as possible.
          </p>
          <p>
            You will be asked for a down payment on the home you are purchasing.
            You can choose to put down as much or as little as you want
            (depending on your mortgage), but remember, the more you put down
            toward the total price of your home, the less time it will take you
            to pay off and the less your mortgage payments will be every month.
          </p>
          <p>
            During this period of purchasing your home, you are going to need an
            escrow or settlement company to act as an independent third party so
            that you know when and who to give your money to get the deed to
            your new home. The escrow or settlement company will hold your
            deposit and coordinate much of the activity that goes on during the
            escrow period. This deposit check may also be held by an attorney or
            in the broker's trust account. Make sure that there are sufficient
            funds in your account to cover this check.
          </p>
          <p>
            The deposit check will be cashed. Assuming the sale goes through,
            this money will be applied to the purchase price of the home. If for
            any reason the sale is not consummated, you may be entitled to
            receive all of your deposit back, less standard cancellation fees.
            In certain instances, the seller may be able to retain this money as
            liquidated damages. Prior to executing a purchase contract, it would
            be wise to speak with your counsel regarding whether or not it is
            your best interest to have a liquidated damages clause as part of
            the contract.
          </p>
          <div style={pStyles}>
            <strong>
              1. The period that you are "in escrow" is often 30 days, but may
              be longer or shorter.
            </strong>{' '}
            During this time, each item specified in the contract must be
            completed satisfactorily. By the time you have opened escrow, you
            have come to an agreement with the seller on the closing date and
            the contingencies. Each contract is different, but most include the
            following: 1. Inspection contingency: this should be completed as
            soon as possible after the contract to purchase is signed as
            unsatisfactory results of the inspection may mean that you will want
            to cancel the contract.
          </div>
          <div style={pStyles}>
            <strong>2. Financing contingency:</strong> Once the contract is
            signed, you have a period of time to secure funding. If, for any
            reason, you are unable to secure funding during the period of time
            granted to you by the contract (and the seller will not provide a
            written extension of time), you must decide whether you want to
            remove the contingency and take your chances on getting a loan. You
            may choose to cancel the purchase contract.
          </div>
          <div style={pStyles}>
            <strong>
              3. A requirement that the seller must provide marketable
              title.&nbsp;
            </strong>
            With an attorney or title officer, review the title report. The
            title must be "clear" to ensure that you do not have legal issues
            regarding your ownership. Check into local and state ordinances
            regarding property transfer and make sure that you and/or the seller
            have complied with them.
          </div>
          <div style={pStyles}>
            <strong>4.</strong> <strong>Secure homeowner's insurance.</strong>{' '}
            This will probably be required before you can close the sale. Due to
            such requirements as special fire and earthquake insurance,
            obtaining this insurance may require a lengthy period of time. It
            would be in your best interest to apply for insurance as soon as
            possible after the contract is signed.
          </div>
          <div style={pStyles}>
            <strong>5. Contact local utility companies</strong> to schedule to
            have service turned on when you close escrow.
          </div>
          <div style={pStyles}>
            <strong>6. Schedule the final walk-through inspection.</strong> At
            this time, you should make sure that the property is exactly as the
            contract says it should be. What you thought to be a "permanently
            attached" chandelier that would come with the property might have
            been removed by the seller and replaced with a different fixture
            entirely.
          </div>
          <p>
            You've made it! Once the sale has closed, you're the proud owner of
            a new home. Congratulations!
          </p>
        </div>
      </div>
      <Sidebar />
    </>
  )
}

export default EscrowNowWhatPage
