import React from 'react'

function AuthBannedRegistrationView({ closeFunction }) {
  return (
    <div id="authBannedRegistrationView">
      <div className="modal-header">
        <button
          type="button"
          className="close js-auth-close"
          style={{ display: 'block' }}
          onClick={closeFunction}
        >
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </button>
        <h4 className="modal-title">Registration Could Not Be Completed</h4>
      </div>
      <div className="modal-body">
        <p>
          Your registration could not be completed. Due to past behavior by a
          user at this IP address, account registrations are no longer being
          accepted from this internet address.
        </p>
        <p>
          If you feel this is an error, please call us at{' '}
          <strong>(435) 255-8888</strong> or email us at{' '}
          <a href="mailto:Joe@GiddyDigs.com?subject=Assistance registering with blocked IP address 37.73.155.77">
            Joe@GiddyDigs.com
          </a>{' '}
          for further assistance.
        </p>
      </div>
      <div className="modal-footer">
        <button type="button" className="si-btn si-btn--default js-modal-close">
          Close
        </button>
      </div>
    </div>
  )
}

export default AuthBannedRegistrationView
