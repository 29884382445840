import React, { useEffect } from 'react'

import InputCustomSelect from './Input-custom-select'

function HomePageMinMaxBlock(props) {
  useEffect(() => {
    if (props.min !== '' && props.max !== '' && +props.min > +props.max) {
      exchangeValues()
    }
  })

  const exchangeValues = () => {
    const min = props.min
    const max = props.max
    props.setMin(max)
    props.setMax(min)
  }
  return (
    <>
      <div className="sbHolder" style={{ height: '75px' }}>
        <InputCustomSelect
          value={props.min}
          onChange={props.setMin}
          selectData={props.minSelectData}
        />
      </div>
      <div className="sbHolder">
        <InputCustomSelect
          value={props.max}
          onChange={props.setMax}
          selectData={props.maxSelectData}
        />
      </div>
    </>
  )
}

export default HomePageMinMaxBlock
