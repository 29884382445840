import React, { useState } from 'react'
import ResultsTopPanel from '../../../common/property-search/results/Results-top-panel'
import ResultsList from '../../../common/property-search/results/Results-list'

import { connect } from 'react-redux'

function ResultsPage(props) {
  const [listType, setListType] = useState('GALLERY')

  return (
    <section
      className="main-content"
      style={{ display: 'inline-block', width: '100%' }}
    >
      <div
        className="si-site-container-fluid col-md-12 si-site-container--full"
        style={{ display: 'inline-block' }}
      >
        <div className="si-sapp" id="sapp">
          <div className="si-row">
            <ResultsTopPanel listType={listType} setListType={setListType} />
            <ResultsList
              listType={listType}
              propertySearch={props.propertySearch}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
const mapStateToProps = (state) => {
  return {
    propertySearch: state.propertySearchReducer.propertySearch,
  }
}
export default connect(mapStateToProps, null)(ResultsPage)
