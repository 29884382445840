import React from 'react'
import { useLocation, Link } from 'react-router-dom'

const getBreadCrumbText = (path) => {
  return path
    .split('-')
    .map((path) => path.slice(0, 1).toUpperCase() + path.slice(1))
    .join(' ')
}

const Breadcrumb = () => {
  const currentLocation = useLocation()

  const breadCrumbs = () => {
    return currentLocation.pathname
      .split('/')
      .filter((path) => path !== '')
      .map((path, index, locations) => (
        <li
          key={index}
          itemScope
          itemType={'http://data-vocabulary.org/Breadcrumb'}
          className={index + 1 === locations.length ? 'current' : ''}
        >
          <Link
            to={'/' + locations.slice(0, index + 1).join('/')}
            itemProp={'url'}
          >
            <span itemProp={'name'}>{getBreadCrumbText(path)}</span>
          </Link>
        </li>
      ))
  }

  return (
    <div className={'si-container si-breadcrumb'} style={{ marginTop: '15px' }}>
      <ul>
        <li itemScope itemType={'http://data-vocabulary.org/Breadcrumb'}>
          <Link to="/" itemProp={'url'}>
            <span itemProp={'name'}>Home</span>
          </Link>
        </li>
        {breadCrumbs().map((breadcrumb) => breadcrumb)}
      </ul>
    </div>
  )
}

export default Breadcrumb
