import React, { useEffect, useState } from 'react'
import communities from '../../../database/comunities'
import { Link } from 'react-router-dom'

const FeaturedCities = () => {
  const [featuredCities, setFeaturedCities] = useState([])
  useEffect(() => {
    const currFeaturedCities = communities.filter(
      (community) => community.type === 'city' && community.is_featured === true
    )
    setFeaturedCities(currFeaturedCities)
  }, [])
  return (
    <div className="si-container si-content-label js-content-label">
      <div className="si-row">
        <div className="si-content-label__title">Featured Cities</div>
        <ul className="si-content-label__body">
          {featuredCities.map((featuredCity, index) => (
            <li key={index} className="si-content-label__link">
              <Link
                to={`/communities/${featuredCity.alias}/`}
                title={featuredCity.name}
              >
                {featuredCity.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default FeaturedCities
