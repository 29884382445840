import React from 'react'
import Breadcrumb from '../../../common/Breadcrumb'
import Sidebar from '../../../common/sidebar/Sidebar'

const MortgagePreApprovalPage = () => {
  return (
    <>
      <div className={'si-site-container col-md-8 si-site-container--full'}>
        <Breadcrumb />
        <div className={'si-content-area'}>
          <h1>Mortgage Pre-Approval</h1>
          <p>
            One of the first steps in the home buying process is to consult with
            a mortgage lender about your financing options.
          </p>
          <p>
            When you know what you can afford, you can focus your home search
            and make your move more quickly when you find the property you want.
          </p>
          <p>Fill out the form below to begin the process.</p>
        </div>
      </div>
      <Sidebar />{' '}
    </>
  )
}

export default MortgagePreApprovalPage
