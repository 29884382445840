import React from 'react'
import { Link } from 'react-router-dom'

import Breadcrumb from '../../../common/Breadcrumb'
import Details from '../../../common/property-search/details/Details'

import { useParams } from 'react-router-dom'

import './details-page.css'

function DetailsPage() {
  const { propertyId } = useParams()
  //   const { addressAlias } = useParams()
  return (
    <section className="main-content">
      <div className="top-strip">
        <div className="container"></div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="si-site-container-fluid col-md-12 si-site-container--full">
            <div className="si-container si-ld">
              <div className="si-ld-nav">
                <div className="container-ld">
                  <Breadcrumb />
                </div>

                <div className="si-ld-nav__pager">
                  <Link
                    to="/property-search/results/"
                    className="js-back-to-results"
                  >
                    <i className="fa fa-angle-double-up"></i>{' '}
                    <span>
                      Back<span> to Results</span>
                    </span>
                  </Link>

                  {/* <a
                    href="javascript: void(0);"
                    className="js-listing-detail"
                   rel="prev"
                  >
                    <i className="fa fa-angle-left"></i> <span>Prev</span>
                  </a>

                  <a
                    href="javascript: void(0);"
                    className="js-listing-detail"
                   rel="next"
                  >
                    <span>Next</span> <i className="fa fa-angle-right"></i>
                  </a> */}
                </div>
              </div>
              <Details propertyId={propertyId} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DetailsPage
