import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import './input-autocomplite.css'

class InputAutocomplete extends Component {
  static propTypes = {
    suggestions: PropTypes.instanceOf(Array),
  }

  static defaultProps = {
    suggestions: [],
  }

  constructor(props) {
    super(props)

    this.state = {
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: '',
    }
  }

  // Event fired when the input value is changed
  onChange = (e) => {
    const { suggestions } = this.props
    const userInput = e.currentTarget.value

    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = suggestions.filter(
      (suggestion) =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    )

    // Update the user input and filtered suggestions, reset the active
    // suggestion and make sure the suggestions are shown
    this.setState(
      {
        activeSuggestion: 0,
        filteredSuggestions,
        showSuggestions: true,
        userInput: e.currentTarget.value,
      },
      () => {
        this.props.onChange(this.state.userInput)
      }
    )
  }

  // Event fired when the user clicks on a suggestion
  onClick = (e) => {
    // Update the user input and reset the rest of the state
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: '',
    })
    this.props.onInputConfirm(e.currentTarget.innerText)
  }

  // Event fired when the user presses a key down
  onKeyDown = (e) => {
    const { activeSuggestion, filteredSuggestions } = this.state

    // User pressed the enter key, update the input and close the
    // suggestions
    if (e.keyCode === 13) {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: '',
      })
      this.props.onInputConfirm(filteredSuggestions[activeSuggestion])
    }
    // User pressed the up arrow, decrement the index
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return
      }
      this.setState({ activeSuggestion: activeSuggestion - 1 })
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 })
    }
  }

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput,
      },
    } = this

    let suggestionsListComponent

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className
              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = 'suggestion-active'
              }

              return (
                <li className={className} key={suggestion} onClick={onClick}>
                  {suggestion}
                </li>
              )
            })}
          </ul>
        )
      } else {
        suggestionsListComponent = (
          <div className="no-suggestions">
            <button className="si-search-tooltip__close js-st-close">x</button>
            <p className="si-search-tooltip__summary js-st-summary">
              We did not find any listings for:{' '}
              <strong>{this.state.userInput}</strong>
            </p>
            <hr />
            <h4 className="si-search-tooltip__title js-st-title">
              Check the spelling and formatting:
            </h4>
            <p className="si-search-tooltip__content js-st-content">
              Please double-check your spelling and try again. This tool
              currently supports search of active listings by{' '}
              <strong>{this.props.locationTypeName}</strong>.
            </p>
          </div>
        )
      }
    }

    return (
      <Fragment>
        <input
          type="text"
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={userInput}
          placeholder={this.props.placeholder}
        />
        {suggestionsListComponent}
      </Fragment>
    )
  }
}

export default InputAutocomplete
