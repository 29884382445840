import React, { useState, useEffect } from 'react'

import PhoneInput from '../../../inputs/Phone-input'

import InputCustomSelect from '../../../../property-search/form/inputs/Input-custom-select'
const styleDisplayNone = { display: 'none' }

function AuthRegisterInputViewStepTwo({ email }) {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
  const [isWorkingWithOurAgents, setIsWorkingWithOurAgents] = useState('')
  const [selectedAgent, setSelectedAgent] = useState('')

  const changeFirstNameHandler = (evt) => {
    setFirstName(evt.target.value)
  }
  const changeLastNameHandler = (evt) => {
    setLastName(evt.target.value)
  }
  const isWorkingWithOurAgentsChangeHandler = (evt) => {
    setIsWorkingWithOurAgents(evt.target.value)
  }

  const agents = [
    {
      value: '',
      label: 'Select an agent',
    },
    {
      value: '1235',
      label: 'Giddy Digs',
    },
    {
      value: '2795',
      label: 'Tyler Gillingham',
    },
    {
      value: '2125',
      label: 'Joe Neuman',
    },
    {
      value: '1396',
      label: 'Lorelei Neuman',
    },
  ]

  useEffect(() => {
    if (isWorkingWithOurAgents === 'no') {
      setSelectedAgent('')
    }
  }, [isWorkingWithOurAgents])
  useEffect(() => {
    console.log('--------------: ')
    console.log('email: ', email)
    console.log('firstName: ', firstName)
    console.log('lastName: ', lastName)
    console.log('phone: ', phone)
    console.log('isWorkingWithOurAgents: ', isWorkingWithOurAgents)
    console.log('selectedAgent: ', selectedAgent)
  })
  // const showError = (errorMessage) => (
  //   <div id="authRegisterLastName-error" class="si-error-msg">
  //     errorMessage
  //   </div>
  // )
  // const [isEmptyFirstNameErrosShown, setIsEmptyFirstNameErrosShown] = useState(
  //   ''
  // )
  // const formSubmitHandler = () => {}
  return (
    <div id="authRegisterStep2">
      <div className="modal-body">
        <div
          id="authRegisterMessage"
          style={styleDisplayNone}
          className="si-form__error"
        ></div>
        <div className="si-row">
          <div className="form-group si-form__full">
            <div className="si-form__column--left">
              <label className="control-label" htmlFor="authRegisterFirstName">
                First Name
              </label>
              <input
                type="text"
                className="form-control"
                value={firstName}
                onChange={changeFirstNameHandler}
              />
            </div>
            <div className="si-form__column--right">
              <label className="control-label" htmlFor="authRegisterLastName">
                Last Name
              </label>
              <input
                type="text"
                value={lastName}
                onChange={changeLastNameHandler}
                className="form-control"
              />
            </div>
          </div>

          <div className="form-group si-form__full">
            <label className="control-label" htmlFor="authRegisterPhone">
              Phone
            </label>
            <PhoneInput
              phone={phone}
              setPhone={setPhone}
              placeholder={'This will be used as your password'}
            />
          </div>

          <div
            id="authAgentRadioButtonPanel"
            className="form-group text-center"
          >
            <label
              className="control-label"
              htmlFor="authRegisterAgentRadioButtons"
            >
              Currently working with one of our agents?
            </label>
            <br />
            <label className="radio-inline">
              <input
                type="radio"
                name="authRegisterAgentRadioButtons"
                value="yes"
                checked={isWorkingWithOurAgents === 'yes'}
                onChange={isWorkingWithOurAgentsChangeHandler}
              />{' '}
              Yes
            </label>
            <label className="radio-inline">
              <input
                type="radio"
                name="authRegisterAgentRadioButtons"
                value="no"
                checked={isWorkingWithOurAgents === 'no'}
                onChange={isWorkingWithOurAgentsChangeHandler}
              />{' '}
              No
            </label>
          </div>
          {isWorkingWithOurAgents === 'yes' ? (
            <div id="authAgentListPanel" className="form-group si-form__full">
              <label className="control-label" htmlFor="authRegisterAgents">
                Our Agents
              </label>
              <InputCustomSelect
                selectData={agents}
                value={selectedAgent}
                onChange={setSelectedAgent}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          id="authRegisterSubmit"
          className="si-btn si-btn--primary si-btn--lg"
        >
          Enter
        </button>
        <p className="si-view-link">
          Returning User?{' '}
          <a href="/" className="js-signin-link">
            Login Here
          </a>
        </p>
        <p className="si-tos">
          <br />
          By registering you agree to our{' '}
          <a href="/privacy-policy/" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>{' '}
          and{' '}
          <a
            href="/terms-of-service/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </a>
        </p>
      </div>
    </div>
  )
}

export default AuthRegisterInputViewStepTwo
