import React, { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'

import UserRegister from '../common/users/User-register'

const displayNoneStyle = { display: 'none' }
const displayBlockStyle = { display: 'block' }

function Header() {
  const [isMobileDevice, setIsMobileDevice] = useState(false)
  const hideAllMenus = () => {
    const hide = false
    setIsMainMenuShown(hide)
    setIsSearchMenuShown(hide)
    setIsBuyMenuShown(hide)
    setIsSellMenuShown(hide)
    setIsOurTeamMenuShown(hide)
    setIsContactMenuShown(hide)
  }

  const inverseValue = (value) => !value
  const [isMainMenuShown, setIsMainMenuShown] = useState(false)
  const mainMenuButtonClickHandler = (evt) => {
    if (isMobileDevice) {
      evt.preventDefault()
      setIsMainMenuShown(inverseValue)
    }
  }

  const [isSearchMenuShown, setIsSearchMenuShown] = useState(false)
  const searchMenuButtonClickHandler = (evt) => {
    if (isMobileDevice) {
      evt.preventDefault()
      setIsSearchMenuShown(inverseValue)
    }
  }

  const [isBuyMenuShown, setIsBuyMenuShown] = useState(false)
  const buyMenuButtonClickHandler = (evt) => {
    if (isMobileDevice) {
      evt.preventDefault()
      setIsBuyMenuShown(inverseValue)
    }
  }

  const [isSellMenuShown, setIsSellMenuShown] = useState(false)
  const sellMenuButtonClickHandler = (evt) => {
    if (isMobileDevice) {
      evt.preventDefault()
      setIsSellMenuShown(inverseValue)
    }
  }

  const [isOurTeamMenuShown, setIsOurTeamMenuShown] = useState(false)
  const ourTeamMenuButtonClickHandler = (evt) => {
    if (isMobileDevice) {
      evt.preventDefault()
      setIsOurTeamMenuShown(inverseValue)
    }
  }
  const [isContactMenuShown, setIsContactMenuShown] = useState(false)
  const contactMenuButtonClickHandler = (evt) => {
    if (isMobileDevice) {
      evt.preventDefault()
      setIsContactMenuShown(inverseValue)
    }
  }

  useEffect(() => {
    if (window.innerWidth <= 992) {
      setIsMobileDevice(true)
    }
  }, [])
  const navBlurHandler = (evt) => {
    if (isMainMenuShown) {
      if (evt.target.closest('ul.main-nav') === null) {
        hideAllMenus()
      }
    }
  }
  useEffect(() => {
    if (isMobileDevice) {
      document.body.addEventListener('click', navBlurHandler)
      return () => {
        document.body.removeEventListener('click', navBlurHandler)
      }
    }
    return () => {}
  })
  return (
    <header className={'fixed dark'}>
      <div className={'container'}>
        <Link to="/" className={'logo'}>
          <img
            src="https://cdn.sitephotos.sierrastatic.com/984_logo_984-logo-drawing-20180808042545-1--20191104081947.png"
            alt="Giddy Digs"
          />
        </Link>

        <ul className={'sub-nav'}>
          <li>
            <UserRegister />
          </li>
          <li>
            <Link to="/blog/">Blog</Link>
          </li>
          {/* (
              <li className={'lang notranslate'}>
                <a href="javascript:;" className={'js-lang'}>
                  <i className={'flag en'}></i>
                  <i className={'fa fa-angle-down'}></i>
                </a>
                <ul className={'lang-list'}>
                  <li>
                    <a href="javascript:;" data-lang="en">
                      <i className={'flag en'}></i> ENGLISH
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;" data-lang="zh-CN">
                      <i className={'flag zh-CN'}></i> ??
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;" data-lang="fr">
                      <i className={'flag fr'}></i> FRAN�AIS
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;" data-lang="ko">
                      <i className={'flag ko'}></i> ???
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;" data-lang="it">
                      <i className={'flag it'}></i> ITALIANO
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;" data-lang="ja">
                      <i className={'flag ja'}></i> ???
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;" data-lang="de">
                      <i className={'flag de'}></i> DEUTSCH
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;" data-lang="pt">
                      <i className={'flag pt'}></i> PORTUGU�S
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;" data-lang="ru">
                      <i className={'flag ru'}></i> ???????
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;" data-lang="es">
                      <i className={'flag es'}></i> ESPA�OL
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;" data-lang="vi">
                      <i className={'flag vi'}></i> TI?NG VI?T
                    </a>
                  </li>
                  <li className={'empty'}>&nbsp;</li>
                </ul>
              </li>
            ) */}
        </ul>

        <nav>
          <a
            href="/"
            className="js-menu-toggle menu-button"
            onClick={mainMenuButtonClickHandler}
          >
            <i className={'fa fa-bars'}></i>Menu
          </a>
          <ul
            className={'main-nav'}
            style={
              isMobileDevice
                ? isMainMenuShown
                  ? displayBlockStyle
                  : displayNoneStyle
                : displayBlockStyle
            }
          >
            <li className={'li-drop'} onClick={searchMenuButtonClickHandler}>
              <Link to="/property-search/">Search</Link>
              <ul
                className={'dropdown'}
                style={isSearchMenuShown ? displayBlockStyle : displayNoneStyle}
              >
                <li onClick={hideAllMenus}>
                  <Link to="/property-search/search-form/">
                    Advanced Search
                  </Link>
                </li>
                <li onClick={hideAllMenus}>
                  <a href="/property-search/results/?searchtype=3">
                    Search by Map
                  </a>
                </li>
                <li onClick={hideAllMenus}>
                  <a href="/property-search/property-tracker/">
                    Property Tracker
                  </a>
                </li>
              </ul>
            </li>
            <li onClick={hideAllMenus}>
              <Link to="/communities/">Communities</Link>
            </li>
            <li className={'li-drop'} onClick={buyMenuButtonClickHandler}>
              <Link to="/buy/">Buy</Link>
              <ul
                className={'dropdown'}
                style={isBuyMenuShown ? displayBlockStyle : displayNoneStyle}
                onClick={hideAllMenus}
              >
                <li>
                  <Link to="/buy/mortgage-calculator/">
                    Mortgage Calculator
                  </Link>
                </li>

                <li>
                  <Link to="/buy/mortgage-pre-approval/">
                    Mortgage Pre-Approval
                  </Link>
                </li>

                <li>
                  <Link to="/buy/first-time-buyers/">First Time Buyers</Link>
                </li>

                <li>
                  <Link to="/buy/making-an-offer/">Making an Offer</Link>
                </li>

                <li>
                  <Link to="/buy/what-are-closing-costs/">
                    What Are Closing Costs?
                  </Link>
                </li>

                <li>
                  <Link to="/buy/escrow-now-what/">Escrow: Now What?</Link>
                </li>

                <li>
                  <Link to="/buy/financial-terms-glossary/">
                    Financial Terms Glossary
                  </Link>
                </li>

                <li>
                  <Link to="/buy/personalized-home-search/">
                    Personalized Home Search
                  </Link>
                </li>

                <li>
                  <Link to="/buy/todays-new-listings/">
                    Todays New Listings
                  </Link>
                </li>

                <li>
                  <Link to="/buy/ready-to-buy/">Ready to Buy</Link>
                </li>

                <li>
                  <Link to="/buy/utility-services/">Utility Services</Link>
                </li>
              </ul>
            </li>
            <li className={'li-drop'} onClick={sellMenuButtonClickHandler}>
              <Link to="/sell/">Sell</Link>
              <ul
                className={'dropdown'}
                style={isSellMenuShown ? displayBlockStyle : displayNoneStyle}
                onClick={hideAllMenus}
              >
                <li>
                  <Link to="/sell/">Selling a Home</Link>
                </li>

                <li>
                  <Link to="/sell/pricing-your-home/">Pricing Your Home</Link>
                </li>

                <li>
                  <Link to="/sell/marketing-your-home/">
                    Marketing Your Home
                  </Link>
                </li>

                <li>
                  <Link to="/sell/showing-your-home/">Showing Your Home</Link>
                </li>

                <li>
                  <Link to="/sell/adding-value/">Adding Value</Link>
                </li>

                <li>
                  <Link to="/sell/free-market-analysis/">
                    Free Market Analysis
                  </Link>
                </li>

                <li>
                  <Link to="/sell/sell-your-property">Sell your property</Link>
                </li>
              </ul>
            </li>
            <li className={'li-drop'} onClick={ourTeamMenuButtonClickHandler}>
              <Link to="/about/">Our Team</Link>

              <ul
                className={'dropdown'}
                style={
                  isOurTeamMenuShown ? displayBlockStyle : displayNoneStyle
                }
                onClick={hideAllMenus}
              >
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/about/featured-listings/">Featured Listings</Link>
                </li>
                <li>
                  <a href="/about/what-it-takes/">What it Takes</a>
                </li>
              </ul>
            </li>
            <li className={'li-drop'} onClick={contactMenuButtonClickHandler}>
              <Link to="/contact/">Contact</Link>

              <ul
                className={'dropdown'}
                style={
                  isContactMenuShown ? displayBlockStyle : displayNoneStyle
                }
                onClick={hideAllMenus}
              >
                <li>
                  <Link to="/contact/">Contact</Link>
                </li>

                <li>
                  <Link to="/contact/appointment">Appointment</Link>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  )
}

export default Header
