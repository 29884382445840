import React from 'react'
import Breadcrumb from '../../../common/Breadcrumb'
import Sidebar from '../../../common/sidebar/Sidebar'

const MarketingYourHomePage = () => {
  return (
    <>
      <div className={'si-site-container col-md-8 si-site-container--full'}>
        <Breadcrumb />
        <div className={'si-content-area'}>
          <h1>Marketing Your Home</h1>
          <p>
            <strong>
              MULTIPLE LISTING SERVICE
              <br />
            </strong>
            Your home is immediately listed on the Multiple Listing Service
            which updates a daily catalog of homes for sale through its member
            brokers and an on-line computer network, available to its members,
            with photos and descriptions of all listed properties. We personally
            submit a photo and the descriptive elements of your home to ensure
            that you have the best possible representation on the MLS.
          </p>
          <p>
            <strong>ON THE INTERNET</strong>
            <br /> All of our property listings are online under Featured
            Listings on our website as well as on all local websites that
            subscribe to the Multiple Listing Service. Current research from the
            National Association of Realtors indicates that more than 80% of
            home buyers now begin the buying process online. Our website
            includes the most advanced tools available, including Active Maps
            and automatic email notifications, to give buyers the most reason to
            keep coming back.
          </p>
          <p>
            <strong>
              FOR SALE SIGNS AND BROCHURE BOXES
              <br />
            </strong>
            Immediately our distinctive'For Sale' sign is placed on your
            property along with a brochure box containing photo/property
            information flyers.
          </p>
          <p>
            <strong>
              PHOTO FLYER
              <br />
            </strong>
            As quickly as possible after receiving your signed listing contract,
            we prepare and print a professional color flyer. We make a point of
            having flyers for all of our listings available at each of our open
            houses.
          </p>
        </div>
      </div>
      <Sidebar />
    </>
  )
}

export default MarketingYourHomePage
