import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

const SidebarBlog = ({ article }) => {
  function getArticleStripedTitle() {
    return (
      article.title
        .replace(/(<([^>]+)>)/gi, '')
        .split(' ')
        .slice(0, 5)
        .join(' ') + '...'
    )
  }
  function getFormatedDate(dateString) {
    const date = new Date(dateString)
    const month = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(
      date
    )
    const longYear = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(
      date
    )
    const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date)
    return `${month}/${day}/${longYear}`
  }
  const articleHref = article.link['@href']
    .split('/')
    .filter((element) => element !== '')
    .pop()
  return (
    <div className="blog">
      <div className="item">
        <div className="img">
          <Link to={`/blog/${articleHref}`}>
            <img src={article.image} alt={article.title} />
          </Link>
        </div>
        <div className="info">
          <Link to={`/blog/${articleHref}`} className="tag">
            {article.category['@term']}
          </Link>
          <div
            className="blog-title is-truncated"
            style={{ overflowWrap: 'break-word' }}
          >
            <Link to={`/blog/${articleHref}`} style={{ display: 'none' }}>
              {getArticleStripedTitle()}
            </Link>
          </div>
        </div>
        <div className="meta">
          <div className="author">BY {article.author.name}</div>
          <div className="date">{getFormatedDate(article.published)}</div>
        </div>
      </div>

      <Link to="/blog/" className="view-all btn-effect">
        View All Blog Posts
      </Link>
    </div>
  )
}

SidebarBlog.propTypes = {
  article: PropTypes.object,
}

export default SidebarBlog
