import React from 'react'
import ContactForm from '../../../common/forms/Contact-form'
import Breadcrumb from '../../../common/Breadcrumb'
import Sidebar from '../../../common/sidebar/Sidebar'

const ContactPage = () => {
  return (
    <>
      <div className={'si-site-container col-md-8 si-site-container--full'}>
        <Breadcrumb />
        <div className={'si-content-area'}>
          <h1>Contact Giddy Digs</h1>
          <p>
            Please understand that our job is to make sure you have all the
            information you need at the time you need it. Feel free to contact
            our team at any time at the following numbers:
          </p>
          <table
            style={{ width: '100%', height: '70px' }}
            cellSpacing="0"
            cellPadding="0"
            border="0"
          >
            <tbody>
              <tr>
                <td width="48%" valign="top">
                  <p>
                    <br />
                    (435) 255-8888
                  </p>
                  <p>
                    <br />
                    <a
                      href="mailto:help@giddydigs.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Happy@GiddyDigs.com
                    </a>
                  </p>
                </td>
                <td width="4%">&nbsp;</td>
                <td width="48%" valign="top">
                  <p>
                    <br />
                    Giddy Digs
                    <br />
                    1031 S. Bluff Street
                    <br />
                    St. George UT 84770
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <h2>Have a Question or Want a Free Market Report?</h2>
          <p>
            Please fill out the form below and we'll get back to you as quickly
            as we can!
          </p>
        </div>
        <ContactForm />
      </div>
      <Sidebar />
    </>
  )
}

export default ContactPage
