import React from 'react'
import PropTypes from 'prop-types'

import MinMaxBlock from './Min-max-block'

import { minLotSize, maxLotSize } from '../inputs/select-data'

function LotSize(props) {
  return (
    <MinMaxBlock
      name={'Lot Size (Acres)'}
      min={props.min}
      setMin={props.setMin}
      minSelectData={minLotSize}
      max={props.max}
      setMax={props.setMax}
      maxSelectData={maxLotSize}
    />
  )
}

LotSize.propTypes = {
  min: PropTypes.string,
  setMin: PropTypes.func,
  max: PropTypes.string,
  setMax: PropTypes.func,
}

export default LotSize
