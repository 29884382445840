import React, { useState } from 'react'

import AuthRegisterInputViewStepOne from './Auth-register-input-view-step-one'
import AuthRegisterInputViewStepTwo from './Auth-register-input-view-step-two'

function AuthRegisterInputView({ closeFunction, changeView }) {
  const [stepNumber, setStepNumber] = useState(1)
  const [email, setEmail] = useState('')

  return (
    <div id="authRegisterInputView" style={{ display: 'block' }}>
      <div className="modal-header">
        <button
          type="button"
          className="close js-auth-close"
          style={{ display: 'block' }}
          onClick={closeFunction}
        >
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </button>
        <h4
          className="modal-title auth-modal-title"
          id="authModalLabel"
          aria-level="2"
        >
          <span>Continue Your Home Search</span>
          <span>Continue Your Search</span>
        </h4>
      </div>

      <div id="authRegisterHowsoon" value="">
        {stepNumber === 1 ? (
          <AuthRegisterInputViewStepOne
            email={email}
            setEmail={setEmail}
            changeView={changeView}
            setStepNumber={setStepNumber}
          />
        ) : (
          ''
        )}
        {stepNumber === 2 ? <AuthRegisterInputViewStepTwo email={email} /> : ''}
      </div>
    </div>
  )
}

export default AuthRegisterInputView
