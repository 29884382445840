import axios from 'axios'

class Api {
  constructor() {
    this.apiClient = axios.create({
      baseURL: `/api/`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
  }

  propertySearch = {
    find: (data) => {
      return this.apiClient.post('property-search/', data)
    },
    count: (data) => {
      return this.apiClient.post('property-search/count', data)
    },
    updatePhotos: (data) => {
      return this.apiClient.post('property-search/updatephotos', data)
    },
    getProperty: (propertyId) => {
      return this.apiClient.get(`property-search/id/${propertyId}`)
    },
  }
  forms = {
    sheduleATour: (data) => {
      return this.apiClient.post('forms/details-form', data)
    },
  }
}

export default new Api()
