import React from 'react'
import Breadcrumb from '../../../common/Breadcrumb'
import Sidebar from '../../../common/sidebar/Sidebar'

const FirstTimeBuyersPage = () => {
  return (
    <>
      <div className={'si-site-container col-md-8 si-site-container--full'}>
        <Breadcrumb />
        <div className={'si-content-area'}>
          <h1>First-Time Home Buyers</h1>
          <p>
            If you are a first-time home buyer, use the guide below for helpful
            hints and tips and learn how to avoid common mistakes when buying
            your first home in St. George, UT.
          </p>
          <ul>
            <li>
              <strong>Pre-Qualification:</strong> Meet with a mortgage broker
              and find out how much you can afford to pay for a home.
            </li>
            <li>
              <strong>Pre-Approval:</strong> While knowing how much you can
              afford is the first step, sellers will be much more receptive to
              potential buyers who have been pre-approved. You'll also avoid
              being disappointed when going after homes that are out of your
              price range. With Pre-Approval, the buyer actually applies for a
              mortgage and receives a commitment in writing from a lender. This
              way, assuming the home you're interested in is at or under the
              amount you are pre-qualified for, the seller knows immediately
              that you are a serious buyer for that property. Costs for
              pre-approval are generally nominal and lenders will usually permit
              you to pay them when you close your loan.
            </li>
            <li>
              <strong>List of Needs &amp; Wants:</strong> Make 2 lists. The
              first should include items you must have (i.e., the number of
              bedrooms you need for the size of your family, a one-story house
              if accessibility is a factor, etc.). The second list is your
              wishes, things you would like to have (pool, den, etc.) but that
              are not absolutely necessary. Realistically for first-time buyers,
              you probably will not get everything on your wish list, but it
              will keep you on track for what you are looking for.
            </li>
            <li>
              <strong>Representation by a Professional:</strong> Consider hiring
              your own real estate agent, one who is working for you, the buyer,
              not the seller.
            </li>
            <li>
              <strong>Focus &amp; Organization:</strong> In a convenient
              location, keep handy the items that will assist you in maximizing
              your home search efforts. Such items may include:
            </li>
          </ul>
          <ol>
            <ol>
              <li>
                One or more detailed maps with your areas of interest
                highlighted.
              </li>
              <li>
                A file of the properties that your agent has shown to you, along
                with ads you have cut out from the newspaper.
              </li>
              <li>Paper and pen, for taking notes as you search.</li>
              <li>
                Instant or video camera to help refresh your memory on
                individual properties, especially if you are attending a series
                of showings.
              </li>
              <li>
                Location: Look at a potential property as if you are the seller.
                Would a prospective buyer find it attractive based on school
                district, crime rate, proximity to positive (shopping, parks,
                freeway access) and negative (abandoned properties, garbage
                dump, source of noise) features of the area?
              </li>
            </ol>
          </ol>
          <ul>
            <li>
              <strong>Visualize the house empty &amp; with your decor:</strong>{' '}
              Are the rooms laid out to fit your needs? Is there enough light?
            </li>
            <li>
              <strong>Be Objective:</strong> Instead of thinking with your heart
              when you find a home, think with your head. Does this home really
              meet your needs? There are many houses on the market, so don't
              make a hurried decision that you may regret later.
            </li>
            <li>
              <strong>Be Thorough:</strong> A few extra dollars well spent now
              may save you big expenses in the long run. Don't forget such
              essentials as:
            </li>
          </ul>
          <ol>
            <ol>
              <li>
                Include inspection &amp; mortgage contingencies in your written
                offer.
              </li>
              <li>Have the property inspected by a professional inspector.</li>
              <li>
                Request a second walk-through to take place within 24 hours of
                closing.
              </li>
              <li>
                You want to check to see that no changes have been made that
                were not agreed on (i.e., a nice chandelier that you assumed
                came with the sale having been replaced by a cheap ceiling
                light).
              </li>
            </ol>
          </ol>
          <p>
            <strong>All the above may seem rather overwhelming.</strong> That is
            why having a professional represent you and keep track of all the
            details for you is highly recommended. Please{' '}
            <a href="/contact/" title="Contact Us">
              contact us
            </a>{' '}
            directly to discuss any of these matters in further detail.
          </p>
        </div>
      </div>
      <Sidebar />
    </>
  )
}

export default FirstTimeBuyersPage
