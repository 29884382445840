import React from 'react'
import posts from '../../../database/blogposts'
import ArticlePreview from '../../common/blog/Article-preview'
import Breadcrumb from '../../common/Breadcrumb'
import Sidebar from '../../common/sidebar/Sidebar'
import './blog.css'
const BlogPage = () => {
  // posts = posts? posts : []
  return (
    <>
      <div className={'si-site-container col-md-8 si-site-container--full'}>
        <Breadcrumb />
        <h1>St. George, UT Real Estate Blog</h1>
        <section className="si-blog-posts si-blog-posts--gallery">
          {posts.map((post) => (
            <ArticlePreview key={post.id} article={post} />
          ))}
        </section>
      </div>
      <Sidebar />
    </>
  )
}

export default BlogPage
