import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

function ResultItemPhoto({ photos, link }) {
  const loadingPhotoUrl = '/images/listings/loadingphoto_mid.gif'
  const [curentPhotoUrl, setCurrentPhotoUrl] = useState(loadingPhotoUrl)
  const [curentPhotoIndex, setCurrentPhotoIndex] = useState(0)
  useEffect(() => {
    if (photos[curentPhotoIndex] !== undefined) {
      if (curentPhotoUrl !== photos[curentPhotoIndex]['UriLarge']) {
        setCurrentPhotoUrl(photos[curentPhotoIndex]['UriLarge'])
      }
    } else {
      setCurrentPhotoUrl(loadingPhotoUrl)
    }
    return () => {
      // no actions
    }
    // eslint-disable-next-line
  }, [curentPhotoIndex])

  const nextPhotoHandler = (evt) => {
    setCurrentPhotoIndex((prevNumber) => {
      return prevNumber + 1 < photos.length ? prevNumber + 1 : prevNumber
    })
  }
  const prevPhotoHandler = (evt) => {
    setCurrentPhotoIndex((prevNumber) => {
      return prevNumber - 1 >= 0 ? prevNumber - 1 : prevNumber
    })
  }
  return (
    <>
      <Link to={link} className="js-listing-detail">
        <div className="si-listing__photo-img js-images">
          <img
            src={curentPhotoUrl}
            className="js-image si-listing-photo"
            alt="656 N Bandolier LN Washington,  UT 84780"
            style={{ display: 'block' }}
          />
        </div>
      </Link>
      <div className="si-listing__controls">
        <button
          type="button"
          className={`si-listing__controls-prev js-prev ${
            curentPhotoIndex === 0 ? 'slick-disabled' : ''
          } `}
          disabled={curentPhotoIndex === 0}
          onClick={prevPhotoHandler}
        ></button>
        <button
          type="button"
          className={`si-listing__controls-next js-next ${
            curentPhotoIndex >= photos.length - 1 ? 'slick-disabled' : ''
          } `}
          disabled={curentPhotoIndex >= photos.length - 1}
          onClick={nextPhotoHandler}
        ></button>
      </div>
    </>
  )
}
ResultItemPhoto.propTypes = {
  photos: PropTypes.array.isRequired,
  link: PropTypes.string.isRequired,
}
export default ResultItemPhoto
