const priceReductionSelectData = [
  { value: '', label: '...' },
  { value: '-.01', label: 'At least 1%' },
  { value: '-.05', label: 'At least 5%' },
  { value: '-.1', label: 'At least 10%' },
  { value: '-.15', label: 'At least 15%' },
  { value: '-.2', label: 'At least 20%' },
  { value: '-.25', label: 'At least 25%' },
]

const priceReductionDateSelectData = [
  { value: '', label: '...' },
  { value: '1', label: 'Today' },
  { value: '2', label: 'Past Two Days' },
  { value: '3', label: 'Past Three Days' },
  { value: '7', label: 'Past Week' },
]

const sortResultsBySelectData = [
  { value: 'm.DateListed DESC', label: 'Newest' },
  { value: 'm.DateListed ASC', label: 'Oldest' },
  { value: 'm.Price DESC', label: 'Price (High to Low)' },
  { value: 'm.Price ASC', label: 'Price (Low to High)' },
  { value: 'm.Beds DESC', label: 'Bedrooms (Most)' },
]

const daysOnSiteSelectData = [
  { value: '', label: '...' },
  { value: '1', label: 'New listings (1 day)' },
  { value: '3', label: 'Less than 3 days' },
  { value: '7', label: 'Less than 7 days' },
  { value: '14', label: 'Less than 14 days' },
  { value: '30', label: 'Less than 30 days' },
  { value: '-7', label: 'More than 7 days' },
  { value: '-14', label: 'More than 14 days' },
  { value: '-30', label: 'More than 30 days ' },
  { value: '-60', label: 'More than 60 days' },
  { value: '-90', label: 'More than 90 days' },
  { value: '-180', label: 'More than 180 days' },
  { value: '-365', label: 'More than 365 days' },
]

export {
  priceReductionSelectData,
  priceReductionDateSelectData,
  sortResultsBySelectData,
  daysOnSiteSelectData,
}
