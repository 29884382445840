import React from 'react'

import FormWrapper from './Form-wrapper'

function AskAQuestion({ closeFunction }) {
  return (
    <FormWrapper
      closeFunction={closeFunction}
      formTitle={'Request More Info About This Listing'}
      info={'Complete the form below to request more info about this listing'}
      buttonName={'Request Info'}
    />
  )
}

export default AskAQuestion
