import React from 'react'
import PropTypes from 'prop-types'

const ListTypes = {
  MAP: 'MAP',
  LIST: 'LIST',
  GALLERY: 'GALLERY',
}

function ResultsTopPanel({ setListType, listType }) {
  const galleryButtonClickHandler = () => {
    setListType(ListTypes.GALLERY)
  }
  const listButtonClickHandler = () => {
    setListType(ListTypes.LIST)
  }
  const mapButtonClickHandler = () => {
    setListType(ListTypes.MAP)
  }

  return (
    <div className="si-container si-criteria-wrap affix-top">
      <div className="si-filter">
        <div className="si-row si-filter-controls">
          <div className="si-filter__tabs">
            <ul className="js-tabs">
              <li
                className={`${listType === ListTypes.GALLERY ? 'active' : ''}`}
              >
                <a
                  href="#sappGallery-"
                  aria-expanded="false"
                  onClick={galleryButtonClickHandler}
                >
                  <i className="fa fa-th-large"></i>
                  <span className="tab-lbl-md">Gallery</span>
                  <span className="tab-lbl-xs">Photo View</span>
                </a>
              </li>
              <li
                className={`list-view  ${
                  listType === ListTypes.LIST ? 'active' : ''
                }`}
                style={{ display: 'inline-block' }}
              >
                <a
                  href="#sappList-"
                  aria-expanded="true"
                  onClick={listButtonClickHandler}
                >
                  <i className="fa fa-list"></i>List
                </a>
              </li>
              <li className={`${listType === ListTypes.MAP ? 'active' : ''}`}>
                <a
                  href="#sappMap-"
                  aria-expanded="false"
                  onClick={mapButtonClickHandler}
                >
                  <i className="fa fa-map-marker"></i>
                  <span className="tab-lbl-md">Map</span>
                  <span className="tab-lbl-xs">Map View</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="si-filter__btn">
            {/* <a
              href="/"
              className="si-btn si-btn--secondary hidden-xs hidden-sm js-save-search"
            >
              <span>Save Search</span>
            </a> */}
          </div>
        </div>
      </div>
    </div>
  )
}
ResultsTopPanel.propTypes = {
  setListType: PropTypes.func.isRequired,
  listType: PropTypes.string.isRequired,
}
export default ResultsTopPanel
