import React, { useState, useEffect, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { updatePropertySearchObject } from '../../../store/actions/update-property-search-object-action'

import './Home-quick-search.css'
import HomePageMinMaxBlock from './home-quick-search/Home-page-min-max-block'
import {
  minPriceSelectData,
  maxPriceSelectData,
} from '../property-search/form/inputs/select-data'

const HomeQuickSearch = (props) => {
  // main search string
  const searchButtonHandler = (evt) => {
    props.history.push('/property-search/results/')
  }
  const [mainSearchString, setMainSearchString] = useState('')
  const [minPrice, setMinPrice] = useState('')
  const [maxPrice, setMaxPrice] = useState('')

  const ChangeMainSearchStringHandler = (evt) => {
    setMainSearchString(evt.target.value)
  }

  const getFormSearchData = useCallback(() => {
    return {
      ...(mainSearchString ? { mainSearchString } : {}),
      ...(minPrice ? { minPrice } : {}),
      ...(maxPrice ? { maxPrice } : {}),
    }
  }, [mainSearchString, minPrice, maxPrice])

  useEffect(() => {
    if (mainSearchString !== '' || minPrice !== '' || maxPrice !== '') {
      props.updatePropertySearchObject(getFormSearchData())
    }
    // eslint-disable-next-line
  }, [getFormSearchData, mainSearchString, minPrice, maxPrice])

  return (
    <section className="home-quick-search js-qs-home si-bgp--center">
      <div className="container js-qs-form">
        <h1 className="fade-in key-1">Find Your St. George Home</h1>
        <div className="search">
          <div className="listings-count js-qs-count fade-in key-2">
            1,384 listings available. Let us find the one that is right for you.
          </div>
          <div className="form fade-in key-3">
            <input
              type="text"
              className="js-qs-loc location ui-autocomplete-input"
              placeholder="Location, Zip, Address or MLS #"
              aria-label="Location, Zip, Address or MLS #"
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              value={mainSearchString}
              onChange={ChangeMainSearchStringHandler}
            />
            <HomePageMinMaxBlock
              min={minPrice}
              setMin={setMinPrice}
              minSelectData={minPriceSelectData}
              max={maxPrice}
              setMax={setMaxPrice}
              maxSelectData={maxPriceSelectData}
            />

            <button
              className="search-button js-qs-btn"
              onClick={searchButtonHandler}
            >
              Search Homes<i className="arrow"></i>
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default connect(null, { updatePropertySearchObject })(
  withRouter(HomeQuickSearch)
)
