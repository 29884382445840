import React, { useState, useEffect } from 'react'
import axios from 'axios'

const SparkAuth = () => {
  const [sparkOAuthLink, setSparkOAuthLink] = useState('1')
  const [code, setCode] = useState('')
  const [authStatus, setAuthStatus] = useState(false)

  useEffect(() => {
    axios
      .get('/api/spark/auth/grant-auth-link')
      .then((responce) => responce.data)
      .then((link) => setSparkOAuthLink(link))
      .catch((e) => console.log(e))
  }, [])

  useEffect(() => {
    axios
      .get('/api/spark/auth/status')
      .then((responce) => responce.data)
      .then((link) => setAuthStatus(link))
      .catch((e) => console.log(e))
  }, [])
  if (sparkOAuthLink === '') {
    return <>Loading...</>
  }

  const codeFormSubmitHandler = async (evt) => {
    evt.preventDefault()
    let generateAccessTokenResult
    try {
      generateAccessTokenResult = await axios.post(
        `/api/spark/auth/code/${code}`
      )
      if (generateAccessTokenResult.data) {
        setAuthStatus(true)
        setCode('')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const inputHandler = (evt) => {
    setCode(evt.target.value)
  }
  return (
    <>
      <h1>Spark authentification status - {authStatus ? 'OK' : 'No Auth'}</h1>
      <h2>Update access token</h2>
      1.{' '}
      <a href={sparkOAuthLink} target="_blank" rel="noopener noreferrer">
        Responce new Code for this App
      </a>{' '}
      <br /> 2. Copy Spark auth code from address field.
      <br /> 3. Input code here to generate access token
      <br />
      <form onSubmit={codeFormSubmitHandler}>
        <input
          type="text"
          value={code}
          onChange={inputHandler}
          placeholder="Enter your code here"
        ></input>
        <br />
        <button type="submit" className="si-btn si-btn--secondary">
          generate new access token
        </button>
      </form>
    </>
  )
}

export default SparkAuth
