import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <footer className={'no-logo'}>
      <div className={'container'}>
        <div className={'footer-content link-effect dark'}>
          <div className={'row'}>
            <div className={'col-md-3 col-sm-6'}>
              <h3>Contact</h3>
              <div className={'contact'}>
                Giddy Digs
                <br />
                1031 S. Bluff Street <br />
                St. George, UT 84770
                <br />
                O: (435) 525-1500
                <br />
                M: (435) 767-1597
                <br />
                E:
                <a href="mailto:Joe@GiddyDigs.com">Email Us</a>
                <br />
                License No. 10870810 CN00
                <br />
              </div>
            </div>

            <div className={'col-md-3 col-sm-6'}>
              <h3>Connect</h3>
              <ul className={'social'}>
                <li>
                  <a
                    href="https://www.facebook.com/Giddy-Digs-469983306773270/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className={'fa fa-facebook'}></i>Facebook
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/giddydigs/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className={'fa fa-linkedin'}></i>LinkedIn
                  </a>
                </li>
              </ul>
            </div>
            <div className={'col-md-3 col-sm-6'}>
              <h3>Navigation</h3>
              <ul>
                <li>
                  <Link to="/property-search/search-form/">Search</Link>
                </li>
                <li>
                  <Link to="/communities/">Communities</Link>
                </li>
                <li>
                  <Link to="/buy/">Buy</Link>
                </li>
                <li>
                  <Link to="/sell/">Sell</Link>
                </li>
                <li>
                  <Link to="/about/">Our Team</Link>
                </li>
                <li>
                  <Link to="/blog/">Blog</Link>
                </li>
                <li>
                  <Link to="/contact/">Contact</Link>
                </li>
              </ul>
            </div>
            <div className={'col-md-3 col-sm-6'}>
              <h3>Popular Searches</h3>
              <ul>
                <li>
                  <a href="/property-search/results/?searchid=210249">
                    Featured Listings
                  </a>
                </li>
                <li>
                  <a href="/property-search/results/?searchid=210250">
                    Recently Listed
                  </a>
                </li>
                <li>
                  <a href="/property-search/results/?searchid=210251">
                    Luxury Homes
                  </a>
                </li>
                <li>
                  <a href="/property-search/results/?searchid=210253">
                    Golf Course Homes
                  </a>
                </li>
                <li>
                  <a href="/property-search/results/?searchid=210254">
                    New Construction
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className={'copyright'}>
            <div className={'row'}>
              <div className={'col-md-6'}>
                <a href="/accessibility/">
                  <strong>Accessibility</strong>
                </a>
                <span>|</span>
                <a href="/terms-of-service/">Terms of Service</a>
                <span>|</span>
                <a href="/privacy-policy/">Privacy Policy</a>
                <span>|</span>
                <a href="/dmca-notice/">DMCA Notice</a>
                <span>|</span>
                <a href="/property-search/site-map/">Property Listings</a>
                <span>|</span>
                <a href="/site-map/">Sitemap</a> <br />
                &copy; Copyright 2019 Giddy Digs. All Rights Reserved.
              </div>
              <div className={'col-md-2'}>
                <div className={'eho-realtor'}>
                  <i className={'ssi ssi-eho'}></i>
                  <i className={'ssi ssi-realtor'}></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
