import React from 'react'
import Breadcrumb from '../../../common/Breadcrumb'
import Sidebar from '../../../common/sidebar/Sidebar'

const ShowingYourHomePage = () => {
  return (
    <>
      <div className={'si-site-container col-md-8 si-site-container--full'}>
        <Breadcrumb />
        <div className={'si-content-area'}>
          <h1>Showing Your Home</h1>
          <p>
            <strong>First Impressions are Lasting Impressions.</strong> <br />{' '}
            The exterior of your home often determines how buyers will view the
            interior, so:
          </p>
          <ul>
            <li>Make sure your front entrance is clean and inviting.</li>
            <li>Paint or replace your front door if it's faded or worn.</li>
            <li>
              Add some paint to shutters, trim and any other outside features
              showing signs of wear.
            </li>
          </ul>
          <p>
            <strong>Accentuate the Positive. </strong>
            <br /> "How we live in a home and how we sell a home are often two
            different things." Try to see your home with a fresh perspective and
            arrange each room to bring out its best attributes, including:
          </p>
          <ul>
            <li>Keep windows and floors clean.</li>
            <li>
              Replace faded wallpaper and glue any areas that have come away
              from the wall.
            </li>
            <li>Repair worn woodwork.</li>
            <li>Repaint scarred or dirty walls in a neutral color.</li>
            <li>Steam clean carpeting or replace it if necessary.</li>
            <li>
              Repair loose knobs, sticking doors and windows, warped cabinet
              drawers, broken light switches and other minor flaws.
            </li>
            <li>Check and repair caulking in bathtubs and showers.</li>
          </ul>
          <p>
            Try to see your home with a fresh perspective and arrange each room
            to bring out its best attributes, including:
          </p>
          <ul>
            <li>
              Open draperies and curtains to let the light in during the
              showing.
            </li>
            <li>
              Remove all unnecessary clutter from your attic, basement and
              closets to better display spacious rooms (consider storage or a
              garage sale to dispose of extraneous items).
            </li>
            <li>
              Arrange all your rooms neatly and remove excess furniture. Keep
              fresh, clean towels in the bathroom. Use candles or air fresheners
              to make the room smell pleasant.
            </li>
          </ul>
          <p>
            <strong>Put Your Home in the best possible light.</strong> <br />{' '}
            Strategically lighting your home, even during daytime showings, can
            create a cozy mood and highlight positive attributes of each room,
            so:
          </p>
          <ul>
            <li>
              Avoid the use of overhead lighting that makes rooms look washed
              out and lifeless.
            </li>
            <li>
              Be creative and arrange lamps to help smaller rooms seem larger,
              and large rooms seem more intimate.
            </li>
            <li>
              Use lighting to highlight the "living areas" of your home, such as
              a pair of chairs near a fireplace, or a table in a breakfast area.
            </li>
          </ul>
          <p>
            <strong> Inside: </strong>
          </p>
          <ul>
            <li>
              Clear all unnecessary objects from furniture throughout the house.
              Keep decorative objects on the furniture restricted to groups of
              1, 3, or 5 items.
            </li>
            <li>
              Clear all unnecessary objects from the kitchen countertops. If it
              hasn't been used for three months…put it away!
            </li>
            <li>
              Clear refrigerator fronts of messages, pictures, etc. (A sparse
              kitchen helps buyers mentally move their own things into your
              kitchen.)
            </li>
            <li>
              In the bathroom, remove any unnecessary items from countertops,
              tubs, shower stalls and commode tops. Keep only your most needed
              cosmetics, brushes, perfumes, etc., in one small group on the
              counter. Coordinate towels to one or two colors only.
            </li>
            <li>
              Rearrange or remove some of the furniture if necessary. As owners,
              many times we have too much furniture in a room. This is wonderful
              for our personal enjoyment, but when it comes to selling, we need
              to thin out as much as possible to make rooms appear larger.
            </li>
            <li>
              Take down or rearrange certain pictures or object on walls. Patch
              and paint if necessary.
            </li>
            <li>
              Review the house inside room by room. Paint any room needing
              paint, clean carpets or drapes that need it, clean windows.
            </li>
            <li>
              Leave on certain lights during the day. During "showings" turn on
              all lights and lamps.
            </li>
            <li>Have stereo FM on during the day for all viewings.</li>
            <li>
              Lockbox--#1 Importance: "If we don't have it, they won't show it."
            </li>
          </ul>
          <p>
            <strong>Outside: </strong>
          </p>
          <ul>
            <li>
              Trim landscaping to reveal architectural detail (bottom of
              windows, etc.). "If they can't see it, we can't sell it."
            </li>
            <li>
              Go around the perimeter of the house and move all garbage cans,
              discarded wood scraps, extra building materials, etc., into the
              garage.
            </li>
            <li>
              Check gutters and/or roof for dry rot. Make sure they are swept
              and cleaned.
            </li>
            <li>
              Look at all plants…prune bushes and trees. Keep plants from
              blocking windows. "You can't sell a house if you can't see it."
              Plants are like children-they grow so fast!!
            </li>
            <li>
              Weed and then bark all planting areas. Keep lawn freshly cut and
              fertilized. Remove any dead plants or shrubs.
            </li>
            <li>
              Clear patios or decks of all small items, such as small planters,
              flower pots, charcoal, barbecues, toys, etc. (Put them in the
              garage).
            </li>
            <li>
              Check paint condition of the house-especially the front door and
              trim. "Curb appeal really works!"
            </li>
          </ul>
          <p>
            <strong>In General</strong>
            <br /> Try to look at your house "through the buyer's eyes" as
            though you've never seen it or been there before. Any time or money
            spent on these items will bring you back more money in return, and
            hopefully a faster sale.
          </p>
          <h2>Nine Minute Showing Drill</h2>
          <p>
            Occasionally you will receive a call to schedule a showing to take
            place within the next few minutes. The following is a checklist for
            this type of panic:
          </p>
          <ol>
            <li>
              <strong> Sound:</strong> Turn off the television and tune the
              radio (low volume) to a soft rock, middle of the road or classic
              rock station.
            </li>
            <li>
              <strong> Sight:</strong> Turn on every light in the house (day or
              night) and open every drape and blind (day time only).
            </li>
            <li>
              <strong> Odors:</strong> Heat some frozen pastry slowly in the
              oven or heat a pan on the stove and then drop in a few drops of
              vanilla.
            </li>
            <li>
              <strong> Kitchen:</strong> Wipe kitchen counters, place dirty
              dishes in dishwasher.
            </li>
            <li>
              <strong> Bathrooms:</strong> Wipe counters, flush and close
              toilets.
            </li>
            <li>
              <strong> Living/Family Rooms: </strong>Hide magazines, newspapers,
              and games; remove clutter.
            </li>
            <li>
              <strong> Bedrooms:</strong> Straighten beds. Hide clutter under
              bed (not in closet).
            </li>
            <li>
              <strong> Exterior:</strong> Put away toys and clutter. Keep walk
              clear.
            </li>
            <li>
              <strong> Children &amp; Pets:</strong> They are a distraction, so
              send them outside.
            </li>
            <li>
              <strong> Goodbye:</strong> Sorry, but this is the single most
              important thing you can do in a showing to help sell your home!
              Even if the showing agent insists that it is okay to stay, you
              must leave. Buyers must get emotionally committed to your home to
              buy it and they cannot become emotional about "their new home" if
              you, the current owners, are "hanging around." Please, at the very
              least, go into the backyard. Even better, go to the store.
            </li>
          </ol>
        </div>
      </div>
      <Sidebar />
    </>
  )
}

export default ShowingYourHomePage
