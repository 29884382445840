import React from 'react'

import { Link } from 'react-router-dom'

const HomeAbout = () => {
  return (
    <section className="about">
      <div className="bg"></div>
      <div className="box si-site-container si-site-container--full">
        <div id="pageComponent56646">
          <div className="si-content-area">
            <h1>
              <span>Homes For Sale In Saint George UT</span>
            </h1>
            <p>
              <strong>
                Welcome to Giddy Digs, your one-stop source for St. George, UT,
                real estate.
              </strong>
            </p>
            <p>
              Use our state-of-the-art{' '}
              <Link to="/property-search/search-form/" title="Search the MLS">
                property search
              </Link>
              , including an{' '}
              <Link
                to="/property-search/results/?searchtype=3"
                title="Search the MLS by Map"
              >
                interactive map search
              </Link>
              , to find <strong>homes for sale in St. George, UT</strong>. These
              listings are updated instantly from the MLS.
            </p>
            <p>
              Or, use our{' '}
              <Link to="/communities/" title="Community Guide">
                Community Guide
              </Link>{' '}
              to help you in your search for{' '}
              <strong>St. George real estate</strong> listings. Our community
              searches will keep you up to date with the latest properties in
              the areas you are interested in.
            </p>
            <p>
              And, for your convenience, be sure to{' '}
              <Link
                title="Email Listings Alerts"
                to="/property-search/property-tracker/"
              >
                register for a free account
              </Link>{' '}
              to receive email notifications whenever new homes for sale come on
              the market that match your specific criteria and save your
              favorite properties for quick and easy access whenever you need —
              it's fast, easy, automatic and{' '}
              <Link
                to="https://form.jotform.com/91507034395154"
                target="_blank"
              >
                FREE!
              </Link>{' '}
              With a Property Tracker account, you'll be among the first to know
              about the newest <span>Homes For Sale In Saint George UT</span>.
            </p>
            <p>
              Thanks for visiting Giddy Digs, home of exquisite properties and
              excellent service.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomeAbout
