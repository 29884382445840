export const locationTypes = [
  {
    value: '0',
    label: 'City',
    placeholder: 'cities',
  },
  {
    value: '1',
    label: 'Subdivision',
    placeholder: 'subdivisions',
  },
  {
    value: '2',
    label: 'County',
    placeholder: 'counties',
  },
  {
    value: '3',
    label: 'Area',
    placeholder: 'areas',
  },
  {
    value: '4',
    label: 'Elementary School',
    placeholder: 'elementary schools',
  },
  {
    value: '5',
    label: 'Middle School',
    placeholder: 'middle schools',
  },
  {
    value: '6',
    label: 'High School',
    placeholder: 'high schools',
  },
  {
    value: '7',
    label: 'Zip Code',
    placeholder: 'zip codes',
  },
]
