import React from 'react'
import PropTypes from 'prop-types'

const styleDisplayNone = { display: 'none' }

function Modal({
  closeFunction,
  title,
  info,
  submitButtonName,
  submitHandler,
  children,
  formSent,
  sucsessResponce,
  errorResponce,
}) {
  const closeButtonHandler = (evt) => {
    if (closeFunction) {
      closeFunction()
    } else {
      alert('no close function')
    }
  }
  const overlayClickHandler = (evt) => {
    if (evt.target === evt.currentTarget) {
      closeFunction()
    }
  }
  return (
    <>
      <div
        className="si-modal modal fade in"
        id="ssModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="ssModalLabel"
        aria-hidden="true"
        style={{ display: 'block', paddingRight: '15px' }}
        onClick={overlayClickHandler}
      >
        <div className="modal-dialog">
          <form onSubmit={submitHandler}>
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={closeButtonHandler}
                >
                  <span aria-hidden="true">×</span>
                  <span className="sr-only">Close</span>
                </button>
                {title}
              </div>
              <div id="ssInputView" style={formSent ? styleDisplayNone : {}}>
                <div className="modal-info">{info}</div>
                <div className="modal-body">{children}</div>
                <div className="modal-footer">
                  <button
                    type="submit"
                    id="ssSubmit"
                    tabIndex="9"
                    className="si-btn si-btn--primary"
                  >
                    {submitButtonName}
                  </button>
                </div>
              </div>
              <div
                id="ssInputView"
                style={
                  formSent && !errorResponce && !sucsessResponce
                    ? {}
                    : styleDisplayNone
                }
              >
                <div className="modal-info">
                  <i className="fa fa-circle-o-notch fa-fw fa-spin"></i>
                </div>
                <div className="modal-body">
                  <i className="fa fa-circle-o-notch fa-fw fa-spin"></i>
                </div>
                <div className="modal-footer">
                  <i className="fa fa-circle-o-notch fa-fw fa-spin"></i>
                </div>
              </div>

              <div
                id="ssErrorView"
                style={errorResponce ? {} : styleDisplayNone}
              >
                <div className="modal-body">
                  <p>
                    <strong>Your contact request could not be sent!</strong>
                  </p>
                  <p>
                    Unfortunately, there was a problem and your message could
                    not be sent. Please make sure you have completed the
                    required fields correctly and try again.
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="si-btn si-btn--default js-modal-close"
                    data-dismiss="modal"
                    onClick={closeButtonHandler}
                  >
                    Close
                  </button>
                </div>
              </div>
              <div
                id="ssSuccessView"
                style={sucsessResponce ? {} : styleDisplayNone}
              >
                <div className="modal-body">
                  <p>
                    <strong>Your contact request was sent successfully!</strong>
                  </p>
                  <p>Thank you. We'll get back to you as quickly as we can.</p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="si-btn si-btn--default js-modal-close"
                    data-dismiss="modal"
                    onClick={closeButtonHandler}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="modal-backdrop fade in"></div>
    </>
  )
}
Modal.propTypes = {
  closeFunction: PropTypes.func.isRequired,
}
export default Modal
