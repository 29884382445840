import React from 'react'

import Breadcrumb from '../../../common/Breadcrumb'
import Sidebar from '../../../common/sidebar/Sidebar'
import MortgageCalculatorLite from '../../../common/forms/Mortage-calculator-lite'

const MortgageCalculatorPage = () => {
  return (
    <>
      <div className={'si-site-container col-md-8 si-site-container--full'}>
        <Breadcrumb />
        <div className={'si-content-area'}>
          <h1>Mortgage Calculator</h1>
          <p>
            <strong>
              This calculator will help you to determine how much house you can
              afford and/or qualify for.
            </strong>
          </p>
          <p>
            Complete or change the entry fields in the "Input" column of all
            three sections. The calculator will automatically recalculate
            anytime you press the Tab key after making a change to an input
            field.
          </p>
        </div>
        <MortgageCalculatorLite />
      </div>
      <Sidebar />{' '}
    </>
  )
}

export default MortgageCalculatorPage
