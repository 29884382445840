import React from 'react'
import Breadcrumb from '../../../common/Breadcrumb'
import Sidebar from '../../../common/sidebar/Sidebar'

const PersonalizedHomeSearchPage = () => {
  return (
    <>
      <div className={'si-site-container col-md-8 si-site-container--full'}>
        <Breadcrumb />
        <div className={'si-content-area'}>
          <h1>Let Us Help You Find Your Dream Home</h1>
          <p>
            Please fill out the form below so we know what you're looking for
            and we'll monitor new listings as they come on the market and alert
            you when we see properties that match your specific criteria.
          </p>
        </div>
      </div>
      <Sidebar />
    </>
  )
}

export default PersonalizedHomeSearchPage
