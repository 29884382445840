import React from 'react'
import { Link } from 'react-router-dom'

const WashingtonProperties = () => {
  return (
    <div className="box">
      <div className="title">Washington Co. Properties</div>
      <div className="stats js-listings-stats">
        <Link className="item" to="/property-search/results/?searchid=210243">
          <div className="name">Residential</div>
          <div className="number">
            <span>1384</span>
          </div>
        </Link>

        <Link className="item" to="/property-search/results/?searchid=210244">
          <div className="name">New Today</div>
          <div className="number">
            <span>0</span>
          </div>
        </Link>

        <Link className="item" to="/property-search/results/?searchid=210245">
          <div className="name">New This Week</div>
          <div className="number">
            <span>157</span>
          </div>
        </Link>

        <Link className="item" to="/property-search/results/?searchid=210246">
          <div className="name">Price Reduced</div>
          <div className="number">
            <span>332</span>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default WashingtonProperties
