import React from 'react'
import Breadcrumb from '../../../common/Breadcrumb'
import Sidebar from '../../../common/sidebar/Sidebar'

const AddingValuePage = () => {
  return (
    <>
      <div className={'si-site-container col-md-8 si-site-container--full'}>
        <Breadcrumb />
        <div className={'si-content-area'}>
          <h1>Adding Value</h1>
          <p>
            <strong>
              Which items will add value to your home and help with resale when
              the time comes to sell?
            </strong>
          </p>
          <p>
            The answer is: “Only the ones that future buyers will also want and
            be willing to pay for.”
          </p>
          <p>
            As a general rule, choosing the functional over the merely cosmetic
            and choosing neutral over unusual or exotic will pay at resale.
            Taste and current trends can also affect the value of an update.
            Unusual colors have a limited appeal.
          </p>
          <p>Which updates will add the most value to your home?</p>
          <ul>
            <li>
              Updates that increase usable space (an extra bathroom, larger
              and/or upgraded kitchen, master bedroom suite, more closet space,
              conversion of unused space to a home office).
            </li>
            <li>
              Updates that will lighten and brighten your rooms (white or light
              color paint and floor coverings, light woods, skylights, larger
              windows, French doors).
            </li>
            <li>
              Updates that will open your home to the outdoors for fresh air and
              entertaining (French doors to the patio or garden).
            </li>
            <li>
              Updates that will increase privacy and security (fencing,
              landscaping).
            </li>
          </ul>
        </div>
      </div>
      <Sidebar />
    </>
  )
}

export default AddingValuePage
