import React from 'react'


const PageLoader = () => {
    return (
      <div
          className="si-container js-loading si-loading"
        >
          <div className="si-loading-overlay is-absolute">
            <div className="msg">
              <span>
                <i className="fa fa-circle-o-notch fa-fw fa-spin"></i> Loading
                Search Form...
              </span>
            </div>
          </div>
        </div>
    )
  }


export default PageLoader