import React from 'react'
import PropTypes from 'prop-types'

import InputCustomSelect from '../inputs/Input-custom-select'

import { minGarageSpace } from '../inputs/select-data'

function GarageSpace(props) {
  return (
    <div className="si-sf-primary__item">
      <h2>Garage Space</h2>
      <InputCustomSelect
        value={props.value}
        onChange={props.setValue}
        selectData={minGarageSpace}
      />
    </div>
  )
}

GarageSpace.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
}

export default GarageSpace
