import React from 'react'
import posts from '../../../database/blogposts'
import SingleArticle from './Single-article'
import { useParams, Redirect } from 'react-router-dom'

//TODO safe dangerouslySetInnerHTML library

const ArticleWrapper = () => {
  const { alias } = useParams()
  const currentArticle = posts.find(
    (article) =>
      article.link['@href']
        .split('/')
        .filter((element) => element !== '')
        .pop() === alias
  )
  if (currentArticle === undefined) {
    return <Redirect to="/blog/" />
  }

  return <SingleArticle article={currentArticle} />
}

export default ArticleWrapper
