import React from 'react'
import Breadcrumb from '../../../common/Breadcrumb'
import Sidebar from '../../../common/sidebar/Sidebar'

const SellingAHomePage = () => {
  return (
    <>
      <div className={'si-site-container col-md-8 si-site-container--full'}>
        <Breadcrumb />
        <div className={'si-content-area'}>
          <h1>Selling a Home in St. George, UT</h1>
          <h3>We Know the St. George Real Estate Market.</h3>
          <p>
            St. George is a unique and diverse area. Each community has its own
            character. Each neighborhood appeals to a different buyer. To obtain
            the highest possible sales price for your home, the marketing must
            reach the right buyers with the right message. We have the network
            and experience to do this.
          </p>
          <h3>We Have the Creative Talent to Showcase Your Home.</h3>
          <p>
            The old adage, "You can't judge a book by its cover" is passé today.
            This is a fast-paced, image-oriented society in which we live. First
            impressions really do count! Every piece of information on your
            property, whether it be a brochure, the wording in the Multiple
            Listing Service, a presence on the Internet, or a well-placed
            advertisement, must show your home in the best possible light.
          </p>
          <p>
            During all phases of a listing from the initial paperwork and
            promotion through the very last details of the closing, we
            personally attend to all of the details. As a result, the large
            majority of our transactions come through referrals and repeat
            business.
          </p>
        </div>
      </div>
      <Sidebar />
    </>
  )
}

export default SellingAHomePage
