import React, { useState, useCallback, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { updatePropertySearchObject } from '../../../store/actions/update-property-search-object-action'

const SidebarSearch = (props) => {
  const searchButtonHandler = (evt) => {
    props.history.push('/property-search/results/')
  }

  const [mainSearchString, setMainSearchString] = useState('')
  const ChangeMainSearchStringHandler = (evt) => {
    setMainSearchString(evt.target.value)
  }
  const getFormSearchData = useCallback(() => {
    return {
      ...(mainSearchString ? { mainSearchString } : {}),
    }
  }, [mainSearchString])

  useEffect(() => {
    console.log(mainSearchString)
    props.updatePropertySearchObject(getFormSearchData())
    // eslint-disable-next-line
  }, [getFormSearchData, mainSearchString])

  return (
    <div className="box search js-qs-inner">
      <div className="js-qs-form">
        <div className="title">Search All Area Listings</div>
        <div className="box-content clearfix">
          <input
            type="text"
            className="js-qs-loc location ui-autocomplete-input"
            placeholder="Location, Zip, Address or MLS #"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            value={mainSearchString}
            onChange={ChangeMainSearchStringHandler}
          />
          <div className="buttons">
            <button
              className="search-button btn-effect js-qs-btn"
              onClick={searchButtonHandler}
            >
              <span>Search Homes</span>
            </button>
            <Link className="advanced" to="/property-search/search-form/">
              Advanced Search
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(null, { updatePropertySearchObject })(
  withRouter(SidebarSearch)
)
