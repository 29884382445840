import React, { useState } from 'react'

import Modal from '../../modals/Modal'

import AuthInputView from './blocks/Auth-input-view'
import AuthRegisterSuccessView from './blocks/Auth-register-success-view'
import AuthSigninSuccessView from './blocks/Auth-signin-success-view'
import AuthDeletedAccountView from './blocks/Auth-deleted-account-view'
import AuthBannedRegistrationView from './blocks/Auth-banned-registration-view'

import { viewNames } from './dictionaries/inputViews'

function UserLoginRegisterForm({ closeFunction, firstView }) {
  const [viewName] = useState(viewNames.AuthInputView)
  const [authInputViewName, setAuthInputViewName] = useState(firstView)

  return (
    <Modal closeFunction={closeFunction}>
      <div className="modal-dialog">
        <div className="modal-content">
          {viewName === viewNames.AuthInputView ? (
            <AuthInputView
              closeFunction={closeFunction}
              authInputViewName={authInputViewName}
              setAuthInputViewName={setAuthInputViewName}
            />
          ) : (
            ''
          )}
          {viewName === viewNames.AuthRegisterSuccessView ? (
            <AuthRegisterSuccessView closeFunction={closeFunction} />
          ) : (
            ''
          )}
          {viewName === viewNames.AuthSigninSuccessView ? (
            <AuthSigninSuccessView closeFunction={closeFunction} />
          ) : (
            ''
          )}
          {viewName === viewNames.AuthDeletedAccountView ? (
            <AuthDeletedAccountView closeFunction={closeFunction} />
          ) : (
            ''
          )}
          {viewName === viewNames.AuthBannedRegistrationView ? (
            <AuthBannedRegistrationView closeFunction={closeFunction} />
          ) : (
            ''
          )}
        </div>
      </div>
    </Modal>
  )
}

export default UserLoginRegisterForm
