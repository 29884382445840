import React from 'react'
import { Route, Switch } from 'react-router-dom'

import SparkAuth from './SparkAuth'

const AdminIndex = () => {
  return (
    <Switch>
      <Route path="/admin/spark">
        <SparkAuth />
      </Route>
      <Route>Admin</Route>
    </Switch>
  )
}

export default AdminIndex
