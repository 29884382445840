import React from 'react'
import PropTypes from 'prop-types'

import MinMaxBlock from './Min-max-block'

import { minHoaFeeSelectData, maxHoaFeeSelectData } from '../inputs/select-data'

function HOAFee(props) {
  return (
    <MinMaxBlock
      name={'HOA Fee'}
      min={props.min}
      setMin={props.setMin}
      minSelectData={minHoaFeeSelectData}
      max={props.max}
      setMax={props.setMax}
      maxSelectData={maxHoaFeeSelectData}
    />
  )
}

HOAFee.propTypes = {
  min: PropTypes.string,
  setMin: PropTypes.func,
  max: PropTypes.string,
  setMax: PropTypes.func,
}

export default HOAFee
