import React, { useState } from 'react'
import PropTypes from 'prop-types'

import ModalFullScreen from '../../../modals/Modal-full-screen'

function LeftButton({ onClick }) {
  return (
    <button
      type="button"
      className="si-ld-photos__prev js-large-photo-prev"
      style={{ display: 'block' }}
      onClick={onClick}
    >
      <svg className="icon" style={{ top: 0 }}>
        <use xlinkHref="/assets/listing-detail-icons.svg#icon-chevron-left" />
      </svg>
    </button>
  )
}

function RightButton({ onClick }) {
  return (
    <button
      type="button"
      className="si-ld-photos__next js-large-photo-next"
      style={{ display: 'block' }}
      onClick={onClick}
    >
      <svg className="icon" style={{ top: 0 }}>
        <use xlinkHref="/assets/listing-detail-icons.svg#icon-chevron-right" />
      </svg>
      <i className="fa fa-repeat fa-flip-horizontal" aria-hidden="true"></i>
    </button>
  )
}

function Photos({ photos }) {
  const [xPosition, setXPosition] = useState(0)
  const largePhotoWidth = 560 // px
  const smallPhotoWidth = 280 // px

  const containerWidth =
    largePhotoWidth + ((photos.length - 1) / 2) * smallPhotoWidth

  const [isToStartButtonShown, setIsToStartButtonShown] = useState(false)

  const movePhotos = (delta) => {
    setXPosition((previousXPosition) => {
      setIsToStartButtonShown(false)
      let newXPosition = previousXPosition + delta
      if (Math.abs(newXPosition) > containerWidth + delta) {
        newXPosition = -(containerWidth + delta)
        setIsToStartButtonShown(true)
      }
      if (newXPosition > 0) {
        newXPosition = 0
      }
      return newXPosition
    })
  }

  const nextPageButtonCkickHandler = () => {
    const moveDelta = -1 * (window.innerWidth - 50)
    movePhotos(moveDelta)
  }

  const prevPageButtonCkickHandler = (evt) => {
    const moveDelta = window.innerWidth - 50
    movePhotos(moveDelta)
  }

  const toStartButtonClickHandler = (evt) => {
    setIsToStartButtonShown(false)
    setXPosition(0)
  }

  const [isModalOpend, setIsModalOpend] = useState(false)
  const [currentPhoto, setCurrentPhoto] = useState(false)

  const openPhotoViewer = (index) => {
    setIsModalOpend(true)
    setCurrentPhoto(index)
  }
  const nextPhoto = () => {
    setCurrentPhoto((currentIndex) => {
      let newIndex = currentIndex + 1
      if (photos.length - 1 < newIndex) {
        newIndex = 0
      }
      return newIndex
    })
  }
  const prevPhoto = () => {
    setCurrentPhoto((currentIndex) => {
      let newIndex = currentIndex - 1
      if (newIndex < 0) {
        newIndex = photos.length - 1
      }
      return newIndex
    })
  }
  return (
    <section className="si-ld-overview js-scrollto">
      <div className="si-ld-photos has-carousel">
        <button
          type="button"
          className="si-ld-photos__prev js-large-photo-prev"
          style={{ display: 'block' }}
          onClick={prevPageButtonCkickHandler}
        >
          <svg className="icon">
            <use xlinkHref="/assets/listing-detail-icons.svg#icon-chevron-left" />
          </svg>
        </button>
        <div
          className="si-ld-photos__container is-carousel js-large-photo"
          style={{
            transform: `translateX(${xPosition}px)`,
            touchAction: 'pan-y',
            userSelect: 'none',
            WebkitUserDrag: 'none',
            WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
          }}
        >
          {photos.map((photo, index) => (
            <div key={`photo_${index}`} className="si-ld-media">
              <img
                src={photo.UriLarge}
                alt="Giddy Digs | 44 N 2820 E 1"
                style={{ display: 'inline-block' }}
                onClick={() => openPhotoViewer(index)}
              />
            </div>
          ))}
        </div>

        {isToStartButtonShown ? (
          <button
            type="button"
            className="si-ld-photos__next js-large-photo-next is-refresh"
            style={{ display: 'block' }}
            onClick={toStartButtonClickHandler}
          >
            <svg className="icon">
              <use xlinkHref="/assets/listing-detail-icons.svg#icon-chevron-right" />
            </svg>
            <i
              className="fa fa-repeat fa-flip-horizontal"
              aria-hidden="true"
            ></i>
          </button>
        ) : (
          <button
            type="button"
            className="si-ld-photos__next js-large-photo-next"
            style={{ display: 'block' }}
            onClick={nextPageButtonCkickHandler}
          >
            <svg className="icon">
              <use xlinkHref="/assets/listing-detail-icons.svg#icon-chevron-right" />
            </svg>
            <i
              className="fa fa-repeat fa-flip-horizontal"
              aria-hidden="true"
            ></i>
          </button>
        )}
      </div>
      {isModalOpend ? (
        <ModalFullScreen
          leftButtonSlot={<LeftButton onClick={prevPhoto} />}
          rightButtonSlot={<RightButton onClick={nextPhoto} />}
          close={() => {
            setIsModalOpend(false)
          }}
        >
          <div>
            {
              <img
                src={photos[currentPhoto].UriLarge}
                alt="123"
                style={{ maxWidth: '100%', maxHeight: '100%', margin: 'auto' }}
              />
            }
          </div>
        </ModalFullScreen>
      ) : null}
    </section>
  )
}
Photos.propTypes = {
  photos: PropTypes.array.isRequired,
}
export default Photos
