import React from 'react'

const styleDispalyNone = {
  display: 'none',
}

const PropertyStatus = () => {
  return (
    <section className="si-sf-section" style={styleDispalyNone}>
      <h2>Property Status</h2>
      <div className="si-sf-check clearfix js-property-statuses"></div>
      <div
        className="js-sold-days"
        style={{ display: 'none', maxWidth: '300px' }}
      >
        <h2>Sold Within Past</h2>
        <div className="si-sf-select">
          <select
            className="js-sold-days-select"
            sb="95429677"
            style={styleDispalyNone}
          >
            <option value="">All</option>
            <option value="1">1 Day</option>
            <option value="2">2 Days</option>
            <option value="3">3 Days</option>
            <option value="7">This Week</option>
            <option value="14">2 Weeks</option>
            <option value="30">This Month</option>
            <option value="60">Last 2 Months</option>
            <option value="90">Last 3 Months</option>
            <option value="120">Last 4 Months</option>
            <option value="150">Last 5 Months</option>
            <option value="180">Last 6 Months</option>
          </select>
          <div id="sbHolder_95429677" className="sbHolder">
            <a id="sbToggle_95429677" href="/" className="sbToggle">
              *
            </a>
            <a id="sbSelector_95429677" href="/" className="sbSelector">
              All
            </a>
            <ul
              id="sbOptions_95429677"
              className="sbOptions"
              style={styleDispalyNone}
            >
              <li>
                <a href="/" rel="" className="sbFocus">
                  All
                </a>
              </li>
              <li>
                <a href="#1" rel="1">
                  1 Day
                </a>
              </li>
              <li>
                <a href="#2" rel="2">
                  2 Days
                </a>
              </li>
              <li>
                <a href="#3" rel="3">
                  3 Days
                </a>
              </li>
              <li>
                <a href="#7" rel="7">
                  This Week
                </a>
              </li>
              <li>
                <a href="#14" rel="14">
                  2 Weeks
                </a>
              </li>
              <li>
                <a href="#30" rel="30">
                  This Month
                </a>
              </li>
              <li>
                <a href="#60" rel="60">
                  Last 2 Months
                </a>
              </li>
              <li>
                <a href="#90" rel="90">
                  Last 3 Months
                </a>
              </li>
              <li>
                <a href="#120" rel="120">
                  Last 4 Months
                </a>
              </li>
              <li>
                <a href="#150" rel="150">
                  Last 5 Months
                </a>
              </li>
              <li>
                <a href="#180" rel="180">
                  Last 6 Months
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PropertyStatus
