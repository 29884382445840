import React from 'react'
const styleDisplayNone = { display: 'none' }

function AuthSocialRegisterInputView({ closeFunction }) {
  return (
    <div id="authSocialRegisterInputView">
      <div className="modal-header">
        <button
          type="button"
          className="close js-auth-close"
          style={{ display: 'block' }}
          onClick={closeFunction}
        >
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </button>
        <h4 className="modal-title">
          <span className="js-social-profile"></span> Sign In
        </h4>
      </div>
      <form
        id="authSocialRegisterForm"
        name="authSocialRegisterForm"
        className="si-form"
        noValidate="noValidate"
      >
        <div className="modal-body">
          <div
            id="authSocialRegisterMessage"
            style={styleDisplayNone}
            className="si-form__error"
          ></div>
          <div className="si-row">
            <div className="form-group si-form__column">
              <label
                className="control-label"
                htmlFor="authSocialRegisterFirstName"
              >
                First Name
              </label>
              <input
                onChange={(evt) => console.log(evt.target.value)}
                type="text"
                name="firstName"
                id="authSocialRegisterFirstName"
                value=""
                className="form-control"
                disabled={true}
              />
            </div>
            <div className="form-group si-form__column">
              <label
                className="control-label"
                htmlFor="authSocialRegisterLastName"
              >
                Last Name
              </label>
              <input
                onChange={(evt) => console.log(evt.target.value)}
                type="text"
                name="lastName"
                id="authSocialRegisterLastName"
                value=""
                className="form-control"
                disabled={true}
              />
            </div>
            <div className="form-group si-form__full">
              <label
                className="control-label"
                htmlFor="authSocialRegisterEmail"
              >
                Email
              </label>
              <input
                onChange={(evt) => console.log(evt.target.value)}
                type="email"
                name="email"
                id="authSocialRegisterEmail"
                value=""
                className="form-control"
                disabled={true}
              />
            </div>

            <div className="form-group si-form__full">
              <label
                className="control-label"
                htmlFor="authSocialRegisterPhone"
              >
                Phone
              </label>
              <input
                onChange={(evt) => console.log(evt.target.value)}
                type="tel"
                name="phone"
                id="authSocialRegisterPhone"
                className="form-control"
                required=""
                maxLength="14"
              />
            </div>

            <div
              id="authSocialAgentRadioButtonPanel"
              className="form-group si-form__full text-center"
            >
              <label
                className="control-label"
                htmlFor="authSocialRegisterAgentRadioButtons"
              >
                Currently working with one of our agents?
              </label>
              <br />
              <label className="radio-inline">
                <input
                  onChange={(evt) => console.log(evt.target.value)}
                  type="radio"
                  name="authSocialRegisterAgentRadioButtons"
                  value="yes"
                />{' '}
                Yes
              </label>
              <label className="radio-inline">
                <input
                  onChange={(evt) => console.log(evt.target.value)}
                  type="radio"
                  name="authSocialRegisterAgentRadioButtons"
                  value="no"
                />{' '}
                No
              </label>
            </div>
            <div
              id="authSocialRegisterAgentListPanel"
              className="form-group si-form__full"
              style={styleDisplayNone}
            >
              <label
                className="control-label"
                htmlFor="authSocialRegisterAgents"
              >
                Our Agents
              </label>

              <select id="authSocialRegisterAgents" className="form-control">
                <option value="">Select an agent</option>

                <option value="1235">Giddy Digs</option>

                <option value="2795">Tyler Gillingham</option>

                <option value="2125">Joe Neuman</option>

                <option value="1396">Lorelei Neuman</option>
              </select>
            </div>

            <div
              id="authSocialRegisterAgentNamePanel"
              className="form-group si-form__full"
              style={styleDisplayNone}
            >
              <label
                className="control-label"
                htmlFor="authSocialRegisterAgentName"
              >
                Working with Another Agent? If so, Who?
              </label>
              <input
                onChange={(evt) => console.log(evt.target.value)}
                type="text"
                id="authSocialRegisterAgentName"
                className="form-control"
                placeholder="Agent Name"
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            id="authSocialRegisterSubmit"
            className="si-btn si-btn--primary si-btn--lg"
          >
            Enter
          </button>
          <div className="si-captcha-disclaimer">
            This site is protected by reCAPTCHA and the Google{' '}
            <a
              href="https://policies.google.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>{' '}
            and{' '}
            <a
              href="https://policies.google.com/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>{' '}
            apply.
          </div>
        </div>
      </form>
    </div>
  )
}

export default AuthSocialRegisterInputView
