import React from 'react'
import PropTypes from 'prop-types'

import './input-check-box.css'

const InputCheckBox = (props) => {
  return (
    <div
      className={`property_search_form__check_box ${
        props.value
          ? props.disabled
            ? 'property_search_form__check_box__disabled'
            : ' property_search_form__check_box__checked'
          : 'property_search_form__check_box'
      } `}
    >
      <input
        type="checkbox"
        id={props.id}
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
      />
      <label htmlFor={props.id} className={props.disabled ? 'disabled' : ''}>
        {props.label}
      </label>
    </div>
  )
}

InputCheckBox.propTypes = {
  id: PropTypes.string,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
}

export default InputCheckBox
