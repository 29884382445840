import React from 'react'
import PropTypes from 'prop-types'

import InputCheckBox from '../inputs/Input-check-box'

const ShowOnly = (props) => {
  return (
    <section className="si-sf-section">
      <h2>Show Only</h2>
      <div className="si-sf-check clearfix js-secondary-criteria">
        <InputCheckBox
          id="foreclosureProperties"
          value={props.foreclosureProperties}
          onChange={props.setForeclosureProperties}
          label="Foreclosure Properties"
        />

        <InputCheckBox
          id="shortSaleProperties"
          value={props.shortSaleProperties}
          onChange={props.setShortSaleProperties}
          label="Short Sale Properties"
        />

        <InputCheckBox
          id="newConstructionProperties"
          value={props.newConstructionProperties}
          onChange={props.setNewConstructionProperties}
          label="New Construction Properties"
        />
      </div>
    </section>
  )
}

ShowOnly.propTypes = {
  foreclosureProperties: PropTypes.bool,
  setForeclosureProperties: PropTypes.func,

  shortSaleProperties: PropTypes.bool,
  setShortSaleProperties: PropTypes.func,

  newConstructionProperties: PropTypes.bool,
  setNewConstructionProperties: PropTypes.func,
}

export default ShowOnly
