import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import ResultItemPhoto from './Result-item-photo'
import TimeCounter from './Time-counter'

import './jello-effect.css'
import {
  formatMoney,
  capitlizeFirstChar,
  shortText,
  getAlias,
} from '../../../../helpers/text-helpers'

import './results-item.css'

const timeCounterStyle = {
  position: 'absolute',
  top: '5px',
  left: '15px',
  width: '80%',
  height: '48px',
  fontSize: '1.0em',
  color: 'white',
  zIndex: '2',
  textShadow: '0px 0px 12px rgba(0,0,0,0.7), 0px 0px 9px rgba(0,0,0,0.5)',
}

function ResultsListItem({ property, timestamp }) {
  const maxDescriptionLength = 245
  const detailsLink = `/property-search/details/${property.Id}/${getAlias(
    property.StandardFields.UnparsedAddress
  )}`
  const [isHover, setIshover] = useState(false)
  const hoverHandler = () => {
    setIshover(true)
  }
  const unhoverHandler = () => {
    setIshover(false)
  }

  useEffect(() => {
    const item = document.querySelector('#id' + property.Id)
    if (item !== null) {
      item.addEventListener('mouseover', hoverHandler)
      item.addEventListener('mouseout', unhoverHandler)
    }
    return () => {
      item.removeEventListener('mouseover', hoverHandler)
      item.removeEventListener('mouseout', unhoverHandler)
    }
    // eslint-disable-next-line
  }, [])

  const [isApplyJelloEffect, setIsApplyJelloEffect] = useState(false)
  const [currentTime, setCurrentTime] = useState(
    Math.floor(new Date().getTime() / 1000)
  )
  useEffect(() => {
    const propertyTime = Math.floor(new Date(timestamp).getTime() / 1000)
    const jelloEffectPeriod = 3600
    const isNecessaryToApplyJelloEffect =
      currentTime - propertyTime < jelloEffectPeriod
    let timeoutId = null
    if (isNecessaryToApplyJelloEffect) {
      setIsApplyJelloEffect(true)
      timeoutId = setTimeout(() => {
        setCurrentTime(Math.floor(new Date().getTime() / 1000))
      }, 3000)
    } else {
      setIsApplyJelloEffect(false)
    }
    return () => {
      clearTimeout(timeoutId)
    }
  }, [currentTime, timestamp])

  return (
    <div
      id={'id' + property.Id}
      className={`si-listing ${isHover ? 'is-hover' : ''} ${
        isApplyJelloEffect ? 'jello' : ''
      }`}
    >
      <div className="si-row">
        <div className="si-listings-column--left">
          <div className="si-listing__photo js-listing-photo-nav">
            <div style={timeCounterStyle}>
              {timestamp ? (
                <>
                  <TimeCounter
                    timestamp={timestamp}
                    OnMarketDate={property.StandardFields.OnMarketDate}
                  />{' '}
                  on Giddy Digs
                </>
              ) : (
                ''
              )}
            </div>
            <button type="button" className="si-listing__like">
              <i className="fa fa-heart-o"></i>
            </button>
            <ResultItemPhoto photos={property.Photos} link={detailsLink} />
          </div>
        </div>
        <div className="si-listings-column--right">
          <div className="si-listing__content">
            <div className="si-listing__price-title">
              <div>
                <div className="si-listing__price">
                  <div className="si-listing__price-main">
                    ${formatMoney(property.StandardFields.CurrentPrice, 0)}{' '}
                  </div>
                </div>
                <div className="si-listing__neighborhood">
                  <span>Subdivision:</span>
                  <span className="si-listing__neighborhood-place">
                    {capitlizeFirstChar(
                      property.StandardFields.SubdivisionName
                    )}
                  </span>
                </div>
              </div>
              <div className="si-listing__title">
                <Link
                  to={detailsLink}
                  title={property.StandardFields.UnparsedAddress}
                  className="js-listing-detail"
                >
                  <div className="si-listing__title-main">
                    {property.StandardFields.UnparsedFirstLineAddress}
                  </div>
                  <div className="si-listing__title-description">
                    {property.StandardFields.City},{' '}
                    {property.StandardFields.StateOrProvince}{' '}
                    {property.StandardFields.PostalCode}
                  </div>
                </Link>
              </div>
            </div>
            <div className="si-listing__info">
              <div>
                <div className="si-listing__info-value">
                  <span>{property.StandardFields.BedsTotal}</span>
                </div>
                <div className="si-listing__info-label"> Beds </div>
              </div>
              <div>
                <div className="si-listing__info-value">
                  <span> {property.StandardFields.BathsTotal}</span>
                </div>
                <div className="si-listing__info-label"> Baths </div>
              </div>
              <div>
                <div className="si-listing__info-value">
                  <span>
                    {formatMoney(property.StandardFields.BuildingAreaTotal, 0)}
                  </span>
                </div>
                <div className="si-listing__info-label"> Sq.Ft. </div>
              </div>
              <div>
                <div className="si-listing__info-value">
                  <span>{property.StandardFields.YearBuilt}</span>
                </div>
                <div className="si-listing__info-label"> Year Built </div>
              </div>
              <div>
                <div className="si-listing__info-value">
                  <span style={{ fontSize: '18px' }}>
                    {timestamp ? (
                      <TimeCounter
                        timestamp={timestamp}
                        OnMarketDate={property.StandardFields.OnMarketDate}
                      />
                    ) : (
                      ''
                    )}
                  </span>
                </div>
                <div className="si-listing__info-label"> on Site </div>
              </div>
              <div>
                <div className="si-listing__info-value">
                  <span>
                    <small>
                      {property.StandardFields.ListingPrefix}{' '}
                      {property.StandardFields.ListingPrefix &&
                      property.StandardFields.ListingNumber
                        ? '-'
                        : ''}
                      {property.StandardFields.ListingNumber}
                    </small>
                  </span>
                </div>
                <div className="si-listing__info-label"> MLS </div>
              </div>
            </div>
            <div className="si-listing__description">
              <div>
                {shortText(
                  property.StandardFields.PublicRemarks,
                  maxDescriptionLength
                )}
              </div>
            </div>
            <div className="si-listing__footer">
              <div> {property.StandardFields.ListOfficeMarketingName} </div>
            </div>
          </div>
        </div>
      </div>
      <Link
        to={detailsLink}
        className="si-listing__detail js-listing-detail"
        title={`Details on ${property.StandardFields.UnparsedAddress}`}
      >
        View Full Details <i className="fa fa-caret-right"></i>
      </Link>
    </div>
  )
}
ResultsListItem.propTypes = {
  property: PropTypes.object.isRequired,
}
export default ResultsListItem
