import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

const FirstPage = ({ setCurrentPage }) => {
  return (
    <>
      <li>
        <a
          href="1"
          className="js-page-link"
          rel="nofollow"
          onClick={(evt) => {
            evt.preventDefault()
            setCurrentPage(1)
          }}
        >
          1
        </a>
      </li>
      <li className="disabled">
        <span>...</span>
      </li>
    </>
  )
}

const MiddlePages = ({ currentPage, pagesQuantity, setCurrentPage }) => {
  useEffect(() => {
    document.activeElement.blur()
    window.scrollTo(0, 0)
    return () => {
      //   cleanup
    }
  }, [currentPage])
  const generateMiddlePagesNumbers = () => {
    const middlePagesQuantity = pagesQuantity > 5 ? 5 : pagesQuantity
    const reducer = (numbers) => {
      if (numbers.length === 0) {
        const firstPageNumber = currentPage - 2
        if (firstPageNumber < 1) {
          return [1]
        }
        if (firstPageNumber + middlePagesQuantity > pagesQuantity) {
          return [pagesQuantity - (middlePagesQuantity - 1)]
        }

        return [firstPageNumber]
      }
      return [...numbers, numbers[numbers.length - 1] + 1]
    }
    return new Array(middlePagesQuantity).fill(0).reduce(reducer, [])
  }
  return (
    <>
      {generateMiddlePagesNumbers().map((number, index) => {
        return number === currentPage ? (
          <li key={index} className="current">
            <span>{number}</span>
          </li>
        ) : (
          <li key={index}>
            <a
              href="3"
              className="js-page-link"
              rel="nofollow"
              onClick={(evt) => {
                evt.preventDefault()
                setCurrentPage(number)
              }}
            >
              {number}
            </a>
          </li>
        )
      })}
    </>
  )
}
const LastPage = ({ lastPageNumber, setCurrentPage }) => {
  const pageButtonClickHandler = (evt) => {
    evt.preventDefault()
    setCurrentPage(lastPageNumber)
  }
  return (
    <>
      <li className="disabled">
        <span>...</span>
      </li>
      <li>
        <a
          href="/"
          className="js-page-link"
          rel="nofollow"
          onClick={pageButtonClickHandler}
        >
          {lastPageNumber}
        </a>
      </li>
    </>
  )
}

function Pagination({ setCurrentPage, currentPage, pagesQuantity }) {
  const prevButtonClickHandler = (evt) => {
    evt.preventDefault()
    if (currentPage !== 1) {
      console.log('prev')
      setCurrentPage(currentPage - 1)
    }
  }
  const nextButtonClickHandler = (evt) => {
    evt.preventDefault()
    if (currentPage < pagesQuantity) {
      console.log('next')
      setCurrentPage(currentPage + 1)
    }
  }
  return (
    <div
      className="si-container si-pager js-pagination"
      style={{ display: 'block' }}
    >
      <ul>
        <li className={`prev ${currentPage === 1 ? 'disabled' : ''}`}>
          <a
            href="/"
            className="js-page-link"
            rel="nofollow"
            onClick={prevButtonClickHandler}
          >
            <i className="fa fa-chevron-left"></i>
            <span> Prev</span>
          </a>
        </li>
        {currentPage > 3 ? <FirstPage setCurrentPage={setCurrentPage} /> : null}
        <MiddlePages
          currentPage={currentPage}
          pagesQuantity={pagesQuantity}
          setCurrentPage={setCurrentPage}
        />
        {currentPage + 2 < pagesQuantity ? (
          <LastPage
            lastPageNumber={pagesQuantity}
            setCurrentPage={setCurrentPage}
          />
        ) : null}

        <li
          className={`next ${currentPage === pagesQuantity ? 'disabled' : ''}`}
        >
          <a
            href="2"
            className="js-page-link"
            rel="nofollow"
            onClick={nextButtonClickHandler}
          >
            <span>Next</span>
            <i className="fa fa-chevron-right"></i>
          </a>
        </li>
      </ul>
    </div>
  )
}
Pagination.propTypes = {
  setCurrentPage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  pagesQuantity: PropTypes.number.isRequired,
}
export default Pagination
