export default [
  {
    id: '1',
    name: 'Apple Valley',
    alias: 'apple-valley',
    description: '',
    city: 'Apple Valley',
    zipcode: '84737',
    state: 'UT',
    is_featured: true,
    type: 'city',
  },
  {
    id: '2',
    name: 'Dammeron Valley',
    alias: 'dammeron-valley',
    description: '',
    city: 'dammeron-valley',
    zipcode: '84783',
    state: 'UT',
    is_featured: true,
    type: 'city',
  },
  {
    id: '3',
    name: 'Hurricane',
    alias: 'hurricane',
    description: '',
    city: 'Hurricane',
    zipcode: '84737',
    state: 'UT',
    is_featured: true,
    type: 'city',
  },
  {
    id: '4',
    name: 'La Verkin',
    alias: 'la-verkin',
    description: '',
    city: 'La Verkin',
    zipcode: '84745',
    state: 'UT',
    is_featured: true,
    type: 'city',
  },
  {
    id: '5',
    name: 'Santa Clara',
    alias: 'santa-clara',
    description: '',
    city: 'Santa Clara',
    zipcode: '84765',
    state: 'UT',
    is_featured: true,
    type: 'city',
  },
  {
    id: '6',
    name: 'St George',
    alias: 'st-george',
    description: '',
    city: 'St George',
    zipcode: '84770',
    state: 'UT',
    is_featured: true,
    type: 'city',
  },
  {
    id: '7',
    name: 'Washington',
    alias: 'washington',
    description: '',
    city: 'Washington',
    zipcode: '84780',
    state: 'UT',
    is_featured: true,
    type: 'city',
  },
  {
    id: '8',
    name: 'Cedar City',
    alias: 'cedar-city',
    description: '',
    city: 'Cedar City',
    zipcode: '84721',
    state: 'UT',
    is_featured: true,
    type: 'city',
  },

  {
    id: '9',
    name: 'Enterprise',
    alias: 'enterprise',
    description: '',
    city: 'Enterprise',
    zipcode: '84725',
    state: 'UT',
    is_featured: true,
    type: 'city',
  },
  {
    id: '10',
    name: 'Ivins',
    alias: 'ivins',
    description: '',
    city: 'Ivins',
    zipcode: '84738',
    state: 'UT',
    is_featured: true,
    type: 'city',
  },
  {
    id: '11',
    name: 'Leeds',
    alias: 'leeds',
    description: '',
    city: 'Leeds',
    zipcode: '84746',
    state: 'UT',
    is_featured: true,
    type: 'city',
  },
  {
    id: '12',
    name: 'Springdale',
    alias: 'springdale',
    description: '',
    city: 'Springdale',
    zipcode: '84767',
    state: 'UT',
    is_featured: true,
    type: 'city',
  },
  {
    id: '13',
    name: 'Virgin',
    alias: 'virgin',
    description: '',
    city: 'Virgin',
    zipcode: '84737',
    state: 'UT',
    is_featured: true,
    type: 'city',
  },
  {
    id: '14',
    name: 'Bloomington',
    alias: 'bloomington',
    description: '',
    city: 'St George',
    zipcode: '84790',
    state: 'UT',
    is_featured: true,
    type: 'neighborhood',
  },
  {
    id: '15',
    name: 'Coral Canyon',
    alias: 'coral-canyon',
    description: '',
    city: 'Washington',
    zipcode: '84780',
    state: 'UT',
    is_featured: true,
    type: 'neighborhood',
  },
  {
    id: '16',
    name: 'Entrada',
    alias: 'entrada',
    description: '',
    city: 'St George',
    zipcode: '84770',
    state: 'UT',
    is_featured: true,
    type: 'neighborhood',
  },
  {
    id: '17',
    name: 'Sun River',
    alias: 'sun-river',
    description: '',
    city: 'St George',
    zipcode: '84790',
    state: 'UT',
    is_featured: true,
    type: 'neighborhood',
  },
  {
    id: '18',
    name: 'Winchester Hills',
    alias: 'winchester-hills',
    description: '',
    city: 'St George',
    zipcode: '84770',
    state: 'UT',
    is_featured: true,
    type: 'neighborhood',
  },
  {
    id: '19',
    name: 'Bloomington Hills',
    alias: 'bloomington-hills',
    description: '',
    city: 'St George',
    zipcode: '84790',
    state: 'UT',
    is_featured: true,
    type: 'neighborhood',
  },
  {
    id: '20',
    name: 'Dixie Springs',
    alias: 'dixie-springs',
    description: '',
    city: 'Hurricane',
    zipcode: '84737',
    state: 'UT',
    is_featured: true,
    type: 'neighborhood',
  },
  {
    id: '21',
    name: 'Little Valley',
    alias: 'little-valley',
    description: '',
    city: 'St George',
    zipcode: '84790',
    state: 'UT',
    is_featured: true,
    type: 'neighborhood',
  },
  {
    id: '22',
    name: 'The Ledges',
    alias: 'the-ledges',
    description: '',
    city: 'St George',
    zipcode: '84770',
    state: 'UT',
    is_featured: true,
    type: 'neighborhood',
  },
]
