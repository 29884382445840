import React from 'react'
import PropTypes from 'prop-types'

//TODO safe dangerouslySetInnerHTML library

const ArticlePreview = ({ article }) => {
  function getArticleStripedPreviewText() {
    return {
      __html:
        article.summary
          .replace(/(<([^>]+)>)/gi, '')
          .split(' ')
          .slice(0, 22)
          .join(' ') + '...',
    }
  }
  function getFormatedDate(dateString) {
    const date = new Date(dateString)
    const longMonth = new Intl.DateTimeFormat('en', { month: 'long' }).format(
      date
    )
    const longYear = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(
      date
    )
    const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date)
    return `${longMonth} ${day}, ${longYear}`
  }
  const articleHref = article.link['@href']
    .split('/')
    .filter((element) => element !== '')
    .pop()
  return (
    <article id="entry2013" className="si-blog-entry last clearfix">
      <div className="si-blog-entry__photo">
        <a href={`/blog/${articleHref}`}>
          <img src={article.image} alt={article.title} />
        </a>
      </div>
      <div className="si-blog-entry__details">
        <a className="si-blog-entry__community" href={`/blog/${articleHref}`}>
          {article.category['@term']}
        </a>
        <a href={`/blog/${articleHref}`} title={article.title}>
          <h2>{article.title}</h2>
        </a>
        <p dangerouslySetInnerHTML={getArticleStripedPreviewText()}></p>
        <a
          href={`/blog/${articleHref}`}
          className="si-btn si-btn--secondary"
          title={article.title}
        >
          Read More
        </a>
        <div className="si-blog-entry__info">
          <span>
            Posted by <strong>{article.author.name}</strong>{' '}
          </span>
          <span>on {getFormatedDate(article.published)} </span>
          <br />
        </div>
      </div>
    </article>
  )
}

ArticlePreview.propTypes = {
  article: PropTypes.object,
}

export default ArticlePreview
