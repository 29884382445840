import React from 'react'
import PropTypes from 'prop-types'

import { useState, useEffect } from 'react'

import './input-custom-select.css'
const styleDispalyNone = {
  display: 'none',
}

const InputCustomSelect = (props) => {
  const currentDataList = props.selectData
  const currentValue = props.value
  const [isListShown, setIsListShown] = useState(false)
  const [listHeight, setListHeight] = useState(300)
  const selectClickHandler = (evt) => {
    setIsListShown(!isListShown)
    setListHeight(countPixelsFromElementToBottom(evt.target))
  }

  const countPixelsFromElementToBottom = (element) => {
    return window.innerHeight - element.getBoundingClientRect().bottom - 5
  }

  const selectClickElementHandler = (evt) => {
    props.onChange(evt.target.dataset.value)
    setIsListShown(false)
  }
  const closeList = () => {
    setIsListShown(false)
  }
  useEffect(() => {
    document.body.addEventListener('click', closeList)
    return () => {
      document.body.removeEventListener('click', closeList)
    }
  })
  const getLabelByValue = (value) => {
    const goodListelement = currentDataList.find(
      (element) => element.value === value
    )
    return goodListelement !== undefined
      ? goodListelement.label
      : currentDataList[0].label
  }
  const selectChangeHandler = (evt) => {}
  return (
    <div className={props.className ? props.className : 'si-sf-select'}>
      <select
        className="js-primary-select"
        value={currentValue}
        onChange={selectChangeHandler}
        style={styleDispalyNone}
      >
        {currentDataList.map((option, index) => (
          <option key={'option_' + index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div className="sbHolder">
        <button onClick={selectClickHandler} className="sbToggle"></button>
        <button onClick={selectClickHandler} className="sbSelector">
          {getLabelByValue(currentValue)}
        </button>
        <ul
          className={
            isListShown
              ? 'sbOptions input-custom-select__list_shown'
              : 'sbOptions input-custom-select__list_hidden'
          }
          onClick={selectClickElementHandler}
          style={{ maxHeight: listHeight }}
        >
          {currentDataList.map((option, index) => (
            <li
              key={'li_' + index}
              className="input-custom-select__list-element"
            >
              <button data-value={option.value}>{option.label}</button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

InputCustomSelect.propTypes = {
  selectData: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default InputCustomSelect
