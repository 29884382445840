import React, {useState, useEffect} from 'react'
import ProretrySearchForm from './form/Property-search-form'
import PageLoader from './Page-loader'


import './search-form.css'

const PropertySearchFormMain = () => {
  const [isLoading, setIsLoading] = useState(false)
  useEffect(()=>{
    setTimeout(setIsLoading, 100, false)
  },[])
  return (
    <div id="searchForm" className="si-sf">
      {isLoading ? <PageLoader /> : <ProretrySearchForm /> }
    </div>
  )
}

export default PropertySearchFormMain
