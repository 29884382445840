import React from 'react'
import FeaturedCities from '../../../common/communities/Featured-cities'
import FeaturedNeighborhoods from '../../../common/communities/Featured-neighborhoods'
import Breadcrumb from '../../../common/Breadcrumb'
import Sidebar from '../../../common/sidebar/Sidebar'

const CommunitiesPage = () => {
  return (
    <>
      <div className={'si-site-container col-md-8 si-site-container--full'}>
        <Breadcrumb />{' '}
        <div className={'si-content-area'}>
          <h1>Community Guide</h1>
          <p>
            Use the guide below to search{' '}
            <strong>homes for sale in St. George</strong> and surrounding
            communities. Each of these pages contains the active real estate
            listings for that area, updated every day. If you already know which
            communities you like, be sure to{' '}
            <strong>
              <a href="/" title="Email Listings Alerts">
                sign up for email alerts of new listings
              </a>
            </strong>{' '}
            as soon as they hit the market -- it's fast, easy, automatic and
            FREE!
          </p>
        </div>
        <FeaturedCities />
        <FeaturedNeighborhoods />
      </div>
      <Sidebar />
    </>
  )
}

export default CommunitiesPage
