import React from 'react'

import { Link } from 'react-router-dom'
import ArticlePreviewSimple from '../blog/Article-preview-simple'
import articles from '../../../database/blogposts'

const HomeBlog = () => {
  return (
    <section className="blog padding">
      <div className="container-fluid">
        <h2>Recently from Our Blog</h2>
        <div className="blog-items">
          <div className="item-wrap">
            <div className="item">
              <ArticlePreviewSimple article={articles[0]} />
            </div>
          </div>
          <div className="item-wrap">
            <div className="item">
              <ArticlePreviewSimple article={articles[1]} />
            </div>
          </div>
          <div className="item-wrap">
            <div className="item">
              <ArticlePreviewSimple article={articles[2]} />
            </div>
          </div>
          <div className="item-wrap">
            <div className="item">
              <ArticlePreviewSimple article={articles[3]} />
            </div>
          </div>
          <div className="clearfix"></div>
          <div className="view-all text-center">
            <Link to="/blog/" className="btn-effect">
              <span>View All Blog Posts</span>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomeBlog
