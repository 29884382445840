import React from 'react'
import Breadcrumb from '../../../common/Breadcrumb'
import Sidebar from '../../../common/sidebar/Sidebar'

const PricingYourHomePage = () => {
  return (
    <>
      <div className={'si-site-container col-md-8 si-site-container--full'}>
        <Breadcrumb />
        <div className={'si-content-area'}>
          <h1>Pricing Your Home</h1>
          <p>
            <strong>Pricing your home is both an art and a science.</strong>{' '}
            Achieving the optimal price is the result of both objective research
            into comparable properties and a gut feeling about your property and
            the current market.
          </p>
          <p>
            <strong>The right price should:</strong>
          </p>
          <ul>
            <li>Attract buyers</li>
            <li>Allow you to earn the most money possible</li>
            <li>Help you sell as quickly as possible</li>
          </ul>
          <p>
            The simple fact is, price is the number one factor that most
            homebuyers use to determine which homes they want to view. And it's
            important to remember that, although the price is set by you, the
            value of the home is determined by the buyer. Try to avoid allowing
            your enthusiasm to impact your better judgment - overpricing is a
            common mistake that can cost you in the end.
          </p>
          <p>
            <strong>The Importance of Proper Pricing</strong>
          </p>
          <ul>
            <li>Faster sale and less inconvenience</li>
            <li>Exposure to more buyers</li>
            <li>Increases Realtors response</li>
            <li>Generates more advertising/sign calls</li>
            <li>Attracts higher offers</li>
            <li>Means more money to seller</li>
            <li>Avoids being "shopworn"</li>
          </ul>
          <p>
            What really matters is how your home stacks up against the others
            currently offered for sale and recently sold in your neighborhood.
            Buyers will be comparing.
          </p>
          <p>
            <strong>Common Reasons for Overpricing</strong>
          </p>
          <ul>
            <li>Over-improvement</li>
            <li>Need</li>
            <li>Purchasing in higher-priced area</li>
            <li>Original purchase price too high</li>
            <li>Lack of factual data</li>
            <li>Bargaining room</li>
            <li>Move isn't necessary</li>
            <li>Assessed value</li>
            <li>Emotional attachment</li>
            <li>Opinion of family and neighbors</li>
          </ul>
          <p>
            <strong>Dangers of Overpricing</strong>
          </p>
          <ul>
            <li>
              Most of the activity on your home will occur in the first few
              weeks. Pricing a home properly and then creating immediate urgency
              in the minds of agents and buyers is critical.
            </li>
            <li>
              Buyers who have seen most available homes in their price range are
              waiting for the "right house" to come on the market. That's why if
              a house is priced right, it will sell quickly. The buyers are
              there waiting for it.
            </li>
            <li>
              Don't start with a high price and the assumption that you can
              reduce it later. By the time you decide to lower the price, it may
              be too late, as interest will have alreadywaned.
            </li>
            <li>
              A major cause for concern is appraisal problems; overpricing can
              lead to loan rejections and lost time.
            </li>
            <li>
              Even if your home is nicer than other homes in the same area, your
              house won't be picked for viewing if you set the price too high.
            </li>
            <li>
              Buyers and agents become aware of the long exposure period and
              often are hesitant to make an offer because they fear something is
              wrong with the property.
            </li>
            <li>Attracting the wrong buyers.</li>
            <li>Fewer potentially qualified buyers will respond.</li>
            <li>You might help sell similar homes that are priced low.</li>
            <li>
              You could lose money as a result of making extra mortgage payments
              while incurring taxes, insurance and unplanned maintenance costs.
            </li>
          </ul>
          <p>
            <strong>The Role of a Real Estate Agent in Pricing</strong>
          </p>
          <ul>
            <li>
              Provide you with a comparative market analysis (CMA), a comparison
              of the prices of recently sold homes that are similar in terms of
              location, style, and amenities. A CMAis performed by comparing
              previously sold homes in the area, and currently active homes to
              know your competition.
            </li>
            <li>There is no "exact price" for real estate</li>
            <li>We don't tell you what we think your home is "worth".</li>
            <li>
              The market determines value…together we determine the price.
            </li>
            <li>
              You determine the price based on the factors you control:
              <br /> &nbsp;&nbsp;&nbsp;&nbsp;- Marketing time <br />{' '}
              &nbsp;&nbsp;&nbsp;&nbsp;- Financing alternatives provided <br />{' '}
              &nbsp;&nbsp;&nbsp;&nbsp;- Condition <br />{' '}
              &nbsp;&nbsp;&nbsp;&nbsp;- Exposure method
            </li>
            <li>
              Keep in touch with market trends and keep up to date with market
              activity of comparable homes.
            </li>
            <li>Estimate your net proceeds.</li>
            <li>Help to determine offering incentives.</li>
          </ul>
          <p>
            An agent has NO control over the market, only the marketing plan.{' '}
            <strong>Never select an agent based on price.</strong>
          </p>
        </div>
      </div>
      <Sidebar />
    </>
  )
}

export default PricingYourHomePage
