import React from 'react'

function AuthSigninSuccessView({ closeFunction }) {
  return (
    <div id="authSigninSuccessView">
      <div className="modal-header">
        <button
          type="button"
          className="close js-auth-close"
          style={{ display: 'block' }}
          onClick={closeFunction}
        >
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </button>
        <h4 className="modal-title">Success!</h4>
      </div>
      <div className="modal-body">
        <p>
          <strong>Thank you for logging in.</strong>&nbsp;&nbsp;And don't forget
          to save your search to get the new listings you want emailed daily.
        </p>
      </div>
      <div className="modal-footer">
        <button type="button" className="si-btn si-btn--default js-modal-close">
          Close
        </button>
      </div>
    </div>
  )
}

export default AuthSigninSuccessView
