const minPriceSelectData = [
  { value: '', label: 'Min Price' },
  { value: '50000', label: '$50,000' },
  { value: '60000', label: '$60,000' },
  { value: '70000', label: '$70,000' },
  { value: '80000', label: '$80,000' },
  { value: '90000', label: '$90,000' },
  { value: '100000', label: '$100,000' },
  { value: '125000', label: '$125,000' },
  { value: '150000', label: '$150,000' },
  { value: '175000', label: '$175,000' },
  { value: '200000', label: '$200,000' },
  { value: '225000', label: '$225,000' },
  { value: '250000', label: '$250,000' },
  { value: '275000', label: '$275,000' },
  { value: '300000', label: '$300,000' },
  { value: '325000', label: '$325,000' },
  { value: '350000', label: '$350,000' },
  { value: '375000', label: '$375,000' },
  { value: '400000', label: '$400,000' },
  { value: '425000', label: '$425,000' },
  { value: '450000', label: '$450,000' },
  { value: '475000', label: '$475,000' },
  { value: '500000', label: '$500,000' },
  { value: '525000', label: '$525,000' },
  { value: '550000', label: '$550,000' },
  { value: '575000', label: '$575,000' },
  { value: '600000', label: '$600,000' },
  { value: '650000', label: '$650,000' },
  { value: '700000', label: '$700,000' },
  { value: '750000', label: '$750,000' },
  { value: '800000', label: '$800,000' },
  { value: '850000', label: '$850,000' },
  { value: '900000', label: '$900,000' },
  { value: '950000', label: '$950,000' },
  { value: '1000000', label: '$1,000,000' },
  { value: '1250000', label: '$1,250,000' },
  { value: '1500000', label: '$1,500,000' },
  { value: '1750000', label: '$1,750,000' },
  { value: '2000000', label: '$2,000,000' },
  { value: '2250000', label: '$2,250,000' },
  { value: '2500000', label: '$2,500,000' },
  { value: '2750000', label: '$2,750,000' },
  { value: '3000000', label: '$3,000,000' },
  { value: '3250000', label: '$3,250,000' },
  { value: '3500000', label: '$3,500,000' },
  { value: '3750000', label: '$3,750,000' },
  { value: '4000000', label: '$4,000,000' },
  { value: '4250000', label: '$4,250,000' },
  { value: '4500000', label: '$4,500,000' },
  { value: '4750000', label: '$4,750,000' },
  { value: '5000000', label: '$5,000,000' },
  { value: '5500000', label: '$5,500,000' },
  { value: '6000000', label: '$6,000,000' },
  { value: '6500000', label: '$6,500,000' },
  { value: '7000000', label: '$7,000,000' },
  { value: '7500000', label: '$7,500,000' },
  { value: '8000000', label: '$8,000,000' },
  { value: '8500000', label: '$8,500,000' },
  { value: '9000000', label: '$9,000,000' },
  { value: '9500000', label: '$9,500,000' },
  { value: '10000000', label: '$10,000,000' },
  { value: '11000000', label: '$11,000,000' },
  { value: '12000000', label: '$12,000,000' },
  { value: '13000000', label: '$13,000,000' },
  { value: '14000000', label: '$14,000,000' },
  { value: '15000000', label: '$15,000,000' },
  { value: '16000000', label: '$16,000,000' },
  { value: '17000000', label: '$17,000,000' },
  { value: '18000000', label: '$18,000,000' },
  { value: '19000000', label: '$19,000,000' },
  { value: '20000000', label: '$20,000,000' },
]

const maxPriceSelectData = [
  { value: '', label: 'Max Price' },
  { value: '50000', label: '$50,000' },
  { value: '60000', label: '$60,000' },
  { value: '70000', label: '$70,000' },
  { value: '80000', label: '$80,000' },
  { value: '90000', label: '$90,000' },
  { value: '100000', label: '$100,000' },
  { value: '125000', label: '$125,000' },
  { value: '150000', label: '$150,000' },
  { value: '175000', label: '$175,000' },
  { value: '200000', label: '$200,000' },
  { value: '225000', label: '$225,000' },
  { value: '250000', label: '$250,000' },
  { value: '275000', label: '$275,000' },
  { value: '300000', label: '$300,000' },
  { value: '325000', label: '$325,000' },
  { value: '350000', label: '$350,000' },
  { value: '375000', label: '$375,000' },
  { value: '400000', label: '$400,000' },
  { value: '425000', label: '$425,000' },
  { value: '450000', label: '$450,000' },
  { value: '475000', label: '$475,000' },
  { value: '500000', label: '$500,000' },
  { value: '525000', label: '$525,000' },
  { value: '550000', label: '$550,000' },
  { value: '575000', label: '$575,000' },
  { value: '600000', label: '$600,000' },
  { value: '650000', label: '$650,000' },
  { value: '700000', label: '$700,000' },
  { value: '750000', label: '$750,000' },
  { value: '800000', label: '$800,000' },
  { value: '850000', label: '$850,000' },
  { value: '900000', label: '$900,000' },
  { value: '950000', label: '$950,000' },
  { value: '1000000', label: '$1,000,000' },
  { value: '1250000', label: '$1,250,000' },
  { value: '1500000', label: '$1,500,000' },
  { value: '1750000', label: '$1,750,000' },
  { value: '2000000', label: '$2,000,000' },
  { value: '2250000', label: '$2,250,000' },
  { value: '2500000', label: '$2,500,000' },
  { value: '2750000', label: '$2,750,000' },
  { value: '3000000', label: '$3,000,000' },
  { value: '3250000', label: '$3,250,000' },
  { value: '3500000', label: '$3,500,000' },
  { value: '3750000', label: '$3,750,000' },
  { value: '4000000', label: '$4,000,000' },
  { value: '4250000', label: '$4,250,000' },
  { value: '4500000', label: '$4,500,000' },
  { value: '4750000', label: '$4,750,000' },
  { value: '5000000', label: '$5,000,000' },
  { value: '5500000', label: '$5,500,000' },
  { value: '6000000', label: '$6,000,000' },
  { value: '6500000', label: '$6,500,000' },
  { value: '7000000', label: '$7,000,000' },
  { value: '7500000', label: '$7,500,000' },
  { value: '8000000', label: '$8,000,000' },
  { value: '8500000', label: '$8,500,000' },
  { value: '9000000', label: '$9,000,000' },
  { value: '9500000', label: '$9,500,000' },
  { value: '10000000', label: '$10,000,000' },
  { value: '11000000', label: '$11,000,000' },
  { value: '12000000', label: '$12,000,000' },
  { value: '13000000', label: '$13,000,000' },
  { value: '14000000', label: '$14,000,000' },
  { value: '15000000', label: '$15,000,000' },
  { value: '16000000', label: '$16,000,000' },
  { value: '17000000', label: '$17,000,000' },
  { value: '18000000', label: '$18,000,000' },
  { value: '19000000', label: '$19,000,000' },
  { value: '20000000', label: '$20,000,000' },
]

const minSquareFeedSelectData = [
  { value: '', label: 'Min' },
  { value: '100', label: '100' },
  { value: '200', label: '200' },
  { value: '250', label: '250' },
  { value: '300', label: '300' },
  { value: '400', label: '400' },
  { value: '500', label: '500' },
  { value: '600', label: '600' },
  { value: '700', label: '700' },
  { value: '750', label: '750' },
  { value: '800', label: '800' },
  { value: '900', label: '900' },
  { value: '1000', label: '1000' },
  { value: '1100', label: '1100' },
  { value: '1200', label: '1200' },
  { value: '1250', label: '1250' },
  { value: '1300', label: '1300' },
  { value: '1400', label: '1400' },
  { value: '1500', label: '1500' },
  { value: '1600', label: '1600' },
  { value: '1700', label: '1700' },
  { value: '1750', label: '1750' },
  { value: '1800', label: '1800' },
  { value: '1900', label: '1900' },
  { value: '2000', label: '2000' },
  { value: '2100', label: '2100' },
  { value: '2200', label: '2200' },
  { value: '2250', label: '2250' },
  { value: '2300', label: '2300' },
  { value: '2400', label: '2400' },
  { value: '2500', label: '2500' },
  { value: '2600', label: '2600' },
  { value: '2700', label: '2700' },
  { value: '2750', label: '2750' },
  { value: '2800', label: '2800' },
  { value: '2900', label: '2900' },
  { value: '3000', label: '3000' },
  { value: '3250', label: '3250' },
  { value: '3500', label: '3500' },
  { value: '3750', label: '3750' },
  { value: '4000', label: '4000' },
  { value: '4250', label: '4250' },
  { value: '4500', label: '4500' },
  { value: '4750', label: '4750' },
  { value: '5000', label: '5000' },
  { value: '5500', label: '5500' },
  { value: '6000', label: '6000' },
  { value: '6500', label: '6500' },
  { value: '7000', label: '7000' },
  { value: '8000', label: '8000' },
  { value: '9000', label: '9000' },
  { value: '10000', label: '10000' },
  { value: '11000', label: '11000' },
  { value: '12000', label: '12000' },
  { value: '13000', label: '13000' },
  { value: '14000', label: '14000' },
  { value: '15000', label: '15000' },
]

const maxSquareFeedSelectData = [
  { value: '', label: 'Max' },
  { value: '100', label: '100' },
  { value: '200', label: '200' },
  { value: '250', label: '250' },
  { value: '300', label: '300' },
  { value: '400', label: '400' },
  { value: '500', label: '500' },
  { value: '600', label: '600' },
  { value: '700', label: '700' },
  { value: '750', label: '750' },
  { value: '800', label: '800' },
  { value: '900', label: '900' },
  { value: '1000', label: '1000' },
  { value: '1100', label: '1100' },
  { value: '1200', label: '1200' },
  { value: '1250', label: '1250' },
  { value: '1300', label: '1300' },
  { value: '1400', label: '1400' },
  { value: '1500', label: '1500' },
  { value: '1600', label: '1600' },
  { value: '1700', label: '1700' },
  { value: '1750', label: '1750' },
  { value: '1800', label: '1800' },
  { value: '1900', label: '1900' },
  { value: '2000', label: '2000' },
  { value: '2100', label: '2100' },
  { value: '2200', label: '2200' },
  { value: '2250', label: '2250' },
  { value: '2300', label: '2300' },
  { value: '2400', label: '2400' },
  { value: '2500', label: '2500' },
  { value: '2600', label: '2600' },
  { value: '2700', label: '2700' },
  { value: '2750', label: '2750' },
  { value: '2800', label: '2800' },
  { value: '2900', label: '2900' },
  { value: '3000', label: '3000' },
  { value: '3250', label: '3250' },
  { value: '3500', label: '3500' },
  { value: '3750', label: '3750' },
  { value: '4000', label: '4000' },
  { value: '4250', label: '4250' },
  { value: '4500', label: '4500' },
  { value: '4750', label: '4750' },
  { value: '5000', label: '5000' },
  { value: '5500', label: '5500' },
  { value: '6000', label: '6000' },
  { value: '6500', label: '6500' },
  { value: '7000', label: '7000' },
  { value: '8000', label: '8000' },
  { value: '9000', label: '9000' },
  { value: '10000', label: '10000' },
  { value: '11000', label: '11000' },
  { value: '12000', label: '12000' },
  { value: '13000', label: '13000' },
  { value: '14000', label: '14000' },
  { value: '15000', label: '15000' },
]

const minLotSize = [
  { value: '', label: 'Min' },
  { value: '0.1', label: '0.1' },
  { value: '0.15', label: '0.15' },
  { value: '0.2', label: '0.2' },
  { value: '0.25', label: '0.25' },
  { value: '0.3', label: '0.30' },
  { value: '0.35', label: '0.35' },
  { value: '0.4', label: '0.40' },
  { value: '0.45', label: '0.45' },
  { value: '0.5', label: '0.5' },
  { value: '0.75', label: '0.75' },
  { value: '1', label: '1' },
  { value: '1.5', label: '1.5' },
  { value: '2', label: '2' },
  { value: '2.5', label: '2.5' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '10', label: '10' },
  { value: '20', label: '20' },
  { value: '30', label: '30' },
  { value: '40', label: '40' },
  { value: '50', label: '50' },
  { value: '60', label: '60' },
  { value: '100', label: '100' },
  { value: '200', label: '200' },
  { value: '500', label: '500+' },
]

const maxLotSize = [
  { value: '', label: 'Max' },
  { value: '0.1', label: '0.1' },
  { value: '0.15', label: '0.15' },
  { value: '0.2', label: '0.2' },
  { value: '0.25', label: '0.25' },
  { value: '0.3', label: '0.30' },
  { value: '0.35', label: '0.35' },
  { value: '0.4', label: '0.40' },
  { value: '0.45', label: '0.45' },
  { value: '0.5', label: '0.5' },
  { value: '0.75', label: '0.75' },
  { value: '1', label: '1' },
  { value: '1.5', label: '1.5' },
  { value: '2', label: '2' },
  { value: '2.5', label: '2.5' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '10', label: '10' },
  { value: '20', label: '20' },
  { value: '30', label: '30' },
  { value: '40', label: '40' },
  { value: '50', label: '50' },
  { value: '60', label: '60' },
  { value: '100', label: '100' },
  { value: '200', label: '200' },
  { value: '500', label: '500+' },
]

const roomsSelectData = [
  { value: '', label: 'Any' },
  { value: '1', label: '1+' },
  { value: '2', label: '2+' },
  { value: '3', label: '3+' },
  { value: '4', label: '4+' },
  { value: '5', label: '5+' },
  { value: '6', label: '6+' },
]

const minYearBuildSelectData = [
  { value: '', label: 'Min' },
  { value: '2020', label: '2020' },
  { value: '2019', label: '2019' },
  { value: '2018', label: '2018' },
  { value: '2017', label: '2017' },
  { value: '2016', label: '2016' },
  { value: '2015', label: '2015' },
  { value: '2014', label: '2014' },
  { value: '2013', label: '2013' },
  { value: '2012', label: '2012' },
  { value: '2011', label: '2011' },
  { value: '2010', label: '2010' },
  { value: '2009', label: '2009' },
  { value: '2008', label: '2008' },
  { value: '2007', label: '2007' },
  { value: '2006', label: '2006' },
  { value: '2005', label: '2005' },
  { value: '2000', label: '2000' },
  { value: '1995', label: '1995' },
  { value: '1990', label: '1990' },
  { value: '1980', label: '1980' },
  { value: '1970', label: '1970' },
  { value: '1960', label: '1960' },
  { value: '1950', label: '1950' },
  { value: '1940', label: '1940' },
  { value: '1930', label: '1930' },
  { value: '1920', label: '1920' },
  { value: '1910', label: '1910' },
  { value: '1900', label: '1900' },
]

const maxYearBuildSelectData = [
  { value: '', label: 'Min' },
  { value: '2020', label: '2020' },
  { value: '2019', label: '2019' },
  { value: '2018', label: '2018' },
  { value: '2017', label: '2017' },
  { value: '2016', label: '2016' },
  { value: '2015', label: '2015' },
  { value: '2014', label: '2014' },
  { value: '2013', label: '2013' },
  { value: '2012', label: '2012' },
  { value: '2011', label: '2011' },
  { value: '2010', label: '2010' },
  { value: '2009', label: '2009' },
  { value: '2008', label: '2008' },
  { value: '2007', label: '2007' },
  { value: '2006', label: '2006' },
  { value: '2005', label: '2005' },
  { value: '2000', label: '2000' },
  { value: '1995', label: '1995' },
  { value: '1990', label: '1990' },
  { value: '1980', label: '1980' },
  { value: '1970', label: '1970' },
  { value: '1960', label: '1960' },
  { value: '1950', label: '1950' },
  { value: '1940', label: '1940' },
  { value: '1930', label: '1930' },
  { value: '1920', label: '1920' },
  { value: '1910', label: '1910' },
  { value: '1900', label: '1900' },
]

const minHoaFeeSelectData = [
  { value: '', label: 'Min' },
  { value: '25', label: '$25' },
  { value: '50', label: '$50' },
  { value: '75', label: '$75' },
  { value: '100', label: '$100' },
  { value: '150', label: '$150' },
  { value: '200', label: '$200' },
  { value: '250', label: '$250' },
  { value: '300', label: '$300' },
  { value: '350', label: '$350' },
  { value: '400', label: '$400' },
  { value: '450', label: '$450' },
  { value: '500', label: '$500' },
  { value: '550', label: '$550' },
  { value: '600', label: '$600' },
  { value: '650', label: '$650' },
  { value: '700', label: '$700' },
  { value: '750', label: '$750' },
  { value: '800', label: '$800' },
  { value: '850', label: '$850' },
  { value: '900', label: '$900' },
  { value: '950', label: '$950' },
  { value: '1000', label: '$1000' },
  { value: '1250', label: '$1250' },
  { value: '1500', label: '$1500' },
  { value: '1750', label: '$1750' },
  { value: '2000', label: '$2000' },
  { value: '2500', label: '$2500' },
  { value: '3000', label: '$3000' },
  { value: '3500', label: '$3500' },
  { value: '4000', label: '$4000' },
]

const maxHoaFeeSelectData = [
  { value: '', label: 'Min' },
  { value: '25', label: '$25' },
  { value: '50', label: '$50' },
  { value: '75', label: '$75' },
  { value: '100', label: '$100' },
  { value: '150', label: '$150' },
  { value: '200', label: '$200' },
  { value: '250', label: '$250' },
  { value: '300', label: '$300' },
  { value: '350', label: '$350' },
  { value: '400', label: '$400' },
  { value: '450', label: '$450' },
  { value: '500', label: '$500' },
  { value: '550', label: '$550' },
  { value: '600', label: '$600' },
  { value: '650', label: '$650' },
  { value: '700', label: '$700' },
  { value: '750', label: '$750' },
  { value: '800', label: '$800' },
  { value: '850', label: '$850' },
  { value: '900', label: '$900' },
  { value: '950', label: '$950' },
  { value: '1000', label: '$1000' },
  { value: '1250', label: '$1250' },
  { value: '1500', label: '$1500' },
  { value: '1750', label: '$1750' },
  { value: '2000', label: '$2000' },
  { value: '2500', label: '$2500' },
  { value: '3000', label: '$3000' },
  { value: '3500', label: '$3500' },
  { value: '4000', label: '$4000' },
]

const minGarageSpace = [
  { value: '', label: 'Any' },
  { value: '1', label: '1+' },
  { value: '2', label: '2+' },
  { value: '3', label: '3+' },
  { value: '4', label: '4+' },
]

export {
  minPriceSelectData,
  maxPriceSelectData,
  minSquareFeedSelectData,
  maxSquareFeedSelectData,
  minLotSize,
  maxLotSize,
  roomsSelectData,
  minYearBuildSelectData,
  maxYearBuildSelectData,
  minHoaFeeSelectData,
  maxHoaFeeSelectData,
  minGarageSpace,
}
