import React from 'react'
import Breadcrumb from '../../../common/Breadcrumb'
import Sidebar from '../../../common/sidebar/Sidebar'

const SplitCalculatorPage = () => {
  return (
    <>
      <div className={'si-site-container col-md-8 si-site-container--full'}>
        <Breadcrumb />
        <div className={'si-content-area'}>
          <p>
            <iframe
              src="https://joeneuman.com/agentcal/"
              style={{ border: '0px #ffffff none' }}
              width="600"
              height="800"
              scrolling="no"
              marginWidth="0px"
              marginHeight="0px"
              frameBorder="1"
              name="myiFrame"
              allowFullScreen=""
              title="Split calculator"
            ></iframe>
          </p>
        </div>
      </div>
      <Sidebar />
    </>
  )
}

export default SplitCalculatorPage
