import React from 'react'
import PropTypes from 'prop-types'

import MinMaxBlock from './Min-max-block'

import { minPriceSelectData, maxPriceSelectData } from '../inputs/select-data'

function PriceRange(props) {
  return (
    <MinMaxBlock
      name={'Price Range'}
      min={props.min}
      setMin={props.setMin}
      minSelectData={minPriceSelectData}
      max={props.max}
      setMax={props.setMax}
      maxSelectData={maxPriceSelectData}
    />
  )
}

PriceRange.propTypes = {
  min: PropTypes.string,
  setMin: PropTypes.func,
  max: PropTypes.string,
  setMax: PropTypes.func,
}

export default PriceRange
