import React from 'react'
import Breadcrumb from '../../../common/Breadcrumb'
import Sidebar from '../../../common/sidebar/Sidebar'

const PrivacyPolicyPage = () => {
  return (
    <>
      <div className={'si-site-container col-md-8 si-site-container--full'}>
        <Breadcrumb />
        <div className={'si-content-area'}>
          <p>
            <b>PRIVACY POLICY</b>
          </p>
          <p>
            <b>GiddyDigs.com</b>
          </p>
          <p>
            We understand that privacy online is important to users of our Site,
            especially when conducting business. This Privacy Policy governs our
            privacy policies with respect to those users of the Site
            (“Visitors,” “you” or “your”) who visit without transacting business
            and Visitors who register to transact business on the Site and make
            use of the various services offered by Giddy Digs (collectively,
            “Services”) (“Authorized Customers”). You are entering into a
            binding&nbsp;legal agreement with Giddy Digs, the website, located
            at GiddyDigs.com&nbsp;(the “Site”) and the third party service
            vendors who use the Site and their parents, subsidiaries and
            affiliates (the “Company,” “us,” “we” and “our”). This Privacy
            Policy is in effect for any web page, mobile application, email
            list, and information, including Personally Identifiable
            Information, collected/owned by us, regardless of the method of
            collection, including collection through any online features,
            services, and/or programs we offer. This Privacy Policy is not
            applicable to your use of any third party web page, mobile
            application, social media site, or any information collected or
            owned by any entity other than us.
          </p>
          <p>
            This Privacy Policy is incorporated by reference in our Terms of
            Service and by using the Site or the Services and/or submitting your
            information to search or create a user profile, you give express
            consent to all of the terms contained herein, as well as those
            provided in our Terms of Service, and constitutes your electronic
            signature. You may withdraw this consent by using the opt-out
            procedures described below.&nbsp;&nbsp;
          </p>
          <p>
            <b>Telephone Consumer Protection Act (“TCPA”)</b>
          </p>
          <p>
            EXPRESS WRITTEN CONSENT. BY SUBMITTING YOUR CONTACT INFORMATION, YOU
            ARE PROVIDING YOUR <b>EXPRESS WRITTEN CONSENT</b> TO RECEIVE
            COMMUNICATIONS FROM US AT THE EMAIL ADDRESS AND TELEPHONE NUMBERS
            YOU ENTERED INTO OUR CONTACT FORM, OR THAT YOU LATER PROVIDE TO US
            OR ENTER INTO YOUR CONTACT PAGE. YOUR CONSENT ALSO SERVES AS YOUR
            EXPRESS WRITTEN CONSENT TO YOUR PAST RECEIPT OF ELECTRONIC
            COMMUNICATIONS FROM US. YOU FURTHER REPRESENT AND WARRANT THAT: (I)
            YOU ARE AT LEAST 18 YEARS OLD; (II) YOU LIVE IN THE UNITED STATES
            (OR CANADA, IN SUCH CASE, THE CANADIAN CONSENTS BELOW SHALL APPLY);
            (III) YOU HAVE NOT REGISTERED ON A NATIONAL OR STATEWIDE DO NOT CALL
            LIST; (IV) YOU ARE THE ACCOUNT HOLDER OF, OR HAVE THE REQUIRED
            CONSENT TO PROVIDE, THE PHONE NUMBERS AND EMAIL ADDRESSES PROVIDED;
            (V) THE PHONE NUMBERS AND EMAIL ADDRESSES PROVIDED ARE ACCURATE AND
            YOU WILL PROVIDE US NOTICE IF YOU RELEASE THEM TO ANOTHER.
          </p>
          <p>
            COMMUNICATIONS INCLUDE. THESE COMMUNICATIONS MAY INCLUDE, WITHOUT
            LIMITATION, TELEMARKETING MESSAGES, THROUGH THE USE OF EMAIL,
            LANDLINE PHONE, FAX, CELLULAR PHONE, TEXT MESSAGES (INCLUDING SMS
            AND MMS), AND NOTIFICATIONS THROUGH ANY APP THAT WE PRODUCE.
          </p>
          <p>
            AUTODIALING. WE MAY USE AN AUTOMATIC TELEPHONE DIALING SYSTEM (OR
            “AUTO-DIALER”), WHICH MAY EMPLOY AN ARTIFICIAL OR PRE-RECORDED VOICE
            OR “ROBOTEXTS.” YOUR CARRIER’S STANDARD RATES AND CHARGES MAY APPLY.
          </p>
          <p>
            NO PURCHASE NECESSARY. AGREEING TO THESE COMMUNICATIONS IS NOT A
            CONDITION OF PURCHASING ANY PROPERTY, GOODS, OR SERVICES FROM US.
          </p>
          <p>
            REVOKING CONSENT AND OPTING OUT. YOU MAY REVOKE YOUR CONSENT TO
            RECEIVE CERTAIN COMMUNICATIONS AT ANY TIME. TO STOP RECEIVING TEXT
            MESSAGES REPLY "STOP" TO ANY OF OUR TEXTS.&nbsp; TO STOP RECEIVING
            EMAILS CLICK THE "UNSUBSCRIBE" LINK WHEN PROVIDED, OR REPLY
            "UNSUBSCRIBE" TO THE EMAIL WHEN AN AUTOMATED UNSUBSCRIBE LINK IS NOT
            AVAILABLE.&nbsp; TO STOP RECEIVING PHONE CALLS, COMMUNICATE THAT
            REQUEST TO US VIA PHONE, TEXT MESSAGE OR EMAIL. WE WILL MAKE A
            COMMERCIALLY REASONABLE EFFORT TO COMPLY WITH ANY COMMUNICATIONS
            FROM YOU OPTING OUT. BY CONTACTING US TO OPT-OUT OF A CERTAIN
            COMMUNICATION, YOU CONSENT TO RECEIVE A FINAL COMMUNICATION
            CONFIRMING YOUR OPT-OUT.&nbsp;&nbsp;
          </p>
          <p>
            COMMUNICATION FREQUENCY. HOW OFTEN WE SEND YOU COMMUNICATIONS WILL
            VARY, BECAUSE THE INDIVIDUAL SALESPERSON OR Realtors WHO
            COMMUNICATES WITH YOU WILL DETERMINE IT.
          </p>
          <p>
            COMMUNICATIONS PROVISIONS – CANADIAN RESIDENTS. IN ADDITION TO THE
            CONSENT PROVIDED ABOVE, CANADIAN RESIDENTS AGREE TO THE FOLLOWING
            COMPLIANCE PROVISIONS, WITH RESPECT TO CANADA’S ANTI-SPAM
            LEGISLATION, CANADA’S PERSONAL INFORMATION PROTECTION AND ELECTRONIC
            DOCUMENTS ACT AND CANADIAN PROVINCIAL LAW INCLUDING ALBERTA’
            PERSONAL INFORMATION PROTECTION ACT, AND QUEBEC’S ACT RESPECTING THE
            PROTECT OF PERSONAL INFORMATION IN THE PRIVATE SECTOR: (i) YOU AGREE
            TO THE PROVISIONS GOVERNING USE AND DISCLOSURE OF PERSONAL
            INFORMATION THAT ARE FOUND IN THIS PRIVACY POLICY; (ii) OUR
            COMMUNICATION WITH YOU SHALL CONTINUE UNTIL YOU OPT-OUT, WHICH
            INDICATES YOU ARE NO LONGER CONSIDERING REAL ESTATE OPPORTUNITIES OR
            THE SERVICES WE PROVIDE; AND (iii) YOUR PERSONAL INFORMATION MAY
            ALSO BE TRANSMITTED TO, USED IN, AND STORED IN THE US.
          </p>
          <p>
            <b>“Personally Identifiable Information”</b>
            <b></b>
          </p>
          <p>
            Refers to any information that identifies or can be used to
            identify, contact, or locate the person to whom such information
            pertains, including, but not limited to, name, address, phone
            number, fax number, email address, financial profiles, social
            security number, and credit card information. Personally
            Identifiable Information does not include information that is
            collected anonymously (that is, without identification of the
            individual user) or demographic information not connected to an
            identified individual.
          </p>
          <p>
            <strong>
              What Personally Identifiable Information is collected?
            </strong>
          </p>
          <p>
            We may collect basic user profile information from all of our
            Visitors. We collect the following additional information from our
            Authorized Customers: the names, addresses, phone numbers and email
            addresses of Authorized Customers.
          </p>
          <p>
            <strong>What organizations are collecting the information?</strong>
          </p>
          <p>
            In addition to our direct collection of information, our third party
            service vendors (such as mortgage lenders, insurance brokers, and
            title companies) who may provide such services as mortgage lending,
            mortgage insurance, escrow services and title insurance may collect
            this information from our Visitors and Authorized Customers. We do
            not control how these third parties use such information, but we do
            ask them to disclose how they use personal information provided to
            them from Visitors and Authorized Customers. Some of these third
            parties may be intermediaries that act solely as links in the
            distribution chain, and do not store, retain, or use the information
            given to them.
          </p>
          <p>
            <strong>
              How does the Site use Personally Identifiable Information?
            </strong>
          </p>
          <p>
            We use Personally Identifiable Information to customize the Site, to
            make appropriate service offerings, and to fulfill buying and
            selling requests on the Site. We may email Visitors and Authorized
            Customers about research or purchase and selling opportunities on
            the Site or information related to the subject matter of the Site.
            We may also use Personally Identifiable Information to contact
            Visitors and Authorized Customers in response to specific inquiries,
            or to provide requested information.
          </p>
          <p>
            <strong>With whom may the information may be shared?</strong>
          </p>
          <p>
            Personally Identifiable Information pertaining to Authorized
            Customers and other non-personally identifiable information will be
            shared with our employees and with third party service providers who
            assist us with our Site operations or other services we may offer.
            Personally Identifiable Information about Authorized may be shared
            with other Authorized Customers and other providers of real
            estate-related services, such as mortgage lenders, that we work
            with, who wish to evaluate potential transactions with other
            Authorized Customers and/or us. Further, we may share aggregated
            information about our Visitors, including the demographics of our
            Visitors and Authorized Customers, with our affiliated agencies and
            third party vendors. We also offer the opportunity to “opt out” of
            receiving information or being contacted by us or by any agency
            acting on our behalf. Our employees, agents, third party service
            providers and partners are contractually bound to use Personally
            Identifiable Information solely in connection with providing the
            Services, and may not use it for any other purpose.
          </p>
          <p>
            We may disclose information provided by Visitors as part of any
            merger, sale, acquisition, or financing of our company. Furthermore,
            in some instances, we may be legally required to provide information
            about Visitors to government authorities, including law enforcement,
            homeland security, and intelligence agencies. We cooperate with law
            enforcement in identifying persons using our Services for illegal
            activities and reserve the right to report any activities that we
            believe to be unlawful or in violation of our Terms and Conditions.
          </p>
          <p>
            <strong>How is Personally Identifiable Information stored?</strong>
          </p>
          <p>
            Personally Identifiable Information collected by&nbsp;Giddy Digs is
            securely stored in accordance with current industry standards and is
            not accessible to third parties or employees of&nbsp;Giddy Digs
            except for use as indicated above.
          </p>
          <p>
            <strong>
              What choices are available to Visitors regarding collection, use
              and distribution of the information?
            </strong>
          </p>
          <p>
            Visitors and Authorized Customers may opt out of receiving
            unsolicited information from or being contacted by us and/or our
            vendors and affiliated agencies by responding to emails as
            instructed, or by contacting us at:
          </p>
          <p>
            Giddy Digs
            <br />
            1031 S. Bluff Street
            <br />
            St. George UT 84770
            <br />
            Contact Email:&nbsp;
            <a href="mailto:Joe@GiddyDigs.com">Joe@GiddyDigs.com</a>
          </p>
          <p>
            <strong>Are Cookies Used on the Site?</strong>
          </p>
          <p>
            Cookies are used for a variety of reasons. We use Cookies to obtain
            information about the preferences of our Visitors and the services
            they select. We also use Cookies for security purposes to protect
            our Authorized Customers. For example, if an Authorized Customer
            registers for a free account after spending time using the site
            services, Cookies allow us to securely associate the Authorized
            Customer's search preferences with the newly created account.
          </p>
          <p>
            <strong>
              How does&nbsp;<span>Giddy Digs</span> use login information?
            </strong>
          </p>
          <p>
            Giddy Digs<b>&nbsp;</b>uses login information, including, but not
            limited to, IP addresses, ISPs, and browser types, to analyze
            trends, administer the Site, track a user’s movement and use, and
            gather broad demographic information.
          </p>
          <p>
            <strong>
              What partners or service providers have access to Personally
              Identifiable Information from Visitors and/or Authorized Customers
              on the Site?
            </strong>
          </p>
          <p>
            Giddy Digs<b>&nbsp;</b>has entered into and will continue to enter
            into partnerships and other affiliations with a number of vendors.
            Such vendors may have access to certain Personally Identifiable
            Information on a need to know basis for evaluating Authorized
            Customers for service eligibility. Our Privacy Policy does not cover
            their collection or use of this information or disclosure of
            Personally Identifiable Information to comply with law. We will
            disclose Personally Identifiable Information in order to comply with
            a court order or subpoena or a request from a law enforcement agency
            to release information. We will also disclose Personally
            Identifiable Information when reasonably necessary to protect the
            safety of our Visitors and Authorized Customers.
          </p>
          <p>
            <strong>
              How does the Site keep Personally Identifiable Information secure?
            </strong>
          </p>
          <p>
            All of our employees are familiar with our security policy and
            practices. The Personally Identifiable Information of our Visitors
            and Authorized Customers is only accessible to a limited number of
            qualified employees who are given a password in order to gain access
            to the information. We audit our security systems and processes on a
            regular basis. Sensitive information is protected by encryption
            protocols, in place to protect information sent over the Internet.
            While we take commercially reasonable measures to maintain a secure
            site, electronic communications and databases are subject to errors,
            tampering and break-ins, and we cannot guarantee or warrant that
            such events will not take place and we will not be liable to
            Visitors or Authorized Customers for any such occurrences.
          </p>
          <p>
            <strong>
              How can Visitors correct any inaccuracies in Personally
              Identifiable Information?
            </strong>
          </p>
          <p>
            Visitors and Authorized Customers may contact us to update
            Personally Identifiable Information about them or to correct any
            inaccuracies by emailing us at{' '}
            <a href="mailto:Joe@GiddyDigs.com">Joe@GiddyDigs.com</a>. We
            encourage Visitors and Authorized Customers to keep Personally
            Identifiable Information up to date at all times. With that said,
            changes and updates to Personally Identifiable Information will only
            be reflected going forward and we cannot alter any information we
            may have provided to a third party
          </p>
          <p>
            <strong>
              Can a Visitor delete or deactivate Personally Identifiable
              Information collected by the Site?
            </strong>
          </p>
          <p>
            We provide Visitors and Authorized Customers with a mechanism to
            delete/deactivate Personally Identifiable Information from the
            Site’s database by contacting us via the information provided in
            this Privacy Policy.. However, because of backups and records of
            deletions, it may be impossible to delete a Visitor’s entry without
            retaining some residual information. An individual who requests to
            have Personally Identifiable Information deactivated will have this
            information functionally deleted, and we will not sell, transfer, or
            use Personally Identifiable Information relating to that individual
            in any way moving forward.
          </p>
          <p>
            <strong>California Users’ Rights</strong>
          </p>
          <p>
            We provide the following disclosures pursuant to Cal. Bus. &amp;
            Prof. Code § 22575.
          </p>
          <p>
            We provide Visitors and Authorized Customers, including those
            claiming California residency, with information on how to exercise
            their respective disclosure options and choices pertaining to
            Personally Identifiable Information, such as the right to opt-out or
            unsubscribe, or opt-in for use of Personally Identifiable
            Information by third parties for marketing purposes. Accordingly,
            pursuant to the California Civil Code, we are not required to
            maintain or disclose a list of the third parties that have received
            Personally Identifiable Information for marketing purposes during
            the preceding year.
          </p>
          <p>
            California residents wishing to request information about how to
            exercise their disclosure options and choices pertaining to third
            party disclosures, please send request by mail to “Request for
            California Disclosure Choices” at the mailing address provided
            above. With all request, please include your name, street address,
            city, state, zip code and e-mail address. We do not accept requests
            via telephone or fax. We are not responsible for failure to respond
            to incomplete, incorrectly labeled or submitted notices.
          </p>
          <p>
            We do not obey Do Not Track signals from a user’s Internet browser.
            We make no representations concerning third parties that do not
            collect Personally Identifiable Information directly through our
            Site.
          </p>
          <p>
            <strong>Minors</strong>
          </p>
          <p>
            Our Site is not intended for use by minors and as such, no one under
            the age of 13 should use, or provide any personal information to,
            our Site. If we discover that we have mistakenly collected personal
            information from a child under the age of 13, we will delete that
            information as soon as possible.
          </p>
          <p>
            <strong>Links</strong>
          </p>
          <p>
            The Site contains links to other web sites. Please note that when
            you click on one of these links, you are moving to another web site.
            We encourage you to read the privacy statements of these linked
            sites as their privacy policies may differ from ours.
          </p>
          <p>
            <strong>Governing Law</strong>
          </p>
          <p>
            By visiting the Site, you agree that the laws of the Commonwealth of
            Kentucky, without regard to principles of conflict of laws, shall
            govern this Privacy Policy and any dispute or any sort that may
            arise between you and our company, or our affiliates. <b></b>
          </p>
          <p>
            <strong>What happens if the Privacy Policy Changes?</strong>
          </p>
          <p>
            We reserve the right to modify or amend this Privacy Policy at any
            time by posting the revised Privacy Policy. We will let our Visitors
            and Authorized Customers know about changes to our Privacy Policy by
            posting such changes on the Site.
          </p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </div>
      </div>
      <Sidebar />
    </>
  )
}

export default PrivacyPolicyPage
