import React from 'react'
import communities from '../../../database/comunities'
import { useParams, Redirect } from 'react-router-dom'
import Breadcrumb from '../../common/Breadcrumb'
import Sidebar from '../../common/sidebar/Sidebar'

const Community = () => {
  const { alias } = useParams()
  const currentCommunity = communities.find(
    (community) => community.alias === alias
  )

  if (currentCommunity === undefined) {
    return <Redirect to="/communities/" />
  }

  return (
    <>
      <div className={'si-site-container col-md-8 si-site-container--full'}>
        <Breadcrumb />
        <div className="si-content-area">
          <h1>{currentCommunity.name} Homes for Sale</h1>
          <p>
            Included below are{' '}
            <strong>homes for sale in {currentCommunity.name}.</strong> If you
            would like more information on any of these{' '}
            <strong>{currentCommunity.name} real estate</strong> listings, just
            click the "Request More Information" button when viewing the details
            of that property. We can provide you with disclosures, past sales
            history, dates and prices of homes recently sold nearby, and more.
          </p>
          <p>
            And, if you haven't already, be sure to{' '}
            <a
              href="/property-search/property-tracker/"
              title="Email Listing Alerts"
            >
              register for a free account
            </a>{' '}
            so that you can receive email alerts whenever new{' '}
            {currentCommunity.name} listings come on the market.
          </p>
        </div>{' '}
      </div>
      <Sidebar />
    </>
  )
}

export default Community
