import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import InputCustomSelect from '../../../property-search/form/inputs/Input-custom-select'

import { formatMoney } from '../../../../../helpers/text-helpers'
import { anuitetMonthlyPayment } from '../../../../../helpers/calc-helpers'
const termSelectData = [
  { value: '30', label: '30 Years' },
  { value: '25', label: '25 Years' },
  { value: '20', label: '20 Years' },
  { value: '15', label: '15 Years' },
  { value: '10', label: '10 Years' },
  { value: '5', label: '5 Years' },
]
function MortgageCalculator({ price }) {
  const [localCalculatorPrice, setLocalCalculatorPrice] = useState(price)
  const localCalculatorPriceOnChangeHandler = (evt) => {
    const newLocalCalculatorPrice = evt.target.value.split(',').join('')
    setLocalCalculatorPrice(newLocalCalculatorPrice)
  }
  const [downPaymentPerCent, setDownPaymentPerCent] = useState(20)

  const downPaymentPerCentOnChangeHandler = (evt) => {
    let newDownPaymentPerCent = +evt.target.value
    if (newDownPaymentPerCent > 100) {
      newDownPaymentPerCent = 100
    } else if (newDownPaymentPerCent < 0) {
      newDownPaymentPerCent = 0
    }
    setDownPaymentPerCent(newDownPaymentPerCent)
  }
  const [loanTerm, setLoanTerm] = useState('30')
  const [barPerCent, setBarPerCent] = useState(20)
  const [ratePerCent, setRatePerCent] = useState(3.3)
  const ratePerCentOnChangeHandler = (evt) => {
    setRatePerCent(evt.target.value)
  }
  useEffect(() => {
    setBarPerCent(100 - downPaymentPerCent)
    return () => {
      // no clinup for this Effect
    }
  }, [downPaymentPerCent])
  const [monthlyPayment, setMonthlyPayment] = useState(0)
  useEffect(() => {
    const loanSumm = (+localCalculatorPrice / 100) * (100 - downPaymentPerCent)
    setMonthlyPayment(anuitetMonthlyPayment(loanSumm, +ratePerCent, +loanTerm))
    return () => {
      // no clinup for this Effect
    }
  }, [localCalculatorPrice, ratePerCent, loanTerm, downPaymentPerCent])

  return (
    <section className="si-ld-calculator is-collapse js-collapsible js-ld-mortgage-calculator">
      <h2>Mortgage Calculator</h2>
      <div className="si-row">
        <div className="si-form__column--left">
          <div className="form-group si-form__column">
            <label className="control-label" htmlFor="ldmcPrice">
              Price <span className="dash dash-price"></span>
            </label>
            <input
              type="text"
              name="price"
              id="ldmcPrice"
              value={formatMoney(localCalculatorPrice, 0)}
              className="form-control"
              onChange={localCalculatorPriceOnChangeHandler}
            />
          </div>
          <div
            className="form-group si-form__column"
            style={{ whiteSpace: 'nowrap' }}
          >
            <label className="control-label" htmlFor="ldmcDownPaymentPercent">
              Down Payment (%) <span className="dash dash-dp"></span>
            </label>
            <input
              type="text"
              name="downpaymentpercent"
              id="ldmcDownPaymentPercent"
              value={downPaymentPerCent}
              className="form-control"
              onChange={downPaymentPerCentOnChangeHandler}
            />
          </div>
          <div className="form-group si-form__column">
            <label className="control-label" htmlFor="ldmcLoanTerm">
              Term
            </label>
            <InputCustomSelect
              selectData={termSelectData}
              value={loanTerm}
              onChange={setLoanTerm}
            />
          </div>
          <div className="form-group si-form__column">
            <label className="control-label" htmlFor="ldmcLoanRate">
              Rate (%)
            </label>
            <input
              type="text"
              name="loanrate"
              id="ldmcLoanRate"
              value={ratePerCent}
              className="form-control"
              onChange={ratePerCentOnChangeHandler}
            />
          </div>
        </div>
        <div className="si-form__column--right">
          <div className="si-ld-calculator__bar">
            <div className="price">
              ${formatMoney(monthlyPayment, 0)} <span>Monthly Payment</span>
            </div>
            <div>Loan and interest only. Taxes and insurance not included.</div>
            <div className="bar">
              <span style={{ width: `${barPerCent}%` }}></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
MortgageCalculator.propTypes = {
  price: PropTypes.number.isRequired,
}
export default MortgageCalculator
