import React, { useState } from 'react'
import SheduleATour from '../../../forms/details/Shedule-a-tour'
import AskAQuestion from '../../../forms/details/Ask-a-question'

function ContactBox() {
  const [sheduleATourFormIsShown, setSheduleATourFormIsShown] = useState(false)
  const [askAQuestionFormIsShown, setAskAQuestionFormIsShown] = useState(false)
  const ScheduleATourButtonClickHandler = (evt) => {
    evt.preventDefault()
    setSheduleATourFormIsShown(true)
  }
  const AskAQuestionButtonClickHandler = (evt) => {
    evt.preventDefault()
    setAskAQuestionFormIsShown(true)
  }
  return (
    <div className="si-ld-contact-wrap js-contact-box" style={{ top: '0px' }}>
      <div className="si-ld-contact-wrap-in container-ld">
        <div className="si-ld-contact">
          <div className="row">
            <div className="col-xs-6 col-sm-7 col-md-12 si-ld-contact__agent">
              <div className="si-ld-contact__name js-ld-contact">
                <div className="si-ld-contact__img js-contact-image">
                  <img src="/images/userfiles/joeneuman.jpg" alt="Joe Neuman" />
                  <span>
                    <i className="fa fa-info-circle"></i>
                  </span>
                </div>
                <div className="si-ld-contact__info">
                  <h3>Joe Neuman</h3>
                  <a
                    href="tel:+14352558888"
                    className="si-phone-link"
                    rel="nofollow"
                  >
                    (435) 255-8888
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xs-6 col-sm-5 col-md-12 si-ld-contact__actions">
              <div className="hidden-sm hidden-xs">Ready to go see it?</div>
              <a
                href="tel:+14352558888"
                rel="nofollow"
                className="si-btn si-btn--secondary"
                onClick={ScheduleATourButtonClickHandler}
              >
                Schedule <span>a </span>Tour
              </a>
              <hr className="hidden-sm hidden-xs" />

              <div className="hidden-sm hidden-xs">Need more info?</div>
              <a
                href="/"
                rel="nofollow"
                className="si-btn si-btn--tertiary"
                onClick={AskAQuestionButtonClickHandler}
              >
                Ask a Question
              </a>
            </div>
          </div>
        </div>
      </div>
      {sheduleATourFormIsShown ? (
        <SheduleATour closeFunction={() => setSheduleATourFormIsShown(false)} />
      ) : (
        ''
      )}
      {askAQuestionFormIsShown ? (
        <AskAQuestion closeFunction={() => setAskAQuestionFormIsShown(false)} />
      ) : (
        ''
      )}
    </div>
  )
}

export default ContactBox
