import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import InputCustomSelect from '../inputs/Input-custom-select'
import InputAutocomplete from '../inputs/input-autocomplite'
import SelectedItems from './search-by-location-types/Selected-items'

import ConfirmDialog from '../../../Confirm-dialog'

import { locationTypes } from './search-by-location-types/location-types'
import {
  zipCodes,
  cities,
  counties,
} from './search-by-location-types/search-toltips-data'

import './search-by-location-types/search-by-location-type.css'

const SearchByLocationType = (props) => {
  const [currentLocationType, setCurrentLocationType] = useState('0')
  const [previousLocationType, setPreviousLocationType] = useState('0')

  const changeCurretnLocationType = (newLocationType) => {
    setCurrentLocationType((prevLocationType) => {
      setPreviousLocationType(prevLocationType)
      return newLocationType
    })
  }
  const [userInput, setUserInput] = useState('')
  const [
    suggestions,
    // setSuggestions
  ] = useState([cities, [], counties, [], [], [], [], zipCodes])

  const selectedItemsDeleteHandler = (evt) => {
    const newCities = props.selectedItems[evt.target.dataset.type].filter(
      (el, index) => {
        return index !== +evt.target.dataset.index
      }
    )
    props.setSelectedItems({
      ...props.selectedItems,
      [evt.target.dataset.type]: newCities,
    })
  }
  const inputConfirmSave = (userInput) => {
    const locationTypeKey = Object.keys(props.selectedItems)[
      currentLocationType
    ]
    const newSelectedItems = [
      ...new Set(props.selectedItems[locationTypeKey].concat([userInput])),
    ]
    props.setSelectedItems({
      ...props.selectedItems,
      [locationTypeKey]: newSelectedItems,
    })
  }
  useEffect(() => {
    const selectedItemsCount = Object.keys(props.selectedItems).filter(
      (key) => props.selectedItems[key].length > 0
    )
    if (
      previousLocationType !== currentLocationType &&
      selectedItemsCount.length > 0
    ) {
      setIsConfirmDialogShown(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocationType])

  useEffect(() => {}, [userInput])
  const placeholder = `Enter one or more ${locationTypes[currentLocationType].placeholder} for search`
  const locationTypeName = locationTypes.find(
    (element) => element.value === currentLocationType
  ).label
  const prevLocationTypeName = locationTypes.find(
    (element) => element.value === previousLocationType
  ).label

  // confirmation dialog
  const confirmDialogTitle = `Search by ${locationTypeName}`
  const confirmDialogText = `Selecting a ${locationTypeName} will remove the ${prevLocationTypeName} you have already selected.(You can only search by one geographic category at a time.)`
  const confirmDialogQuestion = `Continue? `
  const [isConfirmDialogShown, setIsConfirmDialogShown] = useState(false)
  const [userAnswer, setUserAnswer] = useState('no answer')
  useEffect(() => {
    if (userAnswer !== 'no answer') {
      if (userAnswer === false) {
        setCurrentLocationType(previousLocationType)
      } else if (userAnswer === true) {
        const newSelectedItems = { ...props.selectedItems }
        Object.keys(newSelectedItems).forEach((key) => {
          newSelectedItems[key] = []
        })
        props.setSelectedItems(newSelectedItems)
      }
      setUserAnswer('no answer')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAnswer, previousLocationType])
  const userConfirmationHandler = (isConfirm) => {
    setUserAnswer(isConfirm)
    setIsConfirmDialogShown(false)
  }
  return (
    <section className="si-sf-section">
      {/*
        <div
          style={{ height: '200px', display: 'block', overflow: 'auto' }}
          dangerouslySetInnerHTML={{
            __html: suggestions[currentLocationType].join('<br>'),
          }}
        ></div>
        
      <hr />*/}
      <h2>Search by Location Type</h2>
      <div className="si-row si-sf-location">
        <InputCustomSelect
          value={currentLocationType}
          onChange={changeCurretnLocationType}
          selectData={locationTypes}
          className="si-sf-location__select"
        />
        <div className="si-sf-location__input js-location-input-container">
          <InputAutocomplete
            onChange={setUserInput}
            suggestions={suggestions[currentLocationType]}
            placeholder={placeholder}
            locationTypeName={locationTypeName}
            onInputConfirm={inputConfirmSave}
          />
        </div>
        <div className="si-sf-location__selected si-selected-items js-selected-location-container">
          {Object.keys(props.selectedItems).map((locationType, index) => (
            <SelectedItems
              key={index}
              items={props.selectedItems[locationType]}
              locationType={locationType}
              onDelete={selectedItemsDeleteHandler}
            />
          ))}
        </div>
      </div>
      {isConfirmDialogShown}
      {isConfirmDialogShown ? (
        <ConfirmDialog
          title={confirmDialogTitle}
          text={confirmDialogText}
          question={confirmDialogQuestion}
          onAnswer={userConfirmationHandler}
        />
      ) : (
        ''
      )}
    </section>
  )
}

SearchByLocationType.propTypes = {
  selectedItems: PropTypes.object,
  setSelectedItems: PropTypes.func,
}
export default SearchByLocationType
