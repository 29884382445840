import React from 'react'
import Breadcrumb from '../../../common/Breadcrumb'
import Sidebar from '../../../common/sidebar/Sidebar'

const spanStyle = {
  fontSize: '21px',
}

const buttonStyle = {
  backgroundColor: '#1c3144',
  border: '2px solid #0a3c59',
  color: 'white',
  padding: '10px',
  textAlign: 'center',
  textDecoration: 'none',
  display: 'inline-block',
  fontSize: '26px',
  margin: '2px 2px',
  cursor: 'pointer',
  borderRadius: '6px',
}

const RealEstateAgentPage = () => {
  return (
    <>
      <div className={'si-site-container col-md-8 si-site-container--full'}>
        <Breadcrumb />
        <div className={'si-content-area'}>
          <h1>Why Giddy Digs</h1>
          <p>
            <span style={spanStyle}>
              Giddy Digs is an independent brokerage. This is important because
              as a small nimble company the big box brokerages just can not keep
              up with us, especially when it comes to technology and automation.
              If we need to, we can change everything overnight.&nbsp;
            </span>
            <br />
            <br />
            <span style={spanStyle}>
              As an agent with Giddy Digs you get the following:
            </span>
          </p>
          <p>
            <span style={spanStyle}>
              <b style={spanStyle}>Full </b>
              <span style={spanStyle}>
                <b>Featured</b>
              </span>
              <b style={spanStyle}>&nbsp;CRM:&nbsp;</b>
              <span style={spanStyle}>
                The best lead generation CRM in the industry. With some of the
                options listed below.&nbsp;
              </span>
            </span>
          </p>
          <p>
            <span style={spanStyle}>
              <b>Auto Dialer:</b> featuring call recording of incoming and
              outgoing calls from your computer or cellphone.
            </span>
          </p>
          <p>
            <span style={spanStyle}>
              <b>Automatic Action Plans:</b> Includes text messaging with mail
              merge and voicemail drops.
            </span>
          </p>
          <p>
            <span style={spanStyle}>
              <b>Set up of Automatic Prospecting:</b>&nbsp;We will create
              Facebook custom audiences, with your current databases, to keep
              you in front of them all over the web.&nbsp; Monthly newsletters
              that we go out to your top soi members because&nbsp;we realize
              that up to 90% of a good agents business comes from past clients
              and referrals.&nbsp;
            </span>
            <br />
            <br />
            <span style={spanStyle}>
              <b>Leads :</b>&nbsp;For every two transactions an agent closes
              they are eligible for a fully qualified lead from Giddy
              Digs.&nbsp;
            </span>
          </p>
          <p>
            <span style={spanStyle}>
              I know what your thinking, "We must want 30% of your commissions",
              Nope, we are actually the lowest in the industry.
            </span>
            <br />
            <br />
            <span style={spanStyle}>
              We let you create your own commission split.&nbsp;
            </span>
            <br />
            <span style={spanStyle}>
              <a
                href="/about/split-calculator/"
                className={'button'}
                style={buttonStyle}
              >
                Split Calculator
              </a>
            </span>
          </p>
          <p dir="ltr">
            <span style={spanStyle}>
              <strong>Step 1</strong>
            </span>
          </p>
          <p dir="ltr">
            <span style={spanStyle}>
              If you have questions visit{' '}
              <a href="https://www.giddydigs.com/contact/appt/">
                giddydigs.com/contact/appt/
              </a>
              &nbsp;and make an appointment to meet with Joe.{' '}
            </span>
            <br />
            <span style={spanStyle}>
              Or call 435-255-8888. If you are ready to move forward, go to step
              two.
            </span>
          </p>
          <p dir="ltr">
            <span style={spanStyle}>
              <strong>Step 2</strong>
            </span>
          </p>
          <p dir="ltr">
            <span style={spanStyle}>
              Log into RELMS{' '}
              <a
                href="https://secure.utah.gov/account/log-in.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://secure.utah.gov/account/log-in.html
              </a>{' '}
              and request that your &nbsp;license be transferred.
            </span>
          </p>
          <p>
            <span style={spanStyle}>
              <img src="/images/userfiles/relms.gif" alt="" />
            </span>
          </p>
          <p dir="ltr">
            <span style={spanStyle}>
              Now let Giddy Digs and your current broker know that you have
              submitted a request to have your license transfered. It won’t be
              transferred until both parties have approved it.
            </span>
          </p>
        </div>
      </div>
      <Sidebar />
    </>
  )
}

export default RealEstateAgentPage
