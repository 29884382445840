import React from 'react'
import communities from '../../../database/comunities'
import { Link } from 'react-router-dom'

const FeaturedCommunities = () => {
  return (
    <div className="box link-effect">
      <div className="title">Featured Communities</div>
      <div className="box-content">
        <ul className="two-col">
          {communities.map((featuredCity, index) => (
            <li key={index} className="si-content-label__link">
              <Link
                to={`/communities/${featuredCity.alias}/`}
                title={featuredCity.name}
              >
                {featuredCity.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default FeaturedCommunities
