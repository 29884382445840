import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { formatDurationShort } from '../../../../helpers/time-helpers'

function TimeCounter({ timestamp, OnMarketDate }) {
  const time = new Date(timestamp).getTime()
  const onMarketTime = new Date(OnMarketDate).getTime()
  const countSecondsOnSite = () => {
    const twoDays = 2 * 24 * 60 * 60
    const seconds = Math.floor((new Date().getTime() - time) / 1000)
    if (seconds < twoDays) {
      return seconds
    } else {
      const secondsFromOnMarketDate = Math.floor(
        (new Date().getTime() - onMarketTime) / 1000
      )
      return secondsFromOnMarketDate
    }
  }

  const [secondsOnSite, setSecondsOnSite] = useState(countSecondsOnSite())
  const oneSecondPeriod = 1000
  const oneHourPeriod = 3600 * 1000
  const [updateTimePeriod] = useState(
    Math.floor((new Date().getTime() - time) / 1000) > 3600
      ? oneHourPeriod
      : oneSecondPeriod
  )
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSecondsOnSite(countSecondsOnSite())
    }, updateTimePeriod)
    return () => {
      clearTimeout(timeoutId)
    }
  })

  return <>{formatDurationShort(secondsOnSite)} </>
}

TimeCounter.propTypes = {
  timestamp: PropTypes.string,
}

export default TimeCounter
