const propertyFeaturesSelectData = [
  { value: '', label: 'Property Features' },
  { value: '1', label: 'Deck' },
  { value: '2', label: 'Fireplace' },
  { value: '3', label: 'Horse Privileges' },
  { value: '4', label: 'Hot Tub' },
  { value: '5', label: 'Patio' },
  { value: '6', label: 'Skylight' },
  { value: '7', label: 'Tennis Courts' },
  { value: '8', label: 'Vaulted' },
  { value: '9', label: 'Walk-in Closet(s)' },
]

const landDescriptionSelectData = [
  { value: '', label: 'Land Description' },
  { value: '7323', label: "Add'l Land Avail" },
  { value: '7324', label: 'Auto-Full' },
  { value: '7325', label: 'Auto-Part' },
  { value: '7326', label: 'City View' },
  { value: '7327', label: 'Corner Lot' },
  { value: '7328', label: 'Cul-de-sac' },
  { value: '7329', label: 'Curbs &amp; Gutters' },
  { value: '7330', label: 'Fenced Full' },
  { value: '7331', label: 'Fenced Partial' },
  { value: '7332', label: 'Flat' },
  { value: '7333', label: 'Grad Slope' },
  { value: '7334', label: 'Hilly' },
  { value: '7335', label: 'Lake' },
  { value: '7336', label: 'Mountain' },
  { value: '7337', label: 'On Golf Course' },
  { value: '7338', label: 'Paved Road' },
  { value: '7339', label: 'Secluded Yard' },
  { value: '7340', label: 'See Remarks' },
  { value: '7341', label: 'Sidewalk' },
  { value: '7342', label: 'Sidewalks' },
  { value: '7343', label: 'Sprinkler' },
  { value: '7344', label: 'Steep Slope' },
  { value: '7345', label: 'Terrain' },
  { value: '7346', label: 'Unpaved Road' },
  { value: '7347', label: 'Valley' },
  { value: '7348', label: 'View' },
  { value: '7349', label: 'View Golf Course' },
  { value: '7350', label: 'Wooded' },
]

const garageParkingSelectData = [
  { value: '', label: 'Garage / Parking' },
  { value: '7361', label: 'Attached' },
  { value: '7362', label: 'Detached' },
  { value: '7363', label: 'Extra Depth' },
  { value: '7364', label: 'Extra Height' },
  { value: '7365', label: 'Extra Width' },
  { value: '7366', label: 'Garage Door Opener' },
  { value: '7367', label: 'Heated' },
  { value: '7368', label: 'RV Garage' },
  { value: '7369', label: 'RV Parking' },
  { value: '7370', label: 'Storage Above' },
]

const poolSelectData = [
  { value: '', label: 'Pool' },
  { value: '1414', label: 'Above Ground' },
  { value: '1415', label: 'Association' },
  { value: '1416', label: 'Concrete/Gunite' },
  { value: '1417', label: 'Fenced' },
  { value: '1418', label: 'Fiberglass/Vinyl' },
  { value: '1419', label: 'Heated' },
  { value: '1420', label: 'In-Ground' },
  { value: '1421', label: 'Indoor' },
  { value: '1422', label: 'Private' },
  { value: '1423', label: 'With Spa' },
]

const storiesSelectData = [
  { value: '', label: 'Stories' },
  { value: '5375', label: '1' },
  { value: '5376', label: '1+' },
  { value: '5377', label: '2' },
  { value: '5378', label: '2+' },
  { value: '5379', label: '3' },
  { value: '5380', label: '3+' },
]

const zoningSelectData = [
  { value: '', label: 'Zoning' },
  { value: '7388', label: 'Agricultural' },
  { value: '7389', label: 'Business' },
  { value: '7390', label: 'Commercial' },
  { value: '7391', label: 'Horse Property' },
  { value: '7392', label: 'Industrial' },
  { value: '7393', label: 'Mixed Zoning' },
  { value: '7394', label: 'Mobile Home' },
  { value: '7395', label: 'Multi-Family' },
  { value: '7396', label: 'Office/Professional' },
  { value: '7397', label: 'PUD' },
  { value: '7398', label: 'Recreation' },
  { value: '7399', label: 'Rental Restrictions' },
  { value: '7400', label: 'Residential' },
  { value: '7401', label: 'See Remarks' },
  { value: '7402', label: 'Special Purpose' },
  { value: '7403', label: 'Vacation Rental' },
  { value: '7404', label: 'Zone City' },
  { value: '7405', label: 'Zone County' },
]

const inclusionFeaturesSelectData = [
  { value: '', label: 'Inclusions/Features' },
  { value: '7448', label: '55+ Community' },
  { value: '7449', label: 'Alarm/Security Sys' },
  { value: '7450', label: 'Apartment' },
  { value: '7451', label: 'Atrium' },
  { value: '7452', label: 'Awnings' },
  { value: '7453', label: 'Bar Dry' },
  { value: '7454', label: 'Bar Wet' },
  { value: '7455', label: 'Barns' },
  { value: '7456', label: 'Bath Sep Tub/Shwr' },
  { value: '7457', label: 'Bay/Box Windows' },
  { value: '7458', label: 'Built in Barbecue' },
  { value: '7459', label: 'Casita' },
  { value: '7460', label: 'Ceiling Fan(s)' },
  { value: '7461', label: 'Ceiling Vaulted' },
  { value: '7462', label: 'Central Vac Plumbed' },
  { value: '7463', label: 'Central Vacuum' },
  { value: '7464', label: 'Compactor' },
  { value: '7465', label: 'Corrals' },
  { value: '7466', label: 'Deck Covered' },
  { value: '7467', label: 'Deck Uncovered' },
  { value: '7468', label: 'Dishwasher' },
  { value: '7469', label: 'Disposal' },
  { value: '7470', label: 'Dog Run' },
  { value: '7471', label: 'Dryer' },
  { value: '7472', label: 'Elevator' },
  { value: '7473', label: 'Fenced Full' },
  { value: '7474', label: 'Fenced Partial' },
  { value: '7475', label: 'Fireplace Gas' },
  { value: '7476', label: 'Fireplace Insert' },
  { value: '7477', label: 'Fireplace Masonry' },
  { value: '7478', label: 'Freezer' },
  { value: '7479', label: 'Garden Tub' },
  { value: '7480', label: 'Gazebo' },
  { value: '7481', label: 'Greenhouse' },
  { value: '7482', label: 'Handicap Features' },
  { value: '7483', label: 'Home Warranty' },
  { value: '7484', label: 'Horse Privileges' },
  { value: '7485', label: 'Hot Tub' },
  { value: '7486', label: 'Humidifier' },
  { value: '7487', label: 'Intercom' },
  { value: '7488', label: 'Jetted Tub' },
  { value: '7489', label: 'Landscaped Full' },
  { value: '7490', label: 'Landscaped Partial' },
  { value: '7491', label: 'Loft' },
  { value: '7492', label: 'Microwave' },
  { value: '7493', label: 'Mother-in-Law Apt' },
  { value: '7494', label: 'Outdoor Lighting' },
  { value: '7495', label: 'Oven/Range Built-in' },
  { value: '7496', label: 'Oven/Range Freestnd' },
  { value: '7497', label: 'Patio Covered' },
  { value: '7498', label: 'Patio Uncovered' },
  { value: '7499', label: 'Plant Shelves' },
  { value: '7500', label: 'Range Hood' },
  { value: '7501', label: 'Refrigerator' },
  { value: '7502', label: 'REO/Bank Owned' },
  { value: '7503', label: 'Satellite Dish' },
  { value: '7504', label: 'Sauna' },
  { value: '7505', label: 'Second Kitchen' },
  { value: '7506', label: 'Skylight' },
  { value: '7507', label: 'Smart Wiring' },
  { value: '7508', label: 'Sprinkler Auto' },
  { value: '7509', label: 'Sprinkler Full' },
  { value: '7510', label: 'Sprinkler Man-Full' },
  { value: '7511', label: 'Sprinkler Man-Part' },
  { value: '7512', label: 'Sprinkler Part' },
  { value: '7513', label: 'Storage Shed(s)' },
  { value: '7514', label: 'Storm Doors' },
  { value: '7515', label: 'Storm Windows' },
  { value: '7516', label: 'Sunroom' },
  { value: '7517', label: 'Tennis Courts' },
  { value: '7518', label: 'Theater Room' },
  { value: '7519', label: 'TV Antennas' },
  { value: '7520', label: 'Walk-In Closet(s)' },
  { value: '7521', label: 'Washer' },
  { value: '7522', label: 'Water Rvrse Osmosis' },
  { value: '7523', label: 'Water Softner Owned' },
  { value: '7524', label: 'Water Softner Rnted' },
  { value: '7525', label: 'Window Coverings' },
  { value: '7526', label: 'Window Double Pane' },
  { value: '7527', label: 'Wired for Cable' },
  { value: '7528', label: 'Wood Burning Stove' },
  { value: '7529', label: 'Workshop' },
]

const assocDuesIncludeSelectData = [
  { value: '', label: 'Assoc Dues Include' },
  { value: '7406', label: 'Cable' },
  { value: '7407', label: 'Clubhouse' },
  { value: '7408', label: 'Exterior Bldg Ins' },
  { value: '7409', label: 'Exterior Bldg Maint' },
  { value: '7410', label: 'Full Yard Maintenance' },
  { value: '7411', label: 'Garbage' },
  { value: '7412', label: 'Gated' },
  { value: '7413', label: 'Internet' },
  { value: '7414', label: 'Partial Yard Maintenance' },
  { value: '7415', label: 'Pool' },
  { value: '7416', label: 'Private Road' },
  { value: '7417', label: 'Putting Green' },
  { value: '7418', label: 'Roof' },
  { value: '7419', label: 'Sewer' },
  { value: '7420', label: 'Tennis Courts' },
  { value: '7421', label: 'Water' },
  { value: '7422', label: 'Yard Maintenance' },
]

const srHighSchoolsSelectData = [
  { value: '', label: 'Sr. High Schools' },
  { value: '7423', label: 'Crimson Cliffs High' },
  { value: '7424', label: 'Desert Hills High' },
  { value: '7425', label: 'Dixie High' },
  { value: '7426', label: 'Enterprise High' },
  { value: '7427', label: 'Hurricane High' },
  { value: '7428', label: 'Out of Area' },
  { value: '7429', label: 'Pine View High' },
  { value: '7430', label: 'Snow Canyon High' },
  { value: '7431', label: 'Water Canyon School' },
]

const listingClassSelectData = [
  { value: '', label: 'Listing Class' },
  { value: '7432', label: 'Business + Real Estate' },
  { value: '7433', label: 'Business Only' },
  { value: '7434', label: 'Condominium' },
  { value: '7435', label: 'Industrial' },
  { value: '7436', label: 'Land/Acreage' },
  { value: '7437', label: 'Mobile Home/Owned Lot' },
  { value: '7438', label: 'Mobile Home/Rented Lot' },
  { value: '7439', label: 'Modular/Manufactured' },
  { value: '7440', label: 'New Construction' },
  { value: '7441', label: 'Office' },
  { value: '7442', label: 'Park Model' },
  { value: '7443', label: 'Single Family' },
  { value: '7444', label: 'Subdivided Lot' },
  { value: '7445', label: 'Townhouse' },
  { value: '7446', label: 'Water Rights' },
  { value: '7447', label: 'Water Shares' },
]
export {
  propertyFeaturesSelectData,
  landDescriptionSelectData,
  garageParkingSelectData,
  poolSelectData,
  storiesSelectData,
  zoningSelectData,
  inclusionFeaturesSelectData,
  assocDuesIncludeSelectData,
  srHighSchoolsSelectData,
  listingClassSelectData,
}
