import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Api from '../../../../api/index'

import TopPanel from './blocks/Top-panel'
import Photos from './blocks/Photos'
import ContactBox from './blocks/Contact-box'
import PrimaryInformation from './blocks/Primary-information'
import MortgageCalculator from './blocks/Mortgage-calculator'

function Details({ propertyId }) {
  const [property, setProperty] = useState(null)
  const [isPropertyNotExists, SetIsPropertyNotExists] = useState(false)
  const getProperty = async (status, propertyId) => {
    let newProperty = null
    try {
      newProperty = await Api.propertySearch.getProperty(propertyId)
    } catch (error) {
      console.log(error)
      newProperty = {}
    }
    if (newProperty.data) {
      if (!status.isCanceld) {
        setProperty(newProperty.data)
      }
    } else {
      console.log('not good not set state')
      SetIsPropertyNotExists(true)
    }
  }
  useEffect(() => {
    const status = { isCanceld: false }
    getProperty(status, propertyId)
    return () => {
      status.isCanceld = true
    }
    // eslint-disable-next-line
  }, [propertyId])
  if (isPropertyNotExists) {
    return <> This Property Does Not Exist </>
  }
  if (property === null) {
    return <>Loading...</>
  }

  return (
    <>
      <TopPanel property={property} />
      <Photos photos={property.Photos} />
      <ContactBox />
      <div className="container-ld is-wrapper">
        <PrimaryInformation property={property} />
        <MortgageCalculator price={property.StandardFields.CurrentPrice} />
      </div>
    </>
  )
}
Details.propTypes = {
  propertyId: PropTypes.string.isRequired,
}
export default Details
