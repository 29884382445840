import React from 'react'
import Breadcrumb from '../../../common/Breadcrumb'
import Sidebar from '../../../common/sidebar/Sidebar'

const DmcaNoticePage = () => {
  return (
    <>
      <div className={'si-site-container col-md-8 si-site-container--full'}>
        <Breadcrumb />
        <div className={'si-content-area'}>
          <h1>DMCA Notice</h1>
          <p>
            Do you have a good faith belief that your intellectual property
            rights have been violated by Giddy Digs? Do you have a good faith
            belief that a third party may have uploaded content to
            GiddyDigs.com?
          </p>
          <p>
            If you have answered yes to either or both of these questions, Giddy
            Digs requests you provide to it at its primary office address at,
            1031 S. Bluff Street St. George UT 84770, the information listed
            below in accordance with DMCA:
          </p>
          <p>
            1. Please describe the copyrighted material, work or other
            intellectual property (collectively "Material") which you believe is
            being infringed upon, and where on the GiddyDigs.com website the
            Material is located.
          </p>
          <p>
            2. Your contact information (name, telephone number, e-mail address,
            or other detail where Giddy Digs* or the alleged infringing party
            can make direct contact with you).
          </p>
          <p>
            3. Describe that you have a good faith belief that the Material
            and/or its use is not authorized by the existing copyright or other
            intellectual property rights' registered owner, by its agent or by
            law, and such statement and/or description is signed** under the
            penalties of perjury that you are the owner of the Material or the
            person authorized by the owner to act.
          </p>
          <p>
            Upon receipt of the information above Giddy Digs will act
            expeditiously to remove or disable access to the offending material,
            but may need additional information to fully address the DMCA notice
            that you have provided to us and before removing any of the
            offending material. Your contact information given in the notice
            requested may be provided to the alleged infringing party if it is
            not Giddy Digs so an appropriate proper response can be sent.
          </p>
          <p>
            DMCA is the Digital Millennium Copyright Notice, which states that
            an Internet Service Provider must remove materials from a web site
            that appear to infringe upon copyrighted materials after the ISP
            receive proper notice or lose safe harbor and be subject to
            liability.
          </p>
          <p>
            * The alleged infringing party may be someone other than Giddy Digs.
          </p>
          <p>** The signature may be an electronic signature.</p>
        </div>
      </div>
      <Sidebar />
    </>
  )
}

export default DmcaNoticePage
