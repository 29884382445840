import React from 'react'
import PropTypes from 'prop-types'

const ConfirmDialog = ({
  title = 'no Title',
  text = 'no Text',
  question = 'no Question',
  onAnswer = () => {
    console.log("no handler function found 'onAnswer'")
  },
}) => {
  const yesButtonHandler = () => {
    onAnswer(true)
  }
  const noButtonHandler = () => {
    onAnswer(false)
  }
  return (
    <>
      <div
        className="si-modal modal fade in"
        id="confirmDialog"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="confirmDialogCaption"
        aria-hidden="true"
        style={{ display: 'block', paddingRight: '15px', zIndex: '100000000' }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal">
                <span aria-hidden="true">×</span>
                <span className="sr-only">Close</span>
              </button>
              <h4 className="modal-title" id="confirmDialogCaption">
                {title}
              </h4>
            </div>
            <div id="confirmDialogMessage" className="modal-body">
              {text}
              <br />
              <br />
              {question}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                name="submit"
                id="confirmDialogYes"
                tabIndex="1"
                className="si-btn si-btn--primary"
                onClick={yesButtonHandler}
              >
                Continue
              </button>
              <button
                type="button"
                tabIndex="2"
                id="confirmDialogNo"
                className="si-btn si-btn--default"
                onClick={noButtonHandler}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-backdrop fade in"></div>
    </>
  )
}

ConfirmDialog.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  question: PropTypes.string,
  onAnswer: PropTypes.func,
}

export default ConfirmDialog
