import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import TimeCounter from './Time-counter'

import {
  formatMoney,
  capitlizeFirstChar,
  getAlias,
} from '../../../../helpers/text-helpers'

const timeCounterStyle = {
  position: 'absolute',
  top: '5px',
  left: '15px',
  width: '80%',
  height: '48px',
  fontSize: '1.0em',
  color: 'white',
  zIndex: '2',
  textShadow: '0px 0px 12px rgba(0,0,0,0.7), 0px 0px 9px rgba(0,0,0,0.5)',
}

function ResultsGalleryItem({ property, timestamp }) {
  const loadingPhotoUrl = '/images/listings/loadingphoto_mid.gif'
  const curentPhotoUrl =
    property.Photos.length > 0
      ? property.Photos[0][['UriLarge']]
      : loadingPhotoUrl

  const detailsLink = `/property-search/details/${property.Id}/${getAlias(
    property.StandardFields.UnparsedAddress
  )}`

  const [isApplyJelloEffect, setIsApplyJelloEffect] = useState(false)
  const [currentTime, setCurrentTime] = useState(
    Math.floor(new Date().getTime() / 1000)
  )
  useEffect(() => {
    const propertyTime = Math.floor(new Date(timestamp).getTime() / 1000)
    const jelloEffectPeriod = 3600
    const isNecessaryToApplyJelloEffect =
      currentTime - propertyTime < jelloEffectPeriod
    let timeoutId = null
    if (isNecessaryToApplyJelloEffect) {
      setIsApplyJelloEffect(true)
      timeoutId = setTimeout(() => {
        setCurrentTime(Math.floor(new Date().getTime() / 1000))
      }, 3000)
    } else {
      setIsApplyJelloEffect(false)
    }
    return () => {
      clearTimeout(timeoutId)
    }
  }, [currentTime, timestamp])
  return (
    <div
      className={`si-listings-column ${isApplyJelloEffect ? 'jello' : ''}`}
      style={{ minHeight: '395px' }}
    >
      <div className="si-listing" style={{ display: 'block' }}>
        <div className="si-listing__photo">
          <div style={timeCounterStyle}>
            {timestamp ? (
              <>
                <TimeCounter
                  timestamp={timestamp}
                  OnMarketDate={property.StandardFields.OnMarketDate}
                />{' '}
                on Giddy Digs
              </>
            ) : (
              ''
            )}
          </div>
          <button type="button" className="si-listing__like">
            <i className="fa fa-heart-o"></i>
          </button>
          <a href={detailsLink} className="js-listing-detail">
            <div className="si-listing__photo-img">
              <img
                src={curentPhotoUrl}
                className="si-listing-photo"
                alt="1600 S Mills LN Leeds,  UT 84746"
                style={{ display: 'block' }}
              />
            </div>
            <div className="si-listing__photo-price">
              <span>
                ${formatMoney(property.StandardFields.CurrentPrice, 0)}
              </span>
              <div className="si-listing__photo-count">
                {property.Photos.length} <i className="fa fa-camera"></i>
              </div>
            </div>
          </a>
        </div>
        <a
          href={detailsLink}
          title="1600 S Mills LN Leeds,  UT 84746"
          className="js-listing-detail"
        >
          <div className="si-listing__title">
            <div className="si-listing__title-main">
              {property.StandardFields.UnparsedFirstLineAddress}
            </div>
            <div className="si-listing__title-description">
              {property.StandardFields.City},{' '}
              {property.StandardFields.StateOrProvince}{' '}
              {property.StandardFields.PostalCode}
            </div>
          </div>
        </a>
        <div className="si-listing__content">
          <div className="si-listing__neighborhood">
            <span className="si-listing__neighborhood-place">
              {capitlizeFirstChar(property.StandardFields.SubdivisionName)}
            </span>
          </div>
          <div className="si-listing__info">
            <div>
              <div className="si-listing__info-value">
                <span> {property.StandardFields.BedsTotal} </span>
              </div>
              <div className="si-listing__info-label"> Beds </div>
            </div>
            <div>
              <div className="si-listing__info-value">
                <span> {property.StandardFields.BathsTotal} </span>
              </div>
              <div className="si-listing__info-label"> Baths </div>
            </div>
            <div>
              <div className="si-listing__info-value">
                <span>
                  {formatMoney(property.StandardFields.BuildingAreaTotal, 0)}
                </span>
              </div>
              <div className="si-listing__info-label"> Sq.Ft. </div>
            </div>
          </div>
          <div className="si-listing__footer">
            <div>
              {property.StandardFields.ListOfficeMarketingName
                ? property.StandardFields.ListOfficeMarketingName
                : '- '}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
ResultsGalleryItem.propTypes = {
  property: PropTypes.object.isRequired,
  timestamp: PropTypes.string,
}
export default ResultsGalleryItem
