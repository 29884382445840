import React, { useState, useEffect } from 'react'
import Api from '../../../api'

import PhoneInput from './inputs/Phone-input'
import EmailInput from './inputs/Email-input'

const ContactForm = () => {
  //inputs
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [comments, setComments] = useState('')

  const [formSent, setFormSent] = useState(false)
  const [sucsessResponce, setSuccessResponce] = useState(false)
  const [errorResponce, setErrorResponce] = useState(false)

  const firstNameChangeHandler = (evt) => {
    setFirstName(evt.target.value)
  }
  const lastNameChangeHandler = (evt) => {
    setLastName(evt.target.value)
  }
  const questionsChangeHandler = (evt) => {
    setComments(evt.target.value)
  }

  const handleSubmit = async (evt) => {
    evt.preventDefault()
    setFormSent(true)
    const contactFormData = {
      formName: 'Contacts page',
      name: firstName + ' ' + lastName,
      email,
      phone,
      comments,
      source: window.location.hostname,
      url: window.location.href,
    }
    let result
    try {
      result = await Api.forms.sheduleATour(contactFormData)
    } catch (e) {
      result = e
    }
    console.log(result)
    if (result.data && result.data.success) {
      setSuccessResponce(true)
    } else {
      setErrorResponce(true)
    }
  }
  const refreshForm = () => {
    setFormSent(false)
    setSuccessResponce(false)
    setErrorResponce(false)
  }
  useEffect(() => {
    let timeoutId = null
    const FiveSeconds = 5000
    if (sucsessResponce || errorResponce) {
      timeoutId = setTimeout(refreshForm, FiveSeconds)
    }
    return () => {
      clearTimeout(timeoutId)
    }
  }, [formSent, sucsessResponce, errorResponce])
  return (
    <div className="si-container si-form">
      <div className="si-row">
        {sucsessResponce ? (
          <div>
            <h1>Thank You for Contacting Us</h1>{' '}
            <p>We'll get back to you as quickly as we can</p>
          </div>
        ) : (
          ''
        )}
        {errorResponce ? (
          <div>
            <h1>The form is not sent.</h1>{' '}
            <p>Something went wrong. Try to submit the form later.</p>
          </div>
        ) : (
          ''
        )}
        {formSent && !sucsessResponce && !errorResponce ? (
          <div>
            <h3>
              {' '}
              Sending the form{' '}
              <i className="fa fa-circle-o-notch fa-fw fa-spin"></i>{' '}
            </h3>
          </div>
        ) : (
          ''
        )}
        {formSent ? (
          ''
        ) : (
          <form onSubmit={handleSubmit} className="js-contact-form">
            <div className="form-group si-form__column">
              <label className="control-label" htmlFor="First_Name">
                First Name:
              </label>
              <input
                type="text"
                name="First_Name"
                value={firstName}
                className="form-control"
                onChange={firstNameChangeHandler}
                required
              />
            </div>
            <div className="form-group si-form__column">
              <label className="control-label" htmlFor="Last_Name">
                Last Name:
              </label>
              <input
                type="text"
                name="Last_Name"
                value={lastName}
                className="form-control"
                onChange={lastNameChangeHandler}
                required
              />
            </div>
            <div className="form-group si-form__column">
              <label className="control-label" htmlFor="Email">
                Email Address:
              </label>
              <EmailInput email={email} setEmail={setEmail} />
            </div>
            <div className="form-group si-form__column">
              <label className="control-label" htmlFor="Phone">
                Phone:
              </label>
              <PhoneInput phone={phone} setPhone={setPhone} />
            </div>
            <div className="form-group si-form__full">
              <label className="control-label" htmlFor="requestComments">
                Questions / Comments:
              </label>
              <textarea
                name="Questions"
                className="form-control"
                required=""
                value={comments}
                title="Please enter your question"
                aria-required="true"
                onChange={questionsChangeHandler}
              ></textarea>
            </div>

            <div className="form-group si-form__full">
              <button type="submit" className="si-btn si-btn--secondary">
                Submit
              </button>
            </div>
            <div className="form-group si-form__full">
              <div className="si-captcha-disclaimer">
                This site is protected by reCAPTCHA and the Google{' '}
                <a
                  href="https://policies.google.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
                and
                <a
                  href="https://policies.google.com/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service
                </a>{' '}
                apply.
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}

export default ContactForm
