import React from 'react'
import Breadcrumb from '../../../common/Breadcrumb'
import Sidebar from '../../../common/sidebar/Sidebar'

const spanStyle = {
  fontSize: '21px',
}

const GetLicensedPage = () => {
  return (
    <>
      <div className={'si-site-container col-md-8 si-site-container--full'}>
        <Breadcrumb />
        <div className={'si-content-area'}>
          <h1>Becoming a Real Estate Agent in St George, Utah</h1>
          <p>
            <span style={spanStyle}>
              <br />
              Expect an initial cost of about $2,000 and 3 to 5 weeks of full
              time schooling (120 hours).
            </span>
            <br />
            <br />
            <strong>
              <span style={spanStyle}>Step 1</span>
            </strong>
            <br />
            <span style={spanStyle}>
              Make sure you meet the minimum requirements in Utah to be a real
              estate agent. Those requirements are as follows:
            </span>
            <br />
            <span style={spanStyle}>
              a) Be at least 18 years of age at the time of application with the
              state. You can start this process when your 17. You just won't be
              able to get your licence till you're 18.&nbsp;
            </span>
            <br />
            <span style={spanStyle}>
              b) Have at a minimum a High School diploma or its equivalent.
            </span>
            <br />
            <span style={spanStyle}>
              c) No felony convictions in the last five years.
            </span>
            <br />
            <span style={spanStyle}>
              d) No misdemeanor convictions involving fraud, misrepresentation,
              theft, or dishonesty within the last three years.
            </span>
          </p>
          <p>
            <strong>
              <span style={spanStyle}>Step 2</span>
            </strong>
            <br />
            <span style={spanStyle}>
              Evaluate how you will generate business. If you are sure you can
              be a lead generator move on to step 2. If you have any hesitation,
              at all, set an{' '}
              <a
                href="https://www.giddydigs.com/contact/appt/"
                target="_blank"
                rel="noopener noreferrer"
              >
                appointment
              </a>{' '}
              to meet with Joe to discuss your options and evaluate your
              apptitude.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span style={spanStyle}>Step 3</span>
            </strong>
            <br />
            <span style={spanStyle}>
              Sign up for real estate school. We prefer{' '}
              <a
                href="https://www.praedo.com/course-catalog?course=1096&amp;category=2&amp;state=45&amp;catalog=1007&amp;course_period=44439"
                target="_blank"
                rel="noopener noreferrer"
              >
                Praedo
              </a>
              . The cost is $400 and It can take up to five weeks of full time
              schooling (120 hours). I have seen some complete it in as little
              as one week. At the end of the 120 hours you will need to pass a
              practice test. This test can take up to 4 hours and you may have
              to take it several times.&nbsp;
            </span>
            <br />
            <br />
            <strong>
              <span style={spanStyle}>Step 4</span>
            </strong>
            <br />
            <span style={spanStyle}>
              Schedule the Utah sales agent licensing exam via{' '}
              <a
                href="https://home.pearsonvue.com/ut/realestate"
                target="_blank"
                rel="noopener noreferrer"
              >
                Pearson VUE
              </a>{' '}
              in Las Vegas. Sometimes you will have to schedule this a couple
              weeks in advance. The examination fee if $66.&nbsp;
            </span>
            <br />
            <br />
            <strong>
              <span style={spanStyle}>Step 5</span>
            </strong>
            <br />
            <span style={spanStyle}>
              Apply for a license with the{' '}
              <a
                href="https://realestate.utah.gov/realestate/salesagent.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Utah Division of Real Estate
              </a>
              . The cost is $152. So far the total is up to $618.&nbsp;
            </span>
          </p>
          <p>
            <strong>
              <span style={spanStyle}>Step 6</span>
            </strong>
            <br />
            <span style={spanStyle}>
              Pay your Washington County Board Realtors dues. This is prorated
              from the beginning of November. Total cost is $1,031. So if you
              signed up on March first your cost would be about $690, with
              another $1,031 due by November 1st.&nbsp;
            </span>
            <br />
            <br />
            <span style={spanStyle}>
              That is it, now you are ready to start learning about real estate
              LOL. Some agents catch on right away. Most agents will take a year
              or two to learn the ropes and start making six figures.&nbsp;
            </span>
          </p>
        </div>
      </div>
      <Sidebar />
    </>
  )
}

export default GetLicensedPage
