import React, { useState, useEffect } from 'react'

import EmailInput from '../../../inputs/Email-input'
import { authInputViewNames } from '../../dictionaries/inputViews'

import { isEmailValid } from '../../../../../../helpers/email-validator'

function AuthRegisterInputViewStepOne({
  email,
  setEmail,
  changeView,
  setStepNumber,
}) {
  const [isShowEmailValidError, setIsShowEmailValidError] = useState(false)

  const openSigninInputViewButtonHandler = (evt) => {
    evt.preventDefault()
    changeView(authInputViewNames.AuthSigninInputView)
  }

  const submitEmailButtonClickHandler = (evt) => {
    console.log('isEmailValid', isEmailValid(email))
    if (isEmailValid(email)) {
      setStepNumber(2)
    } else {
      setIsShowEmailValidError(true)
    }
  }

  useEffect(() => {
    if (isShowEmailValidError) {
      setIsShowEmailValidError(false)
    }
    // eslint-disable-next-line
  }, [email])

  const emailNotValidError = () => (
    <div className="si-error-msg">Please enter a valid email address</div>
  )
  return (
    <div id="authRegisterStep1" className="si-auth-modal__form-sm">
      <div className="modal-info auth-modal-info"></div>

      <div className="modal-body">
        <div className="si-row">
          <div className="form-group si-form__full">
            <label className="control-label" htmlFor="authRegisterEmail">
              &nbsp;
            </label>

            <EmailInput email={email} setEmail={setEmail} />
            {isShowEmailValidError ? emailNotValidError() : ''}
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          id="authRegisterStep1Submit"
          className="si-btn si-btn--primary si-auth-modal__form-sm-btn"
          onClick={submitEmailButtonClickHandler}
        >
          Continue with Email
        </button>
        <p className="si-view-link">
          Returning User?{' '}
          <a
            href="/"
            className="js-signin-link"
            onClick={openSigninInputViewButtonHandler}
          >
            Login Here
          </a>
        </p>
        <p className="si-tos">
          <br />
          By registering you agree to our{' '}
          <a href="/privacy-policy/" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>{' '}
          and{' '}
          <a
            href="/terms-of-service/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </a>
        </p>
      </div>
    </div>
  )
}

export default AuthRegisterInputViewStepOne
