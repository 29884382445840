import React from 'react'
import Breadcrumb from '../../../common/Breadcrumb'
import Sidebar from '../../../common/sidebar/Sidebar'

const SiteMapPage = () => {
  return (
    <>
      <div className={'si-site-container col-md-8 si-site-container--full'}>
        <Breadcrumb />
        <div className="si-container si-sitemap">
          <div className="si-sitemap__section">
            <div className="si-sitemap__title">
              <a href="/">Home</a>
            </div>
          </div>

          <div className="si-sitemap__section">
            <div className="si-sitemap__title">
              <a href="/property-search/search-form/">
                St. George UT Search Tools
              </a>
            </div>
            <div className="si-sitemap__links" id="collapseSearchTools">
              <a href="/property-search/search-form/" title="Advanced Search">
                Advanced Search
              </a>
              <a
                href="/property-search/results/?searchtype=3"
                title="Interactive Map Search"
              >
                Interactive Map Search
              </a>
              <a href="/communities/" title="Search by Community">
                Search by Community
              </a>
              <a
                href="/property-search/property-tracker/"
                title="Property Tracker"
              >
                Property Tracker
              </a>
              <a href="/property-search/site-map/" title="Property Listings">
                Property Listings
              </a>
            </div>
          </div>
          <div className="si-sitemap__section">
            <div className="si-sitemap__title">
              <a href="/about/">About</a>
            </div>
            <div className="si-sitemap__links" id="collapseSection1296">
              <a href="/about/">About</a>
              <a href="/about/featured-listings/">Featured Listings</a>
              <a href="/about/what-it-takes/">What it Takes</a>
            </div>
          </div>
          <div className="si-sitemap__section">
            <div className="si-sitemap__title">
              <a href="/buyers/mortgage-calculator/">Buy</a>
            </div>
            <div className="si-sitemap__links" id="collapseSection1297">
              <a href="/buy/mortgage-calculator/">Mortgage Calculator</a>
              <a href="/buy/mortgage-pre-approval/">Mortgage Pre-Approval</a>
              <a href="/buy/first-time-buyers/">First Time Buyers</a>
              <a href="/buy/making-an-offer/">Making an Offer</a>
              <a href="/buy/what-are-closing-costs/">What Are Closing Costs?</a>
              <a href="/buy/escrow-now-what/">Escrow: Now What?</a>
              <a href="/buy/financial-terms-glossary/">
                Financial Terms Glossary
              </a>
              <a href="/buy/personalized-home-search/">
                Personalized Home Search
              </a>
              <a href="/buy/todays-new-listings/">Today's New Listings</a>
              <a href="/buy/rtb/">Ready to Buy</a>
              <a href="/buy/utility-services/">Utility Services</a>
              <a href="/buy/homes-for-sale-in-saint-george/">
                Homes For Sale In Saint George UT
              </a>
            </div>
          </div>
          <div className="si-sitemap__section">
            <div className="si-sitemap__title">
              <a href="/communities/">Communities</a>
            </div>
            <div className="si-sitemap__links" id="collapseSection1298">
              <a href="/communities/">Communities</a>
              <a href="/market-statistics/">Market Statistics</a>
              <a href="/communities/apple-valley/">Apple Valley</a>
              <a href="/communities/cedar-city/">Cedar City</a>
              <a href="/communities/dammeron-valley/">Dammeron Valley</a>
              <a href="/communities/enterprise/">Enterprise</a>
              <a href="/communities/hurricane/">Hurricane</a>
              <a href="/communities/ivins/">Ivins</a>
              <a href="/communities/la-verkin/">La Verkin</a>
              <a href="/communities/leeds/">Leeds</a>
              <a href="/communities/santa-clara/">Santa Clara</a>
              <a href="/communities/springdale/">Springdale</a>
              <a href="/communities/st-george/">St George</a>
              <a href="/communities/virgin/">Virgin</a>
              <a href="/communities/washington/">Washington</a>
              <a href="/communities/bloomington/">Bloomington</a>
              <a href="/communities/bloomington-hills/">Bloomington Hills</a>
              <a href="/communities/coral-canyon/">Coral Canyon</a>
              <a href="/communities/dixie-springs/">Dixie Springs</a>
              <a href="/communities/entrada/">Entrada</a>
              <a href="/communities/little-valley/">Little Valley</a>
              <a href="/communities/sun-river/">Sun River</a>
              <a href="/communities/the-ledges/">The Ledges</a>
              <a href="/communities/winchester-hills/">Winchester Hills</a>
            </div>
          </div>
          <div className="si-sitemap__section">
            <div className="si-sitemap__title">
              <a href="/contact/">Contact</a>
            </div>
            <div className="si-sitemap__links" id="collapseSection1299">
              <a href="/contact/">Contact</a>
              <a href="/contact/appointment/">Appointment</a>
            </div>
          </div>
          <div className="si-sitemap__section">
            <div className="si-sitemap__title">
              <a href="/property-search/nightly-rentals/">Search</a>
            </div>
            <div className="si-sitemap__links" id="collapseSection1707">
              <a href="/property-search/nightly-rentals/">Nightly Rentals</a>
              <a href="/property-search/rentals/">Rentals</a>
            </div>
          </div>
          <div className="si-sitemap__section">
            <div className="si-sitemap__title">
              <a href="/sellers/">Sell</a>
            </div>
            <div className="si-sitemap__links" id="collapseSection1301">
              <a href="/sell/">Selling a Home</a>
              <a href="/sell/pricing-your-home/">Pricing Your Home</a>
              <a href="/sell/marketing-your-home/">Marketing Your Home</a>
              <a href="/sell/showing-your-home/">Showing Your Home</a>
              <a href="/sell/adding-value/">Adding Value</a>
              <a href="/sell/free-market-analysis/">Free Market Analysis</a>
              <a href="/sell/sell-your-property/">Sell your property</a>
            </div>
          </div>
          <div className="si-sitemap__section">
            <div className="si-sitemap__title">
              <a href="/agents/">Agents</a>
            </div>
            <div className="si-sitemap__links" id="collapseAgents">
              <a href="/agents/loreleineuman/">Lorelei Naegle Neuman</a>

              <a href="/agents/joeneuman/">Joe Neuman</a>
            </div>
          </div>

          <div className="si-sitemap__section">
            <div className="si-sitemap__title">
              <a href="/blog/">St. George, UT Real Estate Blog</a>
            </div>
          </div>

          <div className="si-sitemap__section">
            <div className="si-sitemap__sub-title">Blog Posts By Category</div>
            <div className="si-sitemap__links" id="collapseBlogPostsCat">
              <a href="/blog/category/buying-a-home/" rel="nofollow">
                Buying a Home (4)
              </a>

              <a href="/blog/category/selling-your-home/" rel="nofollow">
                Selling Your Home (3)
              </a>

              <a href="/blog/category/who-we-are/" rel="nofollow">
                Who We Are (1)
              </a>
            </div>
          </div>

          <div className="si-sitemap__section">
            <div className="si-sitemap__sub-title">Blog Posts By Month</div>
            <div className="si-sitemap__links" id="collapseBlogPostsMonth">
              <a href="/blog/month/1-2020/" rel="nofollow">
                January, 2020 (1)
              </a>

              <a href="/blog/month/8-2019/" rel="nofollow">
                August, 2019 (1)
              </a>

              <a href="/blog/month/5-2019/" rel="nofollow">
                May, 2019 (1)
              </a>

              <a href="/blog/month/1-2019/" rel="nofollow">
                January, 2019 (1)
              </a>

              <a href="/blog/month/8-2018/" rel="nofollow">
                August, 2018 (4)
              </a>
            </div>
          </div>

          <div className="si-sitemap__section">
            <div className="si-sitemap__sub-title">Blog Posts Archive</div>
            <div
              className="si-sitemap__links si-sitemap__links--wide"
              id="collapseBlogPosts"
            >
              <a
                href="/blog/buyer-beware/"
                title="Buyer Beware! Top Myths/Facts About Hiring a Buyer's Agent"
              >
                Buyer Beware! Top Myths/Facts About Hiring a Buyer's Agent
              </a>

              <a
                href="/blog/days-on-market-why-setting-the-right-price-for-your-home-is-crucial/"
                title="Days on Market: Why Setting the Right Price for Your Home is Crucial"
              >
                Days on Market: Why Setting the Right Price for Your Home is
                Crucial
              </a>

              <a
                href="/blog/homesaleduringdivorce/"
                title="Home Sale During Divorce: Top Tips for Selling a House During Divorce"
              >
                Home Sale During Divorce: Top Tips for Selling a House During
                Divorce
              </a>

              <a href="/blog/naming-giddy-digs/" title="Naming Giddy Digs">
                Naming Giddy Digs
              </a>

              <a
                href="/blog/is-getting-a-home-mortgage-still-too-difficult/"
                title="Is Getting a Home Mortgage Still Too Difficult?"
              >
                Is Getting a Home Mortgage Still Too Difficult?
              </a>

              <a
                href="/blog/why-you-should-consider-selling-in-the-winter/"
                title="Why You Should Consider Selling in the Winter"
              >
                Why You Should Consider Selling in the Winter
              </a>

              <a
                href="/blog/common-things-to-look-out-for-before-buying-your-dream-home/"
                title="Common Things to Look Out for Before Buying Your Dream Home"
              >
                Common Things to Look Out for Before Buying Your Dream Home
              </a>

              <a
                href="/blog/will-increasing-mortgage-rates-impact-home-prices/"
                title="Will Increasing Mortgage Rates Impact Home Prices?"
              >
                Will Increasing Mortgage Rates Impact Home Prices?
              </a>
            </div>
          </div>
        </div>
      </div>
      <Sidebar />
    </>
  )
}

export default SiteMapPage
