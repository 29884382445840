import React from 'react'
import Breadcrumb from '../../../common/Breadcrumb'
import Sidebar from '../../../common/sidebar/Sidebar'

const FinancialTermsGlossaryPage = () => {
  return (
    <>
      <div className={'si-site-container col-md-8 si-site-container--full'}>
        <Breadcrumb />
        <div className={'si-content-area'}>
          <h1>Financial Terms Glossary</h1>
          <div
            style={{
              border: '1px solid #cccccc',
              padding: '6px 0px',
              marginTop: '18px',
              textAlign: 'center',
              fontSize: '14px',
              letterSpacing: '5px',
              backgroundColor: '#fff3c9',
            }}
          >
            <a href="#A">A</a>
            <a href="#B">B</a>
            <a href="#C">C</a>
            <a href="#D">D</a>
            <a href="#E">E</a>
            <a href="#F">F</a>
            <a href="#G">G</a>
            <a href="#H">H</a>
            <a href="#I">I</a>
            <a href="#J">J</a>
            <span className="nolink">K</span>
            <a href="#L">L</a>
            <a href="#M">M</a>
            <a href="#N">N</a>
            <a href="#O">O</a>
            <a href="#P">P</a>
            <a href="#Q">Q</a>
            <a href="#R">R</a>
            <a href="#S">S</a>
            <a href="#T">T</a>
            <span className="nolink">U</span>
            <a href="#V">V</a>
            <span className="nolink">W</span>
            <span className="nolink">X</span>
            <span className="nolink">Y</span>
            <span className="nolink">Z</span>
          </div>
          <p></p>
          <table>
            <tbody>
              <tr>
                <td valign="top">
                  <button style={{ color: '#363636' }} name="A">
                    A
                  </button>
                  <img
                    alt=""
                    src="https://assets.site-static.com/userFiles/template_45/image/spacer.gif"
                    width="60"
                    height="60"
                    border="0"
                  />
                </td>
                <td>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="ARM">
                        adjustable-rate mortgage
                      </button>
                    </strong>
                    <br clear="all" /> A mortgage whose interest rate changes
                    periodically based on the changes in a specified index.
                  </p>
                  <p>
                    <strong>
                      <button
                        style={{ color: '#363636' }}
                        name="adjustmentdate"
                      >
                        adjustment date
                      </button>
                      <br clear="all" />
                    </strong>
                    The date on which the interest rate changes for an
                    adjustable-rate mortgage (ARM).
                  </p>
                  <p>
                    <strong>
                      <button
                        style={{ color: '#363636' }}
                        name="adjustmentperiod"
                      >
                        adjustment period
                      </button>
                      <br clear="all" />
                    </strong>
                    The period that elapses between the adjustment dates for an
                    adjustable-rate mortgage (ARM).
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="amortization">
                        amortization
                      </button>
                    </strong>
                    <br clear="all" /> The repayment of a mortgage loan by
                    installments with regular payments to cover the principal
                    and interest.
                  </p>
                  <p>
                    <strong>
                      <button
                        style={{ color: '#363636' }}
                        name="amortizationterm"
                      >
                        amortization term
                      </button>
                    </strong>
                    <br clear="all" /> The amount of time required to amortize
                    the mortgage loan. The amortization term is expressed as a
                    number of months. For example, for a 30-year fixed-rate
                    mortgage, the amortization term is 360 months.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="APR">
                        annual percentage rate (APR)
                      </button>
                    </strong>
                    <br clear="all" /> The cost of a mortgage stated as a yearly
                    rate; includes such items as interest, mortgage insurance,
                    and loan origination fee (points).
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="appreciation">
                        appreciation
                      </button>
                    </strong>
                    <br clear="all" /> An increase in the value of a property
                    due to changes in market conditions or other causes. The
                    opposite of depreciation.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="asset">
                        asset
                      </button>
                    </strong>
                    <br clear="all" /> Anything of monetary value that is owned
                    by a person. Assets include real property, personal
                    property, and enforceable claims against others (including
                    bank accounts, stocks, mutual funds, and so on).
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="assignment">
                        assignment
                      </button>
                    </strong>
                    <br clear="all" /> The transfer of a mortgage from one
                    person to another.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="assumable">
                        assumable mortgage
                      </button>
                    </strong>
                    <br clear="all" /> A mortgage that can be taken over
                    ("assumed") by the buyer when a home is sold.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="assumption">
                        assumption
                      </button>
                      <br clear="all" />
                    </strong>
                    The transfer of the seller's existing mortgage to the buyer.
                  </p>
                  <p>
                    <strong>
                      <button
                        style={{ color: '#363636' }}
                        name="assumptionclause"
                      >
                        assumption clause
                      </button>
                      <br clear="all" />
                    </strong>
                    A provision in an assumable mortgage that allows a buyer to
                    assume responsibility for the mortgage from the seller. The
                    loan does not need to be paid in full by the original
                    borrower upon sale or transfer of the property.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="assumptionfee">
                        assumption fee
                      </button>
                    </strong>
                    <br clear="all" /> The fee paid to a lender (usually by the
                    purchaser of real property) resulting from the assumption of
                    an existing mortgage.
                  </p>
                  <br />
                  <p className="pageTop">
                    <a href="#top" className="button">
                      Page Top
                    </a>
                  </p>
                </td>
              </tr>

              <tr>
                <td valign="top">
                  <button style={{ color: '#363636' }} name="B">
                    B
                  </button>
                  <img
                    alt=""
                    src="https://assets.site-static.com/userFiles/template_45/image/spacer.gif"
                    width="60"
                    height="60"
                    border="0"
                  />
                </td>
                <td>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="balancesheet">
                        balance sheet
                      </button>
                    </strong>
                    <br clear="all" /> A financial statement that shows assets,
                    liabilities, and net worth as of a specific date.
                  </p>
                  <p>
                    <strong>
                      <button
                        style={{ color: '#363636' }}
                        name="balloonmortgage"
                      >
                        balloon mortgage
                      </button>
                    </strong>
                    <br clear="all" /> A mortgage that has level monthly
                    payments that will amortize it over a stated term but that
                    provides for a lump sum payment to be due at the end of an
                    earlier specified term.
                  </p>
                  <p>
                    <strong>
                      <button
                        style={{ color: '#363636' }}
                        name="balloonpayment"
                      >
                        balloon payment
                      </button>
                    </strong>
                    <br clear="all" /> The final lump sum payment that is made
                    at the maturity date of a balloon mortgage.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="basis">
                        basis point
                      </button>
                    </strong>
                    <br clear="all" /> A basis point is 1/100th of a percentage
                    point. For example, a fee calculated as 50 basis points of a
                    loan amount of $100,000 would be 0.50% or $500.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="binder">
                        binder
                      </button>
                    </strong>
                    <br clear="all" /> A preliminary agreement, secured by the
                    payment of an earnest money deposit, under which a buyer
                    offers to purchase real estate.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="biweekly">
                        biweekly payment mortgage
                      </button>
                    </strong>
                    <br clear="all" /> A mortgage that requires payments to
                    reduce the debt every two weeks (instead of the standard
                    monthly payment schedule). The 26 (or possibly 27) biweekly
                    payments are each equal to one-half of the monthly payment
                    that would be required if the loan were a standard 30-year
                    fixed-rate mortgage, and they are usually drafted from the
                    borrower's bank account. The result for the borrower is a
                    substantial savings in interest.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="blanket">
                        blanket mortgage
                      </button>
                    </strong>
                    <br clear="all" /> The mortgage that is secured by a
                    cooperative project, as opposed to the share loans on
                    individual units within the project.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="breach">
                        breach
                      </button>
                    </strong>
                    <br clear="all" /> A violation of any legal obligation.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="bridge">
                        bridge loan
                      </button>
                    </strong>
                    <br clear="all" /> A form of second trust that is
                    collateralized by the borrower's present home (which is
                    usually for sale) in a manner that allows the proceeds to be
                    used for closing on a new house before the present home is
                    sold. Also known as "swing loan."
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="broker">
                        broker
                      </button>
                    </strong>
                    <br clear="all" /> A person who, for a commission or a fee,
                    brings parties together and assists in negotiating contracts
                    between them.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="buydown">
                        buydown mortgage
                      </button>
                    </strong>
                    <br clear="all" /> A temporary buydown is a mortgage on
                    which an initial lump sum payment is made by any party to
                    reduce a borrower's monthly payments during the first few
                    years of a mortgage. A permanent buydown reduces the
                    interest rate over the entire life of a mortgage.
                  </p>
                  <br />
                  <p className="pageTop">
                    <a href="#top" className="button">
                      Page Top
                    </a>
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <button style={{ color: '#363636' }} name="C">
                    C
                  </button>
                  <img
                    alt=""
                    src="https://assets.site-static.com/userFiles/template_45/image/spacer.gif"
                    width="60"
                    height="60"
                    border="0"
                  />
                </td>
                <td>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="calltoption">
                        call option
                      </button>
                    </strong>
                    <br clear="all" /> A provision in the mortgage that gives
                    the mortgagee the right to call the mortgage due and payable
                    at the end of a specified period for whatever reason.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="cap">
                        cap
                      </button>
                    </strong>
                    <br clear="all" /> A provision of an adjustable-rate
                    mortgage (ARM) that limits how much the interest rate or
                    mortgage payments may increase or decrease.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="capitalimp">
                        capital improvement
                      </button>
                    </strong>
                    <br clear="all" /> Any structure or component erected as a
                    permanent improvement to real property that adds to its
                    value and useful life.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="cashoutrefi">
                        cash-out refinance
                      </button>
                    </strong>
                    <br clear="all" /> A refinance transaction in which the
                    amount of money received from the new loan exceeds the total
                    of the money needed to repay the existing first mortgage,
                    closing costs, points, and the amount required to satisfy
                    any outstanding subordinate mortgage liens. In other words,
                    a refinance transaction in which the borrower receives
                    additional cash that can be used for any purpose.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="eligibility">
                        certificate of deposit
                      </button>
                    </strong>
                    <br clear="all" /> Commonly known as a "CD," certificates of
                    deposit bear a maturity date and a specified rate of
                    interest. Penalties may apply for early withdrawal.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="eligibility">
                        certificate of eligibility
                      </button>
                    </strong>
                    <br clear="all" /> A document issued by the federal
                    government certifying a veteran's eligibility for a
                    Department of Veterans Affairs (VA) mortgage.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="CRV">
                        certificate of reasonable value (CRV)
                      </button>
                    </strong>
                    <br clear="all" /> A document issued by the Department of
                    Veterans Affairs (VA) that establishes the maximum value and
                    loan amount for a VA mortgage.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="certtitle">
                        certificate of title
                      </button>
                    </strong>
                    <br clear="all" /> A statement provided by an abstract
                    company, title company, or attorney stating that the title
                    to real estate is legally held by the current owner.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="chaintitle">
                        chain of title
                      </button>
                    </strong>
                    <br clear="all" /> The history of all of the documents that
                    transfer title to a parcel of real property, starting with
                    the earliest existing document and ending with the most
                    recent.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="frequency">
                        change frequency
                      </button>
                    </strong>
                    <br clear="all" /> The frequency (in months) of payment
                    and/or interest rate changes in an adjustable-rate mortgage
                    (ARM).
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="cleartitle">
                        clear title
                      </button>
                    </strong>
                    <br clear="all" /> A title that is free of liens or legal
                    questions as to ownership of the property.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="closing">
                        closing
                      </button>
                    </strong>
                    <br clear="all" /> A meeting at which a sale of a property
                    is finalized by the buyer signing the mortgage documents and
                    paying closing costs. Also called "settlement."
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="closingcost">
                        closing cost item
                      </button>
                    </strong>
                    <br clear="all" /> A fee or amount that a home buyer must
                    pay at closing for a single service, tax, or product.
                    Closing costs are made up of individual closing cost items
                    such as origination fees and attorney's fees. Many closing
                    cost items are included as numbered items on the HUD-1
                    statement.&nbsp; Expenses (over and above the price of the
                    property) incurred by buyers and sellers in transferring
                    ownership of a property. Closing costs normally include an
                    origination fee, an attorney's fee, taxes, an amount placed
                    in escrow, and charges for obtaining title insurance and a
                    survey. Closing costs percentage will vary according to the
                    area of the country.
                  </p>
                  <p>
                    <strong>
                      <button
                        style={{ color: '#363636' }}
                        name="closingstatement"
                      >
                        closing statement
                      </button>
                    </strong>
                    <br clear="all" /> Also referred to as the HUD-1. The final
                    statement of costs incurred to close on a loan or to
                    purchase a home.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="cloud">
                        cloud on title
                      </button>
                    </strong>
                    <br clear="all" /> Any conditions revealed by a title search
                    that adversely affect the title to real estate. Usually
                    clouds on title cannot be removed except by a quitclaim
                    deed, release, or court action.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="collateral">
                        collateral
                      </button>
                    </strong>
                    <br clear="all" /> An asset (such as a car or a home) that
                    guarantees the repayment of a loan. The borrower risks
                    losing the asset if the loan is not repaid according to the
                    terms of the loan contract.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="collection">
                        collection
                      </button>
                    </strong>
                    <br clear="all" /> The efforts used to bring a delinquent
                    mortgage current and to file the necessary notices to
                    proceed with foreclosure when necessary.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="combination">
                        combination loan
                      </button>
                    </strong>
                    <br clear="all" /> With this type of loan, you receive a
                    first mortgage for 80 percent of the loan amount, and a
                    second mortgage at the same time for the remainder of the
                    balance. If avoiding PMI (mortgage insurance) is important
                    to you, consider combination loans--known as 80/10/10 loans
                    or 80/20's.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="CLTV">
                        combined loan-to-value (CLTV)
                      </button>
                    </strong>
                    <br clear="all" /> The unpaid principal balances of all the
                    mortgages on a property (first and second usually) divided
                    by the property's appraised value.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="comaker">
                        co-maker
                      </button>
                    </strong>
                    <br clear="all" /> A person who signs a promissory note
                    along with the borrower. A co-maker's signature guarantees
                    that the loan will be repaid, because the borrower and the
                    co-maker are equally responsible for the repayment. See
                    endorser.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="commission">
                        commission
                      </button>
                    </strong>
                    <br clear="all" /> The fee charged by a broker or agent for
                    negotiating a real estate or loan transaction. A commission
                    is generally a percentage of the price of the property or
                    loan.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="commitment">
                        commitment letter
                      </button>
                    </strong>
                    <br clear="all" /> A formal offer by a lender stating the
                    terms under which it agrees to lend money to a home buyer.
                    Also known as a "loan commitment."
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="common">
                        common areas
                      </button>
                    </strong>
                    <br clear="all" /> Those portions of a building, land, and
                    amenities owned (or managed) by a planned unit development
                    (PUD) or condominium project's homeowners' association (or a
                    cooperative project's cooperative corporation) that are used
                    by all of the unit owners, who share in the common expenses
                    of their operation and maintenance. Common areas include
                    swimming pools, tennis courts, and other recreational
                    facilities, as well as common corridors of buildings,
                    parking areas, means of ingress and egress, etc.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="communityhome">
                        Community Home Improvement Mortgage Loan
                      </button>
                    </strong>
                    <br clear="all" /> An alternative financing option that
                    allows low- and moderate-income home buyers to obtain 95
                    percent financing for the purchase and improvement of a home
                    in need of modest repairs. The repair work can account for
                    as much as 30 percent of the appraised value.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="communityprop">
                        community property
                      </button>
                    </strong>
                    <br clear="all" /> In some western and southwestern states,
                    a form of ownership under which property acquired during a
                    marriage is presumed to be owned jointly unless acquired as
                    separate property of either spouse.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="comparables">
                        comparables
                      </button>
                    </strong>
                    <br clear="all" /> An abbreviation for "comparable
                    properties"; used for comparative purposes in the appraisal
                    process. Comparables are properties like the property under
                    consideration; they have reasonably the same size, location,
                    and amenities and have recently been sold. Comparables help
                    the appraiser determine the approximate fair market value of
                    the subject property.
                  </p>
                  <p>
                    <strong>
                      <button
                        style={{ color: '#363636' }}
                        name="compoundinterest"
                      >
                        compound interest
                      </button>
                    </strong>
                    <br clear="all" /> E-LOAN CDs and Savings accounts compound
                    interest daily. This refers to any interest earned on an
                    account holder's principal balance, as well as any prior
                    interest.
                  </p>
                  <p>
                    <strong>
                      <button
                        style={{ color: '#363636' }}
                        name="condoconversion"
                      >
                        condominium conversion
                      </button>
                    </strong>
                    <br clear="all" /> Changing the ownership of an existing
                    building (usually a rental project) to the condominium form
                    of ownership.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="conforming">
                        conforming loan
                      </button>
                    </strong>
                    <br clear="all" /> The current conforming loan limit is
                    $417,000 and below. Conforming loan limits change annually.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="construction">
                        construction loan
                      </button>
                    </strong>
                    <br clear="all" /> A short-term, interim loan for financing
                    the cost of construction. The lender makes payments to the
                    builder at periodic intervals as the work progresses.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="consumer">
                        consumer reporting agency (or bureau)
                      </button>
                    </strong>
                    <br clear="all" /> An organization that prepares reports
                    that are used by lenders to determine a potential borrower's
                    credit history. The agency obtains data for these reports
                    from a credit repository as well as from other sources.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="contingency">
                        contingency
                      </button>
                    </strong>
                    <br clear="all" /> A condition that must be met before a
                    contract is legally binding. For example, home purchasers
                    often include a contingency that specifies that the contract
                    is not binding until the purchaser obtains a satisfactory
                    home inspection report from a qualified home inspector.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="conventional">
                        conventional mortgage
                      </button>
                    </strong>
                    <br clear="all" /> A mortgage that is not insured or
                    guaranteed by the federal government.
                  </p>
                  <p>
                    <strong>
                      <button
                        style={{ color: '#363636' }}
                        name="convertibility"
                      >
                        convertibility clause
                      </button>
                    </strong>
                    <br clear="all" /> A provision in some adjustable-rate
                    mortgages (ARMs) that allows the borrower to change the ARM
                    to a fixed-rate mortgage at specified timeframes after loan
                    origination.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="convertARM">
                        convertible ARM
                      </button>
                    </strong>
                    <br clear="all" /> An adjustable-rate mortgage (ARM) that
                    can be converted to a fixed-rate mortgage under specified
                    conditions.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="co-op">
                        cooperative (co-op)
                      </button>
                    </strong>
                    <br clear="all" /> A type of multiple ownership in which the
                    residents of a multiunit housing complex own shares in the
                    cooperative corporation that owns the property, giving each
                    resident the right to occupy a specific apartment or unit.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="relocation">
                        corporate relocation
                      </button>
                    </strong>
                    <br clear="all" /> Arrangements under which an employer
                    moves an employee to another area as part of the employer's
                    normal course of business or under which it transfers a
                    substantial part or all of its operations and employees to
                    another area because it is relocating its headquarters or
                    expanding its office capacity.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="COFI">
                        cost of funds index (COFI)
                      </button>
                    </strong>
                    <br clear="all" /> An index that is used to determine
                    interest rate changes for certain adjustable-rate mortgage
                    (ARM) plans. It represents the weighted-average cost of
                    savings, borrowings, and advances of the 11th District
                    members of the Federal Home Loan Bank of San Francisco.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="covenant">
                        covenant
                      </button>
                    </strong>
                    <br clear="all" /> A clause in a mortgage that obligates or
                    restricts the borrower and that, if violated, can result in
                    foreclosure.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="repository">
                        credit
                      </button>
                      repository
                    </strong>
                    <br clear="all" /> An organization that gathers, records,
                    updates, and stores financial and public records information
                    about the payment records of individuals who are being
                    considered for credit.
                  </p>
                  <br />
                  <p className="pageTop">
                    <a href="#top" className="button">
                      Page Top
                    </a>
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <button style={{ color: '#363636' }} name="D">
                    D
                  </button>
                  <img
                    alt=""
                    src="https://assets.site-static.com/userFiles/template_45/image/spacer.gif"
                    width="60"
                    height="60"
                    border="0"
                  />
                </td>
                <td>
                  <p>
                    <strong>
                      <br />
                    </strong>
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="deed">
                        deed
                      </button>
                    </strong>
                    <br clear="all" /> The legal document conveying title to a
                    property.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="deedinlieu">
                        deed in lieu
                      </button>
                    </strong>
                    <br clear="all" /> A deed given by a mortgagor to the
                    mortgagee to satisfy a debt and avoid foreclosure.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="deedoftrust">
                        deed of trust
                      </button>
                    </strong>
                    <br clear="all" /> The document used in some states instead
                    of a mortgage; title is conveyed to a trustee.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="default">
                        default
                      </button>
                    </strong>
                    <br clear="all" /> Failure to make mortgage payments on a
                    timely basis or to comply with other requirements of a
                    mortgage.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="delinquency">
                        delinquency
                      </button>
                    </strong>
                    <br clear="all" /> Failure to make mortgage payments when
                    mortgage payments are due.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="depreciation">
                        depreciation
                      </button>
                    </strong>
                    <br clear="all" /> A decline in the value of property; the
                    opposite of appreciation.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="dueonsale">
                        due-on-sale provision
                      </button>
                    </strong>
                    <br clear="all" /> A provision in a mortgage that allows the
                    lender to demand repayment in full if the borrower sells the
                    property that serves as security for the mortgage.
                  </p>
                  <br />
                  <p className="pageTop">
                    <a href="#top" className="button">
                      Page Top
                    </a>
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <button style={{ color: '#363636' }} name="E">
                    E
                  </button>
                  <img
                    alt=""
                    src="https://assets.site-static.com/userFiles/template_45/image/spacer.gif"
                    width="60"
                    height="60"
                    border="0"
                  />
                </td>
                <td>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="earnest">
                        earnest money deposit
                      </button>
                    </strong>
                    <br clear="all" /> A deposit made by the potential home
                    buyer to show that he or she is serious about buying the
                    house.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="easement">
                        easement
                      </button>
                    </strong>
                    <br clear="all" /> A right of way giving persons other than
                    the owner access to or over a property.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="effectiveage">
                        effective age
                      </button>
                    </strong>
                    <br clear="all" /> An appraiser's estimate of the physical
                    condition of a building. The actual age of a building may be
                    shorter or longer than its effective age.
                  </p>
                  <p>
                    <strong>
                      <button
                        style={{ color: '#363636' }}
                        name="effectivegross"
                      >
                        effective gross income
                      </button>
                    </strong>
                    <br clear="all" /> Normal annual income including overtime
                    that is regular or guaranteed. The income may be from more
                    than one source. Salary is generally the principal source,
                    but other income may qualify if it is significant and
                    stable.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="EFT">
                        electronic funds transfer (EFT)
                      </button>
                    </strong>
                    <br clear="all" /> EFT allows account holders to transfer
                    funds from an account electronically. This method of
                    transfer is not only highly secure, but also extremely
                    efficient and easy to transact.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="encumbrance">
                        encumbrance
                      </button>
                    </strong>
                    <br clear="all" /> Anything that affects or limits the fee
                    simple title to a property, such as mortgages, leases,
                    easements, or restrictions.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="endorser">
                        endorser
                      </button>
                    </strong>
                    <br clear="all" /> A person who signs ownership interest
                    over to another party. Contrast with co-maker.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="ECOA">
                        Equal Credit Opportunity Act (ECOA)
                      </button>
                    </strong>
                    <br clear="all" /> A federal law that requires lenders and
                    other creditors to make credit equally available without
                    discrimination based on race, color, religion, national
                    origin, age, sex, marital status, or receipt of income from
                    public assistance programs.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="equity">
                        equity
                      </button>
                    </strong>
                    <br clear="all" /> A homeowner's financial interest in a
                    property. Equity is the difference between the fair market
                    value of the property and the amount still owed on its
                    mortgage.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="escrow">
                        escrow
                      </button>
                    </strong>
                    <br clear="all" /> An item of value, money, or documents
                    deposited with a third party to be delivered upon the
                    fulfillment of a condition. For example, the deposit by a
                    borrower with the lender of funds to pay taxes and insurance
                    premiums when they become due, or the deposit of funds or
                    documents with an attorney or escrow agent to be disbursed
                    upon the closing of a sale of real estate.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="escrowaccount">
                        escrow account
                      </button>
                    </strong>
                    <br clear="all" /> The account in which a mortgage servicer
                    holds the borrower's escrow payments prior to paying
                    property expenses.
                  </p>
                  <p>
                    <strong>
                      <button
                        style={{ color: '#363636' }}
                        name="escrowanalysis"
                      >
                        escrow analysis
                      </button>
                    </strong>
                    <br clear="all" /> The periodic examination of escrow
                    accounts to determine if current monthly deposits will
                    provide sufficient funds to pay taxes, insurance, and other
                    bills when due.
                  </p>
                  <p>
                    <strong>
                      <button
                        style={{ color: '#363636' }}
                        name="escrowcollections"
                      >
                        escrow collections
                      </button>
                    </strong>
                    <br clear="all" /> Funds collected by the servicer and set
                    aside in an escrow account to pay the borrower's property
                    taxes, mortgage insurance, and hazard insurance.
                  </p>
                  <p>
                    <strong>
                      <button
                        style={{ color: '#363636' }}
                        name="escrowdisbursement"
                      >
                        escrow disbursements
                      </button>
                    </strong>
                    <br clear="all" /> The use of escrow funds to pay real
                    estate taxes, hazard insurance, mortgage insurance, and
                    other property expenses as they become due.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="escrowpayment">
                        escrow payment
                      </button>
                    </strong>
                    <br clear="all" /> The portion of a mortgagor's monthly
                    payment that is held by the servicer to pay for taxes,
                    hazard insurance, mortgage insurance, lease payments, and
                    other items as they become due. Known as "impounds" or
                    "reserves" in some states.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="estate">
                        estate
                      </button>
                    </strong>
                    <br clear="all" /> The ownership interest of an individual
                    in real property. The sum total of all the real property and
                    personal property owned by an individual at time of death.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="eviction">
                        eviction
                      </button>
                    </strong>
                    <br clear="all" /> The lawful expulsion of an occupant from
                    real property.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="examination">
                        examination of title
                      </button>
                    </strong>
                    <br clear="all" /> The report on the title of a property
                    from the public records or an abstract of the title.
                  </p>
                  <br />
                  <p className="pageTop">
                    <a href="#top" className="button">
                      Page Top
                    </a>
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <button style={{ color: '#363636' }} name="F">
                    F
                  </button>
                  <img
                    alt=""
                    src="https://assets.site-static.com/userFiles/template_45/image/spacer.gif"
                    width="60"
                    height="60"
                    border="0"
                  />
                </td>
                <td>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="faircredit">
                        Fair Credit Reporting Act
                      </button>
                    </strong>
                    <br clear="all" /> A consumer protection law that regulates
                    the disclosure of consumer credit reports by consumer/credit
                    reporting agencies and establishes procedures for correcting
                    mistakes on one's credit record.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="fairmarket">
                        fair market value
                      </button>
                    </strong>
                    <br clear="all" /> The highest price that a buyer, willing
                    but not compelled to buy, would pay, and the lowest a
                    seller, willing but not compelled to sell, would accept.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="fanniemae">
                        Fannie Mae
                      </button>
                    </strong>
                    <br clear="all" /> A congressionally chartered,
                    shareholder-owned company that is the nation's largest
                    supplier of home mortgage funds.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="fannieprogram">
                        Fannie Mae's Community Home Buyer's Program
                      </button>
                    </strong>
                    <br clear="all" /> An income-based community lending model,
                    under which mortgage insurers and Fannie Mae offer flexible
                    underwriting guidelines to increase a low- or
                    moderate-income family's buying power and to decrease the
                    total amount of cash needed to purchase a home. Borrowers
                    who participate in this model are required to attend
                    pre-purchase home-buyer education sessions.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="feesimple">
                        fee simple
                      </button>
                    </strong>
                    <br clear="all" /> The greatest possible interest a person
                    can have in real estate.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="FHA">
                        Federal Housing Administration (FHA)
                      </button>
                    </strong>
                    <br clear="all" /> An agency of the U.S. Department of
                    Housing and Urban Development (HUD). Its main activity is
                    the insuring of residential mortgage loans made by private
                    lenders. The FHA sets standards for construction and
                    underwriting but does not lend money or plan or construct
                    housing.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="FHAmortgage">
                        FHA mortgage
                      </button>
                    </strong>
                    <br clear="all" /> A mortgage that is insured by the Federal
                    Housing Administration (FHA). Also known as a government
                    mortgage.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="finder">
                        finder's fee
                      </button>
                    </strong>
                    <br clear="all" /> A fee or commission paid to a mortgage
                    broker for finding a mortgage loan for a prospective
                    borrower.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="first">
                        first adjustment
                      </button>
                    </strong>
                    <br clear="all" /> When you can expect the first rate
                    adjustment in your ARM loan.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="firstmort">
                        first mortgage
                      </button>
                    </strong>
                    <br clear="all" /> A mortgage that is the primary lien
                    against a property.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="float">
                        float down option
                      </button>
                    </strong>
                    <br clear="all" /> An option to choose a lower rate within
                    30 days before the closing of your loan and "float down" to
                    a lower rate than the previously locked-in rate. This allows
                    you to pick the best rate within that time period.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="FRM">
                        fixed-rate mortgage (FRM)
                      </button>
                    </strong>
                    <br clear="all" /> A mortgage in which the interest rate
                    does not change during the entire term of the loan.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="fixedsecond">
                        fixed second mortgage
                      </button>
                    </strong>
                    <br clear="all" /> <em>See home equity loan.</em>
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="flood">
                        flood insurance
                      </button>
                    </strong>
                    <br clear="all" /> Insurance that compensates for physical
                    property damage resulting from flooding. It is required for
                    properties located in federally designated flood areas.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="foreclosure">
                        foreclosure
                      </button>
                    </strong>
                    <br clear="all" /> The legal process by which a borrower in
                    default under a mortgage is deprived of his or her interest
                    in the mortgaged property. This usually involves a forced
                    sale of the property at public auction with the proceeds of
                    the sale being applied to the mortgage debt.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="fullyamorized">
                        fully amortized ARM
                      </button>
                    </strong>
                    <br clear="all" /> An adjustable-rate mortgage (ARM) with a
                    monthly payment that is sufficient to amortize the remaining
                    balance, at the interest accrual rate, over the amortization
                    term.
                  </p>
                  <br />
                  <p className="pageTop">
                    <a href="#top" className="button">
                      Page Top
                    </a>
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <button style={{ color: '#363636' }} name="G">
                    G
                  </button>
                  <img
                    alt=""
                    src="https://assets.site-static.com/userFiles/template_45/image/spacer.gif"
                    width="60"
                    height="60"
                    border="0"
                  />
                </td>
                <td>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="goodfaith">
                        good faith estimate
                      </button>
                    </strong>
                    <br clear="all" /> An estimate of charges which a borrower
                    is likely to incur in connection with a settlement.
                  </p>
                  <br />
                  <p className="pageTop">
                    <a href="#top" className="button">
                      Page Top
                    </a>
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <button style={{ color: '#363636' }} name="H">
                    H
                  </button>
                  <img
                    alt=""
                    src="https://assets.site-static.com/userFiles/template_45/image/spacer.gif"
                    width="60"
                    height="60"
                    border="0"
                  />
                </td>
                <td>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="hazard">
                        hazard insurance
                      </button>
                    </strong>
                    <br clear="all" /> Insurance protecting against loss to real
                    estate caused by fire, some natural causes, vandalism, etc.,
                    depending upon the terms of the policy.
                  </p>
                  <p>
                    <strong>
                      <button
                        style={{ color: '#363636' }}
                        name="homeequitycredit"
                      >
                        home equity line of credit
                      </button>
                    </strong>
                    <br clear="all" /> a credit line that is secured by a second
                    deed of trust on a house. Equity lines of credit are
                    revolving accounts that work like a credit card, which can
                    be paid down or charged up for the term of the loan. The
                    minimum payment due each month is interest only.
                  </p>
                  <p>
                    <strong>
                      <button
                        style={{ color: '#363636' }}
                        name="homeequityloan"
                      >
                        home equity loan
                      </button>
                    </strong>
                    <br clear="all" /> a loan secured by a second deed of trust
                    on a house, typically used as a home improvement loan.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="ratio">
                        housing ratio
                      </button>
                    </strong>
                    <br clear="all" /> The ratio of the monthly housing payment
                    in total (PITI - Principal, Interest, Taxes, and Insurance)
                    divided by the gross monthly income. This ratio is sometimes
                    referred to as the top ratio or front end ratio.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="HUD">
                        HUD
                      </button>
                      <br clear="all" />
                    </strong>
                    The U.S. Department of Housing and Urban Development.
                  </p>
                  <br />
                  <p className="pageTop">
                    <a href="#top" className="button">
                      Page Top
                    </a>
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <button style={{ color: '#363636' }} name="I">
                    I
                  </button>
                  <img
                    alt=""
                    src="https://assets.site-static.com/userFiles/template_45/image/spacer.gif"
                    width="60"
                    height="60"
                    border="0"
                  />
                </td>
                <td>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="index">
                        index
                      </button>
                    </strong>
                    <br clear="all" /> A published interest rate to which the
                    interest rate on an Adjustable Rate Mortgage (ARM) is tied.
                    Some commonly used indices include the 1 Year Treasury Bill,
                    6 Month LIBOR, and the 11th District Cost of Funds (COFI).
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="impound">
                        impound
                      </button>
                      account
                    </strong>
                    <br clear="all" /> An impound account is an account
                    established by the lender to pay a borrower's tax and
                    insurance costs. The borrower's monthly mortgage payment is
                    then increased to cover these costs, with the additional
                    amount being held in the impound account and disbursed by
                    the lender when the payments are due. Lenders typically
                    prefer this arrangement because it reduces the possibility
                    of a lapse in tax or insurance payments that could diminish
                    the value of the lender's investment (your house).
                    Therefore, while it is often possible to opt out of an
                    impound account it will result in additional charges.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="interestonly">
                        interest-only loan option
                      </button>
                    </strong>
                    <br clear="all" /> Loan payments have two components,
                    principal and interest. An interest-only loan has no
                    principal component for a specified period of time. These
                    special loans minimize your monthly payments by eliminating
                    the need to pay down your balance during the interest-only
                    period, giving you greater cash flow control and/or
                    increased purchasing power.
                  </p>
                  <p className="pageTop">
                    <a href="#top" className="button">
                      Page Top
                    </a>
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <button style={{ color: '#363636' }} name="J">
                    J
                  </button>
                  <img
                    alt=""
                    src="https://assets.site-static.com/userFiles/template_45/image/spacer.gif"
                    width="60"
                    height="60"
                    border="0"
                  />
                </td>
                <td>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="jumbo">
                        jumbo mortgage
                      </button>
                    </strong>
                    <br clear="all" /> The current loan limit for a conforming
                    loan is $417,000. Loan amounts of $359,651 and above are
                    considered non-conforming or jumbo mortgages and are usually
                    subject to higher pricing.
                  </p>
                  <br />
                  <p className="pageTop">
                    <a href="#top" className="button">
                      Page Top
                    </a>
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <button style={{ color: '#363636' }} name="L">
                    L
                  </button>
                  <img
                    alt=""
                    src="https://assets.site-static.com/userFiles/template_45/image/spacer.gif"
                    width="60"
                    height="60"
                    border="0"
                  />
                </td>
                <td>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="lien">
                        lien
                      </button>
                    </strong>
                    <br clear="all" /> An encumbrance against property for money
                    due, either voluntary or involuntary.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="lender">
                        lender
                      </button>
                    </strong>
                    <br clear="all" /> The bank, mortgage company, or mortgage
                    broker offering the loan.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="libor">
                        LIBOR
                      </button>
                    </strong>
                    <br clear="all" /> LIBOR stands for London Inter-Bank
                    Offered Rate. This is a favorable interest rate offered for
                    U.S. dollar deposits between a group of London banks. There
                    are several different LIBOR rates, defined by the maturity
                    of their deposit. The LIBOR is an international index that
                    follows world economic conditions. LIBOR-indexed ARMs offer
                    borrowers aggressive initial rates and have proven to be
                    competitive with popular ARM indexes like the Treasury bill.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="life">
                        lifetime cap
                      </button>
                    </strong>
                    <br clear="all" /> A provision of an ARM that limits the
                    highest rate that can occur over the life of the loan.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="LTV">
                        loan to value ratio (LTV)
                      </button>
                    </strong>
                    <br clear="all" /> The unpaid principal balance of the
                    mortgage on a property divided by the property's appraised
                    value. The LTV will affect programs available to the
                    borrower and generally, the lower the LTV the more favorable
                    the terms of the programs offered by lenders.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="lock">
                        lock period
                      </button>
                    </strong>
                    <br clear="all" /> The amount of time that a lender will
                    guarantee a loan's interest rate. Once you've locked in the
                    interest rate on a loan, the lender will guarantee that rate
                    for a certain period of time, usually for 30, 45 or 60 days.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="lockin">
                        lock-in
                      </button>
                    </strong>
                    <br clear="all" /> A written agreement guaranteeing the home
                    buyer a specified interest rate provided the loan is closed
                    within a set period of time. The lock-in also usually
                    specifies the number of points to be paid at closing.
                  </p>
                  <br />
                  <p className="pageTop">
                    <a href="#top" className="button">
                      Page Top
                    </a>
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <button style={{ color: '#363636' }} name="M">
                    M
                  </button>
                  <img
                    alt=""
                    src="https://assets.site-static.com/userFiles/template_45/image/spacer.gif"
                    width="60"
                    height="60"
                    border="0"
                  />
                </td>
                <td>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="margin">
                        margin
                      </button>
                    </strong>
                    <br clear="all" /> The number of percentage points a lender
                    adds to the index value to calculate the ARM interest rate
                    at each adjustment period.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="maturitydate">
                        maturity date
                      </button>
                    </strong>
                    <br clear="all" /> A pre-set date informing account owners
                    when they can withdraw principal funds without incurring a
                    penalty. (Please note that you may withdraw any generated
                    interest before reaching an account's maturity date at
                    E-LOAN.)
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="mortgage">
                        mortgage
                      </button>
                    </strong>
                    <br clear="all" /> A legal document that pledges a property
                    to the lender as security for payment of a debt
                  </p>
                  <p>
                    <strong>
                      <button
                        style={{ color: '#363636' }}
                        name="mortgagedisins"
                      >
                        mortgage disability insurance
                      </button>
                    </strong>
                    <br clear="all" /> A disability insurance policy which will
                    pay the monthly mortgage payment in the event of a covered
                    disability of an insured borrower for a specified period of
                    time.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="MI">
                        mortgage insurance (MI)
                      </button>
                    </strong>
                    <br clear="all" /> Insurance written by an independent
                    mortgage insurance company protecting the mortgage lender
                    against loss incurred by a mortgage default. Usually
                    required for loans with an LTV of 80.01% or higher.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="mortgagee">
                        mortgagee
                      </button>
                    </strong>
                    <br clear="all" /> The person or company who receives the
                    mortgage as a pledge for repayment of the loan. The mortgage
                    lender.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="mortgagor">
                        mortgagor
                      </button>
                    </strong>
                    <br clear="all" /> The mortgage borrower who gives the
                    mortgage as a pledge to repay.
                  </p>
                  <br />
                  <p className="pageTop">
                    <a href="#top" className="button">
                      Page Top
                    </a>
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <button style={{ color: '#363636' }} name="N">
                    N
                  </button>
                  <img
                    alt=""
                    src="https://assets.site-static.com/userFiles/template_45/image/spacer.gif"
                    width="60"
                    height="60"
                    border="0"
                  />
                </td>
                <td>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="negam">
                        negative amortization
                      </button>
                    </strong>
                    <br /> Negative Amortization, or "deferred interest," occurs
                    when the mortgage payment is less than a loan's accruing
                    interest. This causes a loan's balance to grow instead of
                    reduce or "amortize."
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="nonconform">
                        non-conforming loan
                      </button>
                    </strong>
                    <br clear="all" /> Also called a jumbo loan. Conventional
                    home mortgages not eligible for sale and delivery to either
                    Fannie Mae (FNMA) or Freddie Mac (FHLMC) because of various
                    reasons, including loan amount, loan characteristics or
                    underwriting guidelines. Non-conforming loans usually incur
                    a rate and origination fee premium. The current
                    non-conforming loan limit is $333,701 and above.
                  </p>
                  <br />
                  <p className="pageTop">
                    <a href="#top" className="button">
                      Page Top
                    </a>
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <button style={{ color: '#363636' }} name="O">
                    O
                  </button>
                  <img
                    alt=""
                    src="https://assets.site-static.com/userFiles/template_45/image/spacer.gif"
                    width="60"
                    height="60"
                    border="0"
                  />
                </td>
                <td>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="origfee">
                        origination fee
                      </button>
                    </strong>
                    <br clear="all" /> A fee imposed by a lender to cover
                    certain processing expenses in connection with making a real
                    estate loan. Usually a percentage of the amount loaned, such
                    as one percent.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="ownerfin">
                        owner financing
                      </button>
                    </strong>
                    <br clear="all" /> A property purchase transaction in which
                    the property seller provides all or part of the financing.
                  </p>
                  <br />
                  <p className="pageTop">
                    <a href="#top" className="button">
                      Page Top
                    </a>
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <button style={{ color: '#363636' }} name="P">
                    P
                  </button>
                  <img
                    alt=""
                    src="https://assets.site-static.com/userFiles/template_45/image/spacer.gif"
                    width="60"
                    height="60"
                    border="0"
                  />
                </td>
                <td>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="periodic">
                        periodic cap
                      </button>
                    </strong>
                    <br clear="all" /> The maximum rate increase for a specific
                    period for a specific loan (ARM) only.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="PITI">
                        PITI
                      </button>
                    </strong>
                    <br clear="all" /> Principal, interest, taxes and
                    insurance--the components of a monthly mortgage payment.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="PUD">
                        planned unit developments (PUD)
                      </button>
                    </strong>
                    <br clear="all" /> A subdivision of five or more
                    individually owned lots with one or more other parcels owned
                    in common or with reciprocal rights in one or more other
                    parcels.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="points">
                        points
                      </button>
                    </strong>
                    <br clear="all" /> Charges levied by the mortgage lender and
                    usually payable at closing. One point represents 1% of the
                    face value of the mortgage loan.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="prepaids">
                        prepaids
                      </button>
                    </strong>
                    <br clear="all" /> Those expenses of property which are paid
                    in advance of their due date and will usually be prorated
                    upon sale, such as taxes, insurance, rent, etc.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="prepay">
                        prepayment penalty
                      </button>
                    </strong>
                    <br clear="all" /> A charge imposed by a mortgage lender on
                    a borrower who wants to pay off part or all of a mortgage
                    loan in advance of schedule.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="principal">
                        principal
                      </button>
                    </strong>
                    <br clear="all" /> This term refers to the total amount of
                    money originally deposited into a Savings or CD account.
                    When taking out a loan however, it refers to the amount of
                    debt, not including interest.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="PMI">
                        private mortgage insurance (PMI)
                      </button>
                    </strong>
                    <br clear="all" /> Insurance provided by nongovernment
                    insurers that protects lenders against loss if a borrower
                    defaults. Fannie Mae generally requires private mortgage
                    insurance for loans with loan-to-value (LTV) percentages
                    greater than 80%.
                  </p>
                  <br />
                  <p className="pageTop">
                    <a href="#top" className="button">
                      Page Top
                    </a>
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <button style={{ color: '#363636' }} name="Q">
                    Q
                  </button>
                  <img
                    alt=""
                    src="https://assets.site-static.com/userFiles/template_45/image/spacer.gif"
                    width="60"
                    height="60"
                    border="0"
                  />
                </td>
                <td>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="qualifying">
                        qualifying ratios
                      </button>
                    </strong>
                    <br clear="all" /> The ratio of your fixed monthly expenses
                    to your gross monthly income, used to determine how much you
                    can afford to borrow. The fixed monthly expenses would
                    include PITI along with other obligations such as student
                    loans, car loans, or credit card payments.
                  </p>
                  <br />
                  <p className="pageTop">
                    <a href="#top" className="button">
                      Page Top
                    </a>
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <button style={{ color: '#363636' }} name="R">
                    R
                  </button>
                  <img
                    alt=""
                    src="https://assets.site-static.com/userFiles/template_45/image/spacer.gif"
                    width="60"
                    height="60"
                    border="0"
                  />
                </td>
                <td>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="rate">
                        rate
                      </button>
                    </strong>
                    <br clear="all" /> The annual rate of interest on a loan,
                    expressed as a percentage of 100.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="ratecap">
                        rate cap
                      </button>
                    </strong>
                    <br clear="all" /> A limit on how much the interest rate can
                    change, either at each adjustment period or over the life of
                    the loan.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="ratelock">
                        rate lock-in
                      </button>
                    </strong>
                    <br clear="all" /> A written agreement in which the lender
                    guarantees the borrower a specified interest rate, provided
                    the loan closes within a set period of time.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="rebate">
                        rebate
                      </button>
                    </strong>
                    <br clear="all" /> Compensation received from a wholesale
                    lender which can be used to cover closing costs or as a
                    refund to the borrower. Loans with rebates often carry
                    higher interest rates than loans with "points" (see above).
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="refi">
                        refinancing
                      </button>
                    </strong>
                    <br clear="all" /> The process of paying off one loan with
                    the proceeds from a new loan using the same property as
                    security.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="RMCR">
                        residential mortgage credit report (RMCR)
                      </button>
                    </strong>
                    <br clear="all" /> A report requested by your lender that
                    utilizes information from at least two of the three national
                    credit bureaus and information provided on your loan
                    application.
                  </p>
                  <br />
                  <p className="pageTop">
                    <a href="#top" className="button">
                      Page Top
                    </a>
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <button style={{ color: '#363636' }} name="S">
                    S
                  </button>
                  <img
                    alt=""
                    src="https://assets.site-static.com/userFiles/template_45/image/spacer.gif"
                    width="60"
                    height="60"
                    border="0"
                  />
                </td>
                <td>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="seller">
                        seller carry back
                      </button>
                    </strong>
                    <br clear="all" /> An agreement in which the owner of a
                    property provides financing, often in combination with an
                    assumed mortgage.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="seller">
                        simple interest
                      </button>
                    </strong>
                    <br clear="all" /> An amount earned on an account holder's
                    principal, according to a specified rate. This does not
                    include any compounding interest.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="statedinc">
                        stated/documented income
                      </button>
                    </strong>
                    <br clear="all" /> Some loan products require only that
                    applicants "state" the source of their income without
                    providing supporting documentation such as tax returns.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="subordination">
                        subordination
                      </button>
                    </strong>
                    <br clear="all" /> If you are refinancing your first
                    mortgage and have an existing second or home equity line,
                    one option is to "subordinate" the second mortgage: request
                    that your second mortgage holder go back into the second
                    lien position when you replace your existing first mortgage
                    with the new refinance loan.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="survey">
                        survey
                      </button>
                    </strong>
                    <br clear="all" /> A print showing the measurements of the
                    boundaries of a parcel of land, together with the location
                    of all improvements on the land and sometimes its area and
                    topography.
                  </p>
                  <br />
                  <p className="pageTop">
                    <a href="#top" className="button">
                      Page Top
                    </a>
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <button style={{ color: '#363636' }} name="T">
                    T
                  </button>
                  <img
                    alt=""
                    src="https://assets.site-static.com/userFiles/template_45/image/spacer.gif"
                    width="60"
                    height="60"
                    border="0"
                  />
                </td>
                <td>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="tenants">
                        tenants in common
                      </button>
                    </strong>
                    <br clear="all" /> An undivided interest in property taken
                    by two or more persons. The interest need not be equal. Upon
                    death of one or more persons, there is no right of
                    survivorship.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="titleins">
                        title insurance
                      </button>
                    </strong>
                    <br clear="all" /> Insurance against loss resulting from
                    defects of title to a specifically described parcel of real
                    property.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="titlesearch">
                        title search
                      </button>
                    </strong>
                    <br clear="all" /> An investigation into the history of
                    ownership of a property to check for liens, unpaid claims,
                    restrictions or problems, to prove that the seller can
                    transfer free and clear ownership.
                  </p>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="totaldebt">
                        total debt ratio
                      </button>
                    </strong>
                    <br clear="all" /> Monthly debt and housing payments divided
                    by gross monthly income. Also known as Obligations-to-Income
                    Ratio or Back-End Ratio.
                  </p>
                  <br />
                  <p className="pageTop">
                    <a href="#top" className="button">
                      Page Top
                    </a>
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <button style={{ color: '#363636' }} name="V">
                    V
                  </button>
                  <img
                    alt=""
                    src="https://assets.site-static.com/userFiles/template_45/image/spacer.gif"
                    width="60"
                    height="60"
                    border="0"
                  />
                </td>
                <td>
                  <p>
                    <strong>
                      <button style={{ color: '#363636' }} name="variablerate">
                        variable rate
                      </button>
                    </strong>
                    <br clear="all" /> An interest rate that may change once an
                    account opens.
                  </p>
                  <p className="pageTop">
                    <a href="#top" className="button">
                      Page Top
                    </a>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Sidebar />
    </>
  )
}

export default FinancialTermsGlossaryPage
