import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'

import { connect } from 'react-redux'
import { updatePropertySearchObject } from '../../../../store/actions/update-property-search-object-action'

import ListingsCounter from './Listings-counter'
import PropertyStatus from './form-blocks/Property-status'
import SearchByLocationType from './form-blocks/Search-by-location-type'
import PriceRange from './form-blocks/Price-range'
import SquareFeet from './form-blocks/Square-feet'
import LotSize from './form-blocks/Lot-size'
import Bedrooms from './form-blocks/Bedrooms'
import Bathrooms from './form-blocks/Bathrooms'
import YearBuild from './form-blocks/Year-build'
import HOAFee from './form-blocks/Hoa-fee'
import GarageSpace from './form-blocks/Garage-space'
import AdditionalFeatures from './form-blocks/Additional-features'
import ExtConditions from './form-blocks/Ext-conditions'
import ShowOnly from './form-blocks/Show-only'

import InputCheckBox from './inputs/Input-check-box'

import './property-search-form.css'
const styleDispalyNone = {
  display: 'none',
}

const defaultSelectedItemsObject = {
  cities: [],
  subdivisions: [],
  counties: [],
  areas: [],
  elementarySchools: [],
  middleSchools: [],
  highSchool: [],
  zipCodes: [],
}

const PropertySearchForm = (props) => {
  // main search string
  const [mainSearchString, setMainSearchString] = useState('')
  const ChangeMainSearchStringHandler = (evt) =>
    setMainSearchString(evt.target.value)

  // Property Type
  const [isResidential, setIsResidential] = useState(true)
  const ChangeIsResidentialHandler = (evt) => setIsResidential(!isResidential)

  const [isCommercial, setIsCommercial] = useState(false)
  const ChangeIsCommercialHandler = (evt) => setIsCommercial(!isCommercial)

  const [isLotsLand, setIsLotsLand] = useState(false)
  const ChangeIsLotsLandHandler = (evt) => setIsLotsLand(!isLotsLand)

  const [isMultiFamily, setIsMultiFamily] = useState(false)
  const ChangeIsMultiFamilyHandler = (evt) => setIsMultiFamily(!isMultiFamily)

  const [isFractional, setIsFractional] = useState(false)
  const ChangeIsFractionalHandler = (evt) => setIsFractional(!isFractional)

  useEffect(() => {
    if (
      !isResidential &&
      !isCommercial &&
      !isLotsLand &&
      !isMultiFamily &&
      !isFractional
    ) {
      setIsResidential(true)
    }
    if (!isResidential) {
      setIsManufacturedHome(false)
      setIsSingleFamily(false)
    }
    if (!isResidential && !isFractional) {
      setIsCondoTownhomes(false)
    }
    if (!isCommercial) {
      setIsCommercialCommercial(false)
    }
    if (!isLotsLand) {
      setIsLotsLandLand(false)
    }
    if (!isMultiFamily) {
      setIsMultiMultiFamily(false)
    }
  }, [isResidential, isCommercial, isLotsLand, isMultiFamily, isFractional])

  // Property Sub-Type
  const [isCondoTownhomes, setIsCondoTownhomes] = useState(false)
  const ChangeIsCondoTownhomesHandler = (evt) =>
    setIsCondoTownhomes(!isCondoTownhomes)

  const [isManufacturedHome, setIsManufacturedHome] = useState(false)
  const ChangeIsManufacturedHomeHandler = (evt) =>
    setIsManufacturedHome(!isManufacturedHome)

  const [isSingleFamily, setIsSingleFamily] = useState(false)
  const ChangeIsSingleFamilyHandler = (evt) =>
    setIsSingleFamily(!isSingleFamily)

  const [isCommercialCommercial, setIsCommercialCommercial] = useState(false)
  const ChangeIsCommercialCommercialHandler = (evt) =>
    setIsCommercialCommercial(!isCommercialCommercial)

  const [isLotsLandLand, setIsLotsLandLand] = useState(false)
  const ChangeIsLotsLandLandHandler = (evt) =>
    setIsLotsLandLand(!isLotsLandLand)

  const [isMultiMultiFamily, setIsMultiMultiFamily] = useState(false)
  const ChangeIsMultiMultiFamilyHandler = (evt) =>
    setIsMultiMultiFamily(!isMultiMultiFamily)

  // search by location type
  const [selectedItems, setSelectedItems] = useState({
    ...defaultSelectedItemsObject,
  })

  // Price range
  const [minPrice, setMinPrice] = useState('')
  const [maxPrice, setMaxPrice] = useState('')

  // Square Feet
  const [minSquareFeet, setMinSquareFeet] = useState('')
  const [maxSquareFeet, setMaxSquareFeet] = useState('')

  // Lot Size
  const [minLotSize, setMinLotSize] = useState('')
  const [maxLotSize, setMaxLotSize] = useState('')

  // Bedrooms
  const [minBedrooms, setMinBedrooms] = useState('')
  // Bathrooms
  const [minBathrooms, setMinBathrooms] = useState('')

  // YearBuild
  const [minYearBuild, setMinYearBuild] = useState('')
  const [maxYearBuild, setMaxYearBuild] = useState('')

  // HOAFee
  const [minHOAFee, setMinHOAFee] = useState('')
  const [maxHOAFee, setMaxHOAFee] = useState('')

  // GarageSpace
  const [minGarageSpace, setMinGarageSpace] = useState('')

  // AdditionalFeatures
  const [selectedAdditionalFeatures, setSelectedAdditionalFeatures] = useState(
    {}
  )

  // Ext conditions
  const [priceReduction, setPriceReduction] = useState('')
  const [priceReductionDate, setPriceReductionDate] = useState('')
  const [sortResultsBy, setSortResultsBy] = useState('')
  const [daysOnSite, setDaysOnSite] = useState('')

  // Show only

  const [foreclosureProperties, setForeclosureProperties] = useState(false)
  const ChangeForeclosurePropertiesHandler = (evt) =>
    setForeclosureProperties(!foreclosureProperties)

  const [shortSaleProperties, setShortSaleProperties] = useState(false)
  const ChangeShortSalePropertiesHandler = (evt) =>
    setShortSaleProperties(!shortSaleProperties)

  const [newConstructionProperties, setNewConstructionProperties] = useState(
    false
  )
  const ChangeNewConstructionPropertiesHandler = (evt) =>
    setNewConstructionProperties(!newConstructionProperties)

  const getFormSearchData = useCallback(() => {
    const newFormSearchData = {
      mainSearchString,

      // property types
      isResidential,
      isCommercial,
      isLotsLand,
      isMultiFamily,
      isFractional,

      // property sub-types
      isCondoTownhomes,
      isManufacturedHome,
      isSingleFamily,
      isCommercialCommercial,
      isLotsLandLand,
      isMultiMultiFamily,

      // location types
      locationTypes: selectedItems,

      // ranges
      minPrice,
      maxPrice,
      minSquareFeet,
      maxSquareFeet,
      minLotSize,
      maxLotSize,
      minBedrooms,
      minBathrooms,
      minYearBuild,
      maxYearBuild,
      minHOAFee,
      maxHOAFee,
      minGarageSpace,

      // Aditional features
      additionalFeatures: selectedAdditionalFeatures,

      // ext conditions
      priceReduction,
      priceReductionDate,
      sortResultsBy,
      daysOnSite,

      // show only (filter conditions)
      foreclosureProperties,
      shortSaleProperties,
      newConstructionProperties,
    }
    return newFormSearchData
  }, [
    mainSearchString,
    isResidential,
    isCommercial,
    isLotsLand,
    isMultiFamily,
    isFractional,
    isCondoTownhomes,
    isManufacturedHome,
    isSingleFamily,
    isCommercialCommercial,
    isLotsLandLand,
    isMultiMultiFamily,
    selectedItems,
    minPrice,
    maxPrice,
    minSquareFeet,
    maxSquareFeet,
    minLotSize,
    maxLotSize,
    minBedrooms,
    minBathrooms,
    minYearBuild,
    maxYearBuild,
    minHOAFee,
    maxHOAFee,
    minGarageSpace,
    selectedAdditionalFeatures,
    priceReduction,
    priceReductionDate,
    sortResultsBy,
    daysOnSite,
    foreclosureProperties,
    shortSaleProperties,
    newConstructionProperties,
  ])

  const [formSearchData, setFormSearchData] = useState({})

  useEffect(() => {
    setFormSearchData(getFormSearchData())
  }, [
    getFormSearchData,
    mainSearchString,
    isResidential,
    isCommercial,
    isLotsLand,
    isMultiFamily,
    isFractional,
    isCondoTownhomes,
    isManufacturedHome,
    isSingleFamily,
    isCommercialCommercial,
    isLotsLandLand,
    isMultiMultiFamily,
    selectedItems,
    minPrice,
    maxPrice,
    minSquareFeet,
    maxSquareFeet,
    minLotSize,
    maxLotSize,
    minBedrooms,
    minBathrooms,
    minYearBuild,
    maxYearBuild,
    minHOAFee,
    maxHOAFee,
    minGarageSpace,
    selectedAdditionalFeatures,
    priceReduction,
    priceReductionDate,
    sortResultsBy,
    daysOnSite,
    foreclosureProperties,
    shortSaleProperties,
    newConstructionProperties,
  ])

  useEffect(() => {
    props.updatePropertySearchObject(formSearchData)
    // eslint-disable-next-line
  }, [formSearchData])

  const loadFormInputsFromStore = (propertySearch) => {
    setMainSearchString(
      propertySearch.mainSearchString !== undefined
        ? propertySearch.mainSearchString
        : ''
    )
    // property types
    setIsResidential(
      propertySearch.isResidential !== undefined
        ? propertySearch.isResidential
        : true
    )
    setIsCommercial(
      propertySearch.isCommercial !== undefined
        ? propertySearch.isCommercial
        : false
    )
    setIsLotsLand(
      propertySearch.isLotsLand !== undefined
        ? propertySearch.isLotsLand
        : false
    )
    setIsMultiFamily(
      propertySearch.isMultiFamily !== undefined
        ? propertySearch.isMultiFamily
        : false
    )
    setIsFractional(
      propertySearch.isFractional !== undefined
        ? propertySearch.isFractional
        : false
    )

    // property sub types
    setIsCondoTownhomes(
      propertySearch.isCondoTownhomes !== undefined
        ? propertySearch.isCondoTownhomes
        : false
    )

    setIsManufacturedHome(
      propertySearch.isManufacturedHome !== undefined
        ? propertySearch.isManufacturedHome
        : false
    )
    setIsSingleFamily(
      propertySearch.isSingleFamily !== undefined
        ? propertySearch.isSingleFamily
        : false
    )

    setIsCommercialCommercial(
      propertySearch.isCommercialCommercial !== undefined
        ? propertySearch.isCommercialCommercial
        : false
    )

    setIsLotsLandLand(
      propertySearch.isLotsLandLand !== undefined
        ? propertySearch.isLotsLandLand
        : false
    )
    setIsMultiMultiFamily(
      propertySearch.isMultiMultiFamily !== undefined
        ? propertySearch.isMultiMultiFamily
        : false
    )

    // locationTypes
    if (propertySearch.locationTypes !== undefined) {
      setSelectedItems(propertySearch.locationTypes)
    }

    //ranges
    setMinPrice(
      propertySearch.minPrice !== undefined ? propertySearch.minPrice : ''
    )
    setMaxPrice(
      propertySearch.maxPrice !== undefined ? propertySearch.maxPrice : ''
    )
    setMinSquareFeet(
      propertySearch.minSquareFeet !== undefined
        ? propertySearch.minSquareFeet
        : ''
    )
    setMaxSquareFeet(
      propertySearch.maxSquareFeet !== undefined
        ? propertySearch.maxSquareFeet
        : ''
    )

    setMinLotSize(
      propertySearch.minLotSize !== undefined ? propertySearch.minLotSize : ''
    )
    setMaxLotSize(
      propertySearch.maxLotSize !== undefined ? propertySearch.maxLotSize : ''
    )

    setMinBedrooms(
      propertySearch.minBedrooms !== undefined ? propertySearch.minBedrooms : ''
    )

    setMinBathrooms(
      propertySearch.minBathrooms !== undefined
        ? propertySearch.minBathrooms
        : ''
    )
    setMinYearBuild(
      propertySearch.minYearBuild !== undefined
        ? propertySearch.minYearBuild
        : ''
    )
    setMaxYearBuild(
      propertySearch.maxYearBuild !== undefined
        ? propertySearch.maxYearBuild
        : ''
    )
    setMinHOAFee(
      propertySearch.minHOAFee !== undefined ? propertySearch.minHOAFee : ''
    )
    setMaxHOAFee(
      propertySearch.maxHOAFee !== undefined ? propertySearch.maxHOAFee : ''
    )
    setMinGarageSpace(
      propertySearch.minGarageSpace !== undefined
        ? propertySearch.minGarageSpace
        : ''
    )

    // Additional features
    if (propertySearch.additionalFeatures !== undefined) {
      setSelectedAdditionalFeatures(propertySearch.additionalFeatures)
    }

    // ext conditions
    setPriceReduction(
      propertySearch.priceReduction !== undefined
        ? propertySearch.priceReduction
        : ''
    )
    setPriceReductionDate(
      propertySearch.priceReductionDate !== undefined
        ? propertySearch.priceReductionDate
        : ''
    )
    setSortResultsBy(
      propertySearch.sortResultsBy !== undefined
        ? propertySearch.sortResultsBy
        : 'm.DateListed DESC'
    )
    setDaysOnSite(
      propertySearch.daysOnSite !== undefined ? propertySearch.daysOnSite : ''
    )

    // show only (filters)
    setForeclosureProperties(
      propertySearch.foreclosureProperties !== undefined
        ? propertySearch.foreclosureProperties
        : false
    )

    setShortSaleProperties(
      propertySearch.shortSaleProperties !== undefined
        ? propertySearch.shortSaleProperties
        : false
    )
    setNewConstructionProperties(
      propertySearch.newConstructionProperties !== undefined
        ? propertySearch.newConstructionProperties
        : false
    )
  }

  const resetFormFieldsTodefault = () => {
    setMainSearchString('')
    // property types
    setIsResidential(true)
    setIsCommercial(false)
    setIsLotsLand(false)
    setIsMultiFamily(false)
    setIsFractional(false)

    // property sub types
    setIsCondoTownhomes(false)

    setIsManufacturedHome(false)
    setIsSingleFamily(false)

    setIsCommercialCommercial(false)

    setIsLotsLandLand(false)
    setIsMultiMultiFamily(false)

    // locationTypes
    setSelectedItems({ ...defaultSelectedItemsObject })

    //ranges
    setMinPrice('')
    setMaxPrice('')
    setMinSquareFeet('')
    setMaxSquareFeet('')

    setMinLotSize('')
    setMaxLotSize('')

    setMinBedrooms('')

    setMinBathrooms('')
    setMinYearBuild('')
    setMaxYearBuild('')
    setMinHOAFee('')
    setMaxHOAFee('')
    setMinGarageSpace('')

    // Additional features
    setSelectedAdditionalFeatures({})

    // ext conditions
    setPriceReduction('')
    setPriceReductionDate('')
    setSortResultsBy('m.DateListed DESC')
    setDaysOnSite('')

    // show only (filters)
    setForeclosureProperties(false)
    setShortSaleProperties(false)
    setNewConstructionProperties(false)
  }

  useEffect(() => {
    if (props.propertySearch !== undefined) {
      loadFormInputsFromStore(props.propertySearch)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className="js-sf">
      <div className="si-content-area">
        <h1 className="js-title">Advanced Property Search</h1>
      </div>
      <div className="si-container clearfix">
        <div className="si-sf-main">
          <div className="si-sf-qs js-quick-search">
            <div className="input-group">
              <input
                type="text"
                className="si-sf-qs__field js-qs-input ui-autoComplete-input"
                placeholder="Quick Search by Address or MLS Number"
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                value={mainSearchString}
                onChange={ChangeMainSearchStringHandler}
              />
              <span className="input-group-btn">
                <button
                  className="si-sf-qs__btn js-qs-btn"
                  type="submit"
                ></button>
              </span>
            </div>
          </div>

          <div className="js-sf-container">
            <section className="si-sf-section">
              <h2>Property Type</h2>
              <div className="si-sf-check clearfix js-property-types">
                <InputCheckBox
                  id="Residential"
                  value={isResidential}
                  onChange={ChangeIsResidentialHandler}
                  label="Residential"
                  // disabled={isGettingListingCounter}
                />
                <InputCheckBox
                  id="Commercial"
                  value={isCommercial}
                  onChange={ChangeIsCommercialHandler}
                  label="Commercial"
                  // disabled={isGettingListingCounter}
                />

                <InputCheckBox
                  id="LotsLand"
                  value={isLotsLand}
                  onChange={ChangeIsLotsLandHandler}
                  label="Lots / Land"
                  // disabled={isGettingListingCounter}
                />

                <InputCheckBox
                  id="MultiFamily"
                  value={isMultiFamily}
                  onChange={ChangeIsMultiFamilyHandler}
                  label="Multi-Family"
                  // disabled={isGettingListingCounter}
                />

                <InputCheckBox
                  id="Fractional"
                  value={isFractional}
                  onChange={ChangeIsFractionalHandler}
                  label="Fractional"
                  // disabled={isGettingListingCounter}
                />
              </div>
            </section>
            <section className="si-sf-section">
              <h2>Property Sub-Type</h2>
              <div className="si-sf-check clearfix js-property-sub-types">
                {isResidential || isFractional ? (
                  <InputCheckBox
                    id="CondoTownhomes"
                    value={isCondoTownhomes}
                    onChange={ChangeIsCondoTownhomesHandler}
                    label="Residential - Condo/Townhomes"
                  />
                ) : (
                  ''
                )}
                {isResidential ? (
                  <>
                    <InputCheckBox
                      id="ManufacturedHome"
                      value={isManufacturedHome}
                      onChange={ChangeIsManufacturedHomeHandler}
                      label="Residential - Manufactured Home"
                    />

                    <InputCheckBox
                      id="SingleFamily"
                      value={isSingleFamily}
                      onChange={ChangeIsSingleFamilyHandler}
                      label="Residential - Single Family"
                    />
                  </>
                ) : (
                  ''
                )}
                {isCommercial ? (
                  <InputCheckBox
                    id="CommercialCommercial"
                    value={isCommercialCommercial}
                    onChange={ChangeIsCommercialCommercialHandler}
                    label="Commercial"
                  />
                ) : (
                  ''
                )}

                {isLotsLand ? (
                  <InputCheckBox
                    id="LotsLandLand"
                    value={isLotsLandLand}
                    onChange={ChangeIsLotsLandLandHandler}
                    label="Land"
                  />
                ) : (
                  ''
                )}

                {isMultiFamily ? (
                  <InputCheckBox
                    id="MultiMultiFamily"
                    value={isMultiMultiFamily}
                    onChange={ChangeIsMultiMultiFamilyHandler}
                    label="Multi-Family"
                  />
                ) : (
                  ''
                )}
              </div>
            </section>
            <PropertyStatus />
            <SearchByLocationType
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
            />
            <section className="si-sf-section">
              <div className="si-sf-primary si-row js-primary-criteria">
                <PriceRange
                  min={minPrice}
                  setMin={setMinPrice}
                  max={maxPrice}
                  setMax={setMaxPrice}
                />
                <SquareFeet
                  min={minSquareFeet}
                  setMin={setMinSquareFeet}
                  max={maxSquareFeet}
                  setMax={setMaxSquareFeet}
                />
                <LotSize
                  min={minLotSize}
                  setMin={setMinLotSize}
                  max={maxLotSize}
                  setMax={setMaxLotSize}
                />

                <Bedrooms value={minBedrooms} setValue={setMinBedrooms} />
                <Bathrooms value={minBathrooms} setValue={setMinBathrooms} />

                <YearBuild
                  min={minYearBuild}
                  setMin={setMinYearBuild}
                  max={maxYearBuild}
                  setMax={setMaxYearBuild}
                />

                <HOAFee
                  min={minHOAFee}
                  setMin={setMinHOAFee}
                  max={maxHOAFee}
                  setMax={setMaxHOAFee}
                />
                <GarageSpace
                  value={minGarageSpace}
                  setValue={setMinGarageSpace}
                />
              </div>
            </section>
            <AdditionalFeatures
              selectedAdditionalFeatures={selectedAdditionalFeatures}
              setSelectedAdditionalFeatures={setSelectedAdditionalFeatures}
            />
            <ExtConditions
              priceReduction={priceReduction}
              setPriceReduction={setPriceReduction}
              priceReductionDate={priceReductionDate}
              setPriceReductionDate={setPriceReductionDate}
              sortResultsBy={sortResultsBy}
              setSortResultsBy={setSortResultsBy}
              daysOnSite={daysOnSite}
              setDaysOnSite={setDaysOnSite}
            />
            <ShowOnly
              foreclosureProperties={foreclosureProperties}
              setForeclosureProperties={ChangeForeclosurePropertiesHandler}
              shortSaleProperties={shortSaleProperties}
              setShortSaleProperties={ChangeShortSalePropertiesHandler}
              newConstructionProperties={newConstructionProperties}
              setNewConstructionProperties={
                ChangeNewConstructionPropertiesHandler
              }
            />
          </div>
          <div className="si-sf-btn js-search-buttons">
            <Link
              className="si-btn si-btn--secondary si-sf-btn__submit js-list-result"
              to="/property-search/results/"
            >
              <i className="fa fa-bars"></i> List Results
            </Link>
            <Link
              className="si-btn si-btn--secondary si-sf-btn__submit js-list-result"
              to="/property-search/results/"
            >
              <i className="fa fa-map-marker"></i> Map Results
            </Link>

            <button
              type="reset"
              className="si-btn si-btn--default si-sf-btn__reset js-reset"
              onClick={resetFormFieldsTodefault}
            >
              Reset
            </button>
          </div>
          <div className="si-sf-btn js-save-buttons" style={styleDispalyNone}>
            <a
              href="/"
              className="si-btn si-btn--secondary si-sf-btn__submit js-save"
            >
              <i className="fa fa-search"></i>Save Search
            </a>
            <a
              href="/"
              className="si-btn si-btn--default si-sf-btn__reset js-cancel-save"
            >
              Cancel
            </a>
          </div>
        </div>

        <ListingsCounter
          // isLoading={isGettingListingCounter}
          // counterTotal={listingCounter}
          searchData={formSearchData}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    propertySearch: state.propertySearchReducer.propertySearch,
  }
}

export default connect(mapStateToProps, { updatePropertySearchObject })(
  PropertySearchForm
)
