import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Api from '../../../../api/index'

const styleDispalyNone = {
  display: 'none',
}

const Loader = () => {
  return <i className="fa fa-circle-o-notch fa-fw fa-spin"></i>
}

function debounce(func) {
  var timeout = null
  return function (status, searchData) {
    clearTimeout(timeout)
    timeout = setTimeout(func, 400, status, searchData)
  }
}

const ListingsCounter = ({ searchData }) => {
  const [isGettingListingCounter, setIsGettingListingCounter] = useState(false)
  const [listingsQuantity, setListingsQuantity] = useState(0)
  const [containerClass, setContainerClass] = useState('affix-top')

  const onScrollHandler = (evt) => {
    if (document.body.getBoundingClientRect().top < -99) {
      setContainerClass('affix')
    } else {
      setContainerClass('affix-top')
    }
  }

  const addScrollHandler = () => {
    window.addEventListener('scroll', onScrollHandler)
    return removeScrollHandler
  }

  const removeScrollHandler = () => {
    window.removeEventListener('scroll', onScrollHandler)
  }

  useEffect(addScrollHandler, [])

  const getQuantity = async (status, searchData) => {
    setIsGettingListingCounter(true)
    let newCounter = null
    try {
      newCounter = await Api.propertySearch.count(searchData)
    } catch (error) {
      console.log(error)
      newCounter = { data: 0 }
    }
    if (!status.isCanceled) {
      setListingsQuantity(newCounter.data)
      setIsGettingListingCounter(false)
    }
  }

  const updateListingQuantity = useCallback(debounce(getQuantity), [])

  useEffect(() => {
    let status = { isCanceled: false }
    updateListingQuantity(status, searchData)
    return () => {
      status.isCanceled = true
    }
  }, [updateListingQuantity, searchData])

  const getShortQuantityString = () => {
    return listingsQuantity > 999
      ? Math.round(listingsQuantity / 100) / 10 + 'k'
      : listingsQuantity
  }

  return (
    <div className="si-sf-aside">
      <div className={`js-fixed-counter ${containerClass}`}>
        <div className="si-sf-counter si-sf-counter--full">
          <div className="si-sf-counter__number js-counter">
            {isGettingListingCounter ? <Loader /> : listingsQuantity}
          </div>
          <div className="si-sf-counter__label">Listings Found</div>
        </div>
        <div className="si-sf-counter si-sf-counter--abbr">
          <div className="si-sf-counter__number js-counter-abbr">
            {isGettingListingCounter ? <Loader /> : getShortQuantityString()}
          </div>
          <div className="si-sf-counter__label">Matches</div>
        </div>
        <div className="js-search-buttons">
          <Link
            className="si-sf-counter__btn js-list-result"
            to="/property-search/results/"
          >
            VIEW<span> ALL</span>
          </Link>
        </div>
        <div className="js-save-buttons" style={styleDispalyNone}>
          <Link className="si-sf-counter__btn js-save" to="/">
            SAVE<span> NOW</span>
          </Link>
        </div>
      </div>
    </div>
  )
}

ListingsCounter.propTypes = {
  searchData: PropTypes.object,
}
export default ListingsCounter
