import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Api from '../../../../api'

import Modal from './Modal'
import Form from './Form'

function FormWrapper({ closeFunction, formTitle, info, buttonName }) {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [contactPreference, setContactPreference] = useState('')
  const [comments, setComments] = useState('')
  const [formSent, setFormSent] = useState(false)
  const [sucsessResponce, setSuccessResponce] = useState(false)
  const [errorResponce, setErrorResponce] = useState(false)
  const submitButtonHandler = async (evt) => {
    evt.preventDefault()
    setFormSent(true)
    const contactFormData = {
      formName: formTitle,
      name,
      email,
      phone,
      contactPreference,
      comments,
      source: window.location.hostname,
      url: window.location.href,
    }
    let result
    try {
      result = await Api.forms.sheduleATour(contactFormData)
    } catch (e) {
      result = e
    }
    console.log(result)
    if (result.data && result.data.success) {
      setSuccessResponce(true)
    } else {
      setErrorResponce(true)
    }
  }
  const title = (
    <h4 className="modal-title" id="ssModalLabel" aria-level="2">
      {formTitle}
    </h4>
  )
  return (
    <Modal
      closeFunction={closeFunction}
      title={title}
      info={info}
      submitButtonName={buttonName}
      submitHandler={submitButtonHandler}
      formSent={formSent}
      sucsessResponce={sucsessResponce}
      errorResponce={errorResponce}
    >
      <Form
        name={name}
        setName={setName}
        email={email}
        setEmail={setEmail}
        phone={phone}
        setPhone={setPhone}
        contactPreference={contactPreference}
        setContactPreference={setContactPreference}
        comments={comments}
        setComments={setComments}
      />
    </Modal>
  )
}
FormWrapper.propTypes = {
  closeFunction: PropTypes.func.isRequired,
  formTitle: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  buttonName: PropTypes.string.isRequired,
}
export default FormWrapper
