import React from 'react'

import SidebarSearch from './Sidebar-search'
import FeaturedCommunities from './Sidebar-communities'
import WashingtonProperties from './Washington-properties'
import articles from '../../../database/blogposts'


import SidebarBlog from './Sidebar-blog'

function Sidebar() {
  return (
    <div className="col-md-4 right-col">
      <aside>
        <SidebarSearch />
        <FeaturedCommunities />
        <WashingtonProperties />
        <SidebarBlog article={articles[0]}/>
      </aside>
    </div>
  )
}

export default Sidebar
